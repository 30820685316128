import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Compiler } from '@angular/core';
declare var $: any; 
@Component({
    selector: 'upgrade-site',
    templateUrl: './upgradesite.component.html',
    styleUrls: ['./upgradesite.component.css']
  })

  export class UpgradesiteComponent implements OnInit {
    constructor(){}

    ngOnInit(){

    }
  }