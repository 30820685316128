import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { TraficsrcbygeoService } from './traficsrcbygeo.service';



@Component({
  selector: 'app-traficsrcbygeo',
  templateUrl: './traficsrcbygeo.component.html',
  styleUrls: ['./traficsrcbygeo.component.css']
})
export class TraficsrcbygeoComponent implements OnInit {
  chart: Chart;
  donutchart:Chart;
  donutchart1:Chart;
  public loading: boolean = true;
  public columnchartdata = [];
  public donutchatdata1 = [];
   public dateranges = []; 
   public tabulardata = []; 
   public topdata = []; 
   public date = []; 
   public date1 = []; 
   public maxvalue = []; 
   public minvalue = [];
   public errorMsg;
   public donutchartdata =[];
   public donutdata =[];
   dataSource: Object;
 
   getdonutchart(x,y,z)
   {

this.dataSource = {
  "chart": {
    "animation": "0",
    "showbevel": "0",
    "usehovercolor": "1",
      "theme": "fint",
       "entityFillColor": "#cccccc",
        "entityFillHoverColor": "#d40062",
    "canvasbordercolor": "FFFFFF",
    "bordercolor": "FFFFFF",
    "showlegend": "1",
    "showshadow": "0",
    "legendposition": "BOTTOM",
    "legendborderalpha": "0",
    "legendbordercolor": "ffffff",
    "legendbgcolor": "ffffff",
    "legendallowdrag": "0",
    "legendshadow": "0",
    "caption": "",
    "connectorcolor": "000000",
    "fillalpha": "80",
    "hovercolor": "d40062",
    "showlabels": "0",
    "showborder": 0
},
  // Aesthetics; ranges synced with the slider
  "colorrange": {
    "minvalue": "0",
    "startlabel": "Low",
    "endlabel": "High",
    "code": "00bede",
    "gradient": "1",
    "color": [
        {
            "maxvalue": z,
            "displayvalue": "Average",
            "code": "008398"
        },
        {
            "maxvalue": y,
            "code": "00afcc"
        }
    ],
    "maxvalue": 0
},
  // Source data as JSON --> id represents countries of world.
  
  "data":x
}
}
  constructor(private _TraficsrcbygeoService:TraficsrcbygeoService) { }

  ngOnInit() {
    this._TraficsrcbygeoService.getdonutchart(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.columnchartdata = data['chartdata'];this.tabulardata = data['tabulardata1'];this.topdata = data['daterangetop'];this.date = data['topdata'];this.minvalue = data['geomin'];this.maxvalue = data['geomax'];this.getdonutchart(this.columnchartdata,this.maxvalue,this.minvalue);},
    error => this.errorMsg = error);
  }

}