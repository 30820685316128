import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { ViewprofiledataService } from './viewprofile.service';
import { Location } from '@angular/common';

@Component({
  selector: 'view-profiles',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfilesComponent implements OnInit { 
  public email;
  public errorMsg;
  public profileList = [];
  public totData = [];
  display='none';
  errshow:boolean=true;
  public pub_partner_id;
  public pub_partner_email;
  model: any = {};

  constructor(private ViewprofiledataService:ViewprofiledataService,private router: ActivatedRoute, private routes: Router,private _location: Location) { }

  ngOnInit() {
    this.email = this.router.snapshot.paramMap.get('m');
    this.ViewprofiledataService.getprofilesdata(this.email)
    .subscribe(data => {
      this.profileList = data['table_data'];
    },
      error => this.errorMsg = error);
  }

  openModalDialog(pub_email){
    // this.pub_partner_id = pub_id;
    this.pub_partner_email = pub_email;
    this.display='block'; //Set block css

  }
  closeModalDialog(){
    this.display='none'; //set none css after close dialog.
    this.errshow=false;
  }
  request_query_form(){
    this.errshow=true;
  }
  onClickSubmit(data) {
    this.errshow=false;
    const formData: FormData = new FormData();
    // const tags = new Array();
    // formData.append('pub_partner_id', this.pub_partner_id);
    formData.append('pub_partner_email', this.pub_partner_email);
    formData.append('data', JSON.stringify(data));
    this.ViewprofiledataService.requestquery(formData).subscribe((response)=>{
      if(response){
        alert("Thanks! Request has been Sent successfully!.");
        this.closeModalDialog();
        this.ngOnInit();
      }
      else{
        alert("Error Please fill again.");
        this.closeModalDialog();
        this.ngOnInit();
      }
     
    }) 
  }
  backClicked() {
    this._location.back();
  }
}
