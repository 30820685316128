import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { appadunit } from './adxapp_adunit';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxAppAdunitService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_app/cyberads_app_adunits.php";

  constructor(private http:HttpClient) { }

  getAppAdunit(daterange,acc_name,uniqid): Observable<appadunit[]>{
    return this.http.get<appadunit[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}