import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AppService } from './appinvite.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-invite',
    templateUrl: './app-invite.component.html',
    styleUrls: ['./app-invite.component.css']
})
export class GemAppInvite implements OnInit {
  public monthyear;
  public errorMsg;
  public primary_email;
  public pub_uniq_id;
  public pub_acc_name;
  public gamAcc;
  public memberType;
  public newEmail;
  public appName;
  public appID;
  public inviteURL;
  public pub_useremail;

  constructor(private httpClient: HttpClient,private _gemAdManagerService:AppService,private router: ActivatedRoute, private routes: Router) { }

  ngOnInit() {
    if(localStorage.getItem('usertype')=='2'){
        this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/appsnetwork.php";
    }else{
        this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/appinvite.php";
        // this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/gamInvitecopy.php";
    }
    this.monthyear = this.router.snapshot.paramMap.get('m');
    // this._gemAdManagerService.getGemAppdata(localStorage.getItem('uniq_id'),localStorage.getItem('acc_mgr_id'))
    // .subscribe(data => {
    //   this.primary_email = data['gamEmail'];
    //   this.pub_uniq_id = data['pub_uniq_id'];
    //   this.pub_acc_name = data['pub_acc_name'];
    //   this.gamAcc = data['gamAcc'];
    // },
    //   error => this.errorMsg = error);

    this.pub_uniq_id = localStorage.getItem('uniq_id');
    this.pub_acc_name = localStorage.getItem('acc_name');
    this.pub_useremail = localStorage.getItem('useremail');
  }

  sendInvite(data){
    
    this.memberType = $("input[name='select']:checked").val();
    this.appName = $("#id_app_name").val();
    this.appID = $("#app_id").val();
    
    
    if((this.memberType=='Android') || (this.memberType=='IOS')){
       // alert(this.memberType);
    }else{
        alert("Please Select App Type");
        return false;
    }
    if(this.appName==''){
        alert("Please Enter App Name");
        return false;
    }
    if(this.appID==''){
        alert("Please Enter App ID");
        return false;
    }
    
    
    data['memberType'] = this.memberType;
    data['appName'] = this.appName;
    data['pub_uniq_id'] = this.pub_uniq_id;
    data['pub_acc_name'] = this.pub_acc_name;
    data['useremail'] = this.pub_useremail;
    data['appID'] = this.appID;
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    
    this.httpClient.post(this.inviteURL+'?q=newapp',formData).subscribe(data => {
        if(data['msg']=='success'){
            alert("Thank you Adding New App. Our team has started a analysis of your App.");
        }else{
            alert(data['text']);
        }
        location.reload(); 
      },error => this.errorMsg = error);
  }

}

