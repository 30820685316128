import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NetworkEarningComponent } from './network-earning.component';

@Injectable({
  providedIn: 'root'
})
export class NetworkEarningService {
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_display/networks_display_earnings.php"
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_display/cyberads_display_overview.php"
    }	
  }

    getNetworkearning(daterange,acc_mgr_id,acc_name,new_adx_name,uniqid): Observable<NetworkEarningComponent[]>{
    return this.http.get<NetworkEarningComponent[]>(this._url+ "?"+daterange+"&acc_mgr_id="+acc_mgr_id+"&acc_name="+acc_name+"&new_acc_name="+new_adx_name+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }



}
