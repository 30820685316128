import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { donutchart } from './adxdonutchartcontent';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DonutcharttotalService {
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/donut_totalearning_networks.php"
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/donut_totalearning.php"
    }
  }

  getdonutchart(daterange,acc_mgr_id,anlyticid,acc_name,uniq_id,website,adsid,child_net_code): Observable<donutchart[]>{
    return this.http.get<donutchart[]>(this._url+ "?"+daterange+"&acc_mgr_id="+acc_mgr_id+"&analyid="+anlyticid+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&website="+website+"&adsid="+adsid+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}