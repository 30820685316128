import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { GamReportingAdUnitService } from './gam-adunit.service';
declare var $: any;
import * as moment from 'moment';
@Component({
  selector: 'gam-reporting-adunit',
  templateUrl: './gam-reporting-adunit.component.html',
  styleUrls: ['./gam-reporting-adunit.component.css']
})
export class GamReportAdUnitComponent implements OnInit {
  piechart;
  public ustabledata = [];
  public usinnertabledata = [];
  public catabledata = [];
  public cainnertabledata = [];
  public uktabledata = [];
  public ukinnertabledata = [];
  public austabledata = [];
  public ausinnertabledata = [];
  public gertabledata = [];
  public gerinnertabledata = [];
  public indtabledata = [];
  public indinnertabledata = [];
  public frtabledata = [];
  public frinnertabledata = [];
  public beltabledata = [];
  public belinnertabledata = [];
  public nethrtabledata = [];
  public nethrinnertabledata = [];
  public nztabledata = [];
  public nzinnertabledata = [];
  public othtabledata = [];
  public othinnertabledata = [];
  public show: boolean = false;
  public showcan: boolean = false;
  public showuk: boolean = false;
  public showaus: boolean = false;
  public showger: boolean = false;
  public showind: boolean = false;
  public showfr: boolean = false;
  public showbel: boolean = false;
  public shownet: boolean = false;
  public shownz: boolean = false;
  public showoth: boolean = false;
  public classnamelvl1: any = 'fa fa-plus show-span';
  public classnamecan: any = 'fa fa-plus';
  public classnameuk: any = 'fa fa-plus';
  public classnameaus: any = 'fa fa-plus';
  public classnameger: any = 'fa fa-plus';
  public classnameind: any = 'fa fa-plus';
  public classnamefr: any = 'fa fa-plus';
  public classnamebel: any = 'fa fa-plus';
  public classnamenet: any = 'fa fa-plus';
  public classnamenz: any = 'fa fa-plus';
  public classnameoth: any = 'fa fa-plus';
  public graphdata = [];
  public graphdatelvl3 = [];
  public graphdata1 = [];
  public graphdata4 = [];
  public tabledata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  public loading: boolean = true;
  public firstlevlTble;
  getpiechart(x, y, z) {
    this.piechart = new Chart({
      chart: {
        events: {
          /*drilldown: function (e) {
            // console.log(this.series[0].options._levelNumber);
            // if(this.series[0].options._levelNumber == '0' || this.series[0].options._levelNumber=='undefined'){
            //   var firstlevlTble = e.seriesOptions.name;
            //   console.log(firstlevlTble);
            // }
            // console.log("level =>"+this.series[0].options._levelNumber);
            $("table").hide();
            $("#" + e.seriesOptions.name).show();

          },
          drillup: function (e) {
            // console.log(this.series[0].name);
            // console.log(this.series);
            // console.log(firstlevlTble);
            $("table").hide();
            if(this.series[0].options._levelNumber == '2'){
              $("#" + this.series[0].name).show();
            }else{
              $("#maintable").show();
            }
            
          }*/
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: 'CyberAds Revenue For GAM360 - Ad Units'
      },
      xAxis: {
        categories: z.dates
      },
      yAxis: {
        showEmpty: false,
      },
      exporting: { enabled: false },
      credits: {
        enabled: false
      },
      colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
        '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

      tooltip: {
        pointFormat: '{series.name}: <b>${point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: ${point.y}',

          }, showInLegend: true,
        }
      },
      series: [
        {
          name: "Ad Tags",
          data: y,
        }
      ]
      // drilldown: {
      //   drillUpButton: {
      //     position: {
      //       x: 0,
      //       y: -35,
      //     }
      //   },
      //   series: x
        
      // }
    });
    function init_daterangepicker() {
      if ("undefined" != typeof $.fn.daterangepicker) {
        console.log("init_daterangepicker");
        var a = function (a, b, c) {
          console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
        },
          b = {
            startDate: moment().subtract(7, "days"),
            endDate: moment(),
            // minDate: moment().subtract(3, "month"),
            minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            },
            opens: "left",
            buttonClasses: ["btn bg-pink"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
        $(".custom-daterangepicker span").html(localStorage.getItem('enddate') + "-" + localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function () {
          console.log("show event fired")
        }), $(".custom-daterangepicker").on("hide.daterangepicker", function () {
          console.log("hide event fired")
        }), $(".custom-daterangepicker").on("apply.daterangepicker", function (a, b) {

          let strtdate = "strtdate=" + b.startDate.format("YYYY-MM-DD") + "&enddate=" + b.endDate.format("YYYY-MM-DD");
          $("#dateinput").val(strtdate);
          let startdate = b.startDate.format("MMMM D, YYYY");
          let enddate = b.endDate.format("MMMM D, YYYY");
          localStorage.setItem('strtdate', strtdate);
          localStorage.setItem('startdate', enddate);
          localStorage.setItem('enddate', startdate);
          window.location.reload();


          console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $(".custom-daterangepicker").on("cancel.daterangepicker", function (a, b) {
          console.log("cancel event fired")
        }), $("#options1").click(function () {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function () {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function () {
          $(".custom-daterangepicker").data("daterangepicker").remove()
        })
      }

    }

    function init_daterangepicker_right() {
      if ("undefined" != typeof $.fn.daterangepicker) {
        console.log("init_daterangepicker_right");
        var a = function (a, b, c) {
          console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
        },
          b = {
            startDate: moment().subtract(6, "days"),
            endDate: moment(),
            // minDate: moment().subtract(3, "month"),
            minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
            opens: "right",
            buttonClasses: ["btn btn-default"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
        $("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function () {
          console.log("show event fired")
        }), $("#reportrange_right").on("hide.daterangepicker", function () {
          console.log("hide event fired")
        }), $("#reportrange_right").on("apply.daterangepicker", function (a, b) {
          console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $("#reportrange_right").on("cancel.daterangepicker", function (a, b) {
          console.log("cancel event fired")
        }), $("#options1").click(function () {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function () {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function () {
          $("#reportrange_right").data("daterangepicker").remove()
        })
      }
    }

    function init_daterangepicker_single_call() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_1"
      }, function (a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal2").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_2"
      }, function (a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal3").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_3"
      }, function (a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal4").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_4"
      }, function (a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }))
    }

    function init_daterangepicker_reservation() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function (a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#reservation-time").daterangepicker({
        timePicker: !0,
        timePickerIncrement: 30,
        locale: {
          format: "MM/DD/YYYY h:mm A"
        }
      }))
    }

    function init_SmartWizard() {
      "undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
        transitionEffect: "slide"
      }), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
    }




    var originalLeave = $.fn.popover.Constructor.prototype.leave;
    $.fn.popover.Constructor.prototype.leave = function (a) { }, $("body").popover(), $(document).ready(function () {
      init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
    });
  }
  constructor(private _gamadunitService: GamReportingAdUnitService) { }

  ngOnInit() {

    this._gamadunitService.getAdunit(localStorage.getItem('strtdate'), localStorage.getItem('child_net_code'))
      .subscribe(data => {
        this.loading = false;
          //this.graphdata = data["level3"],
          this.graphdata1 = data["level1"],
          //this.graphdata4 = data["level4"],
          this.tabledata = data["mobos_table_data"],
          this.daterange = data["adtype_date_range"],
          this.graphdatelvl3 = data["level3_dates"],
          this.sumupdata = data["Sum_table_data"];
        // console.log(this.sumupdata);
        // console.log(this.tabledata);
        this.getpiechart(this.graphdata, this.graphdata1, this.graphdatelvl3);
      },
        error => this.errorMsg = error);

  }

  downloadadxadunit() {

    document.location.href = 'https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/download-gam-adunit.php?' + localStorage.getItem("strtdate") + '&child_net_code=' + localStorage.getItem("child_net_code");

  }

  toggle(a) {
    $(".lvl" + a).toggle();
    if ($(".show-span" + a).hasClass("fa fa-plus")) {
      $(".show-span" + a).removeClass('fa fa-plus');
      $(".show-span" + a).addClass('fa fa-minus  show-span' + a);
    } else {
      $(".show-span" + a).removeClass('fa fa-minus');
      $(".show-span" + a).addClass('fa fa-plus show-span' + a);
    }
    $('.tstcls'+a).hide();
    if ($(".level2chck" + a).hasClass("fa fa-minus")) {
      $(".level2chck" + a).toggle();
    }
    $(".level2chck" + a).removeClass('fa fa-minus fa fa-plus');
    $(".level2chck" + a).addClass('fa fa-plus');
    $(".level2chck" + a).removeAttr('style');
     
    
  }
  togglee(a, x) {
    $(".lv3" +a + x).toggle();
    if ($(".show-span" + a + x).hasClass("fa fa-plus")) {
      $(".show-span" + a + x).removeClass('fa fa-plus');
      $(".show-span" + a + x).addClass('fa fa-minus show-span' + a + x);
      $(".show-span" + a).addClass('allon'+a);
      $(".show-span" + a).removeClass('alloff'+a);
    } else {
      $(".show-span" + a + x).removeClass('fa fa-minus');
      $(".show-span" + a + x).addClass('fa fa-plus show-span' + a + x);
      $(".show-span" + a).addClass('alloff'+a);
      $(".show-span" + a).removeClass('allon'+a);
    }
  }
}