import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  public usertype: any;
  private url: string = "";
  
  urlSafe: SafeResourceUrl;



  public donutchatdata = [];
  public topdatas = [];
   public dateranges = [];
   public eptabledata = []; 
   public errorMsg;
   public donutchartdata =[];
   public donutdata =[];
   public loading;
   public load;


 constructor(public sanitizer: DomSanitizer) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this.url = "https://www.safe.cybermediaservices.net/pub/dashboard/network_bank_details.php?act=bd&adxid=";
      this.loading = localStorage.getItem('acc_mgr_id');
    }else{
      this.load= localStorage.getItem('child_net_code');
      this.url = "https://www.safe.cybermediaservices.net/pub/dashboard/bank_details_new.php?act=bd&childnetcode="+this.load+"&adxid=";
      this.loading = localStorage.getItem('adx_id');

    }
  }

  ngOnInit() {
    // this.loading = localStorage.getItem('adx_id');
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url+this.loading);
    

  //   this._BankdtlService.getbankdtl(localStorage.getItem('adx_id'))
  //   .subscribe(data =>{
  //     this.topdatas = data['bankdetails_data'];
  //     if(this.topdatas!= null)
  //     {
  //       this.loading = false;
  //     }
  //  },
  //   error => this.errorMsg = error);
    
}
// updateUser(event) {
//   event.preventDefault()
//   const target = event.target
//   const companyType = target.querySelector('#companyType').value
//   const accname = target.querySelector('#accountHolderName').value
//   const accno = target.querySelector('#accountNumber').value
//   const bankName = target.querySelector('#bankName').value
//   const ifsc = target.querySelector('#ifsc').value
//   const bank_address = target.querySelector('#bank_address').value
//   const accountHolderAddress = target.querySelector('#accountHolderAddress').value
//   const city = target.querySelector('#city').value
//   const state = target.querySelector('#state').value
//   const postal_code = target.querySelector('#postal_code').value
//   const pan_number = target.querySelector('#pan_number').value
//   const acountType = target.querySelector('#acountType').value
//   const gstYN = target.querySelector('#gstYN').value
  
//   this._insertbnkDetails.insertbnkDetails(companyType,accname,accno,bankName,ifsc,bank_address,accountHolderAddress,city,state,postal_code,pan_number,acountType,gstYN).subscribe(data => {
//     if(data!= null) 
//     {
//       window.alert("Data Saved Successfully"); 
//     } else {
//       window.alert("Data not Saved Successfully. Kindly Check and re-sumbitt the details.");
//     }
// })
// }
}
