import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { vidadunit } from './gam-vid-adunit';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamVidAdunitService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/video_gam/gam_video_adtag.php";

  constructor(private http:HttpClient) { }

  getAdunit(daterange,child_net_code): Observable<vidadunit[]>{
    return this.http.get<vidadunit[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}