import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { HIGHCHARTS_MODULES,ChartModule } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import Drilldown from 'highcharts-drilldown';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highmaps from 'highcharts/modules/map.src';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdxAppOverviewComponent } from './adx-app-overview/adx-app-overview.component';
import { AdxAppAdTypesComponent } from './adx-app-ad-types/adx-app-ad-types.component';
import { AdxContentOverviewComponent } from './adx-content-overview/adx-content-overview.component';
import { AdxContentAdTypesComponent } from './adx-content-ad-types/adx-content-ad-types.component';
import { AdxContentOverviewService } from './adx-content-overview/adx-content-overview.service';
import { AdxAdtypeService } from './adx-content-ad-types/adx-adtypeService';
import { AdxContentSiteComponent } from './adx-content-sites/adx-content-site.component';
import { AdxSiteService } from './adx-content-sites/adx-siteService';
import { AdxDeviceComponent } from './adx-content-device/adx-content-device.component';
import { AdxDeviceService } from './adx-content-device/adx-deviceService';
import { AdxContentGeoComponent } from './adx-content-geo/adx-content-geo.component';
import { AdxGeoService } from './adx-content-geo/adx-geoService';
import { AdxContentMobileosComponent } from './adx-content-mobile-os/adx-content-mobileos.component';
import { AdxMobileosService } from './adx-content-mobile-os/adx-mobileosService';
import { AdxContentAdUnitComponent } from './adx-content-ad-unit/adx-content-ad-unit.component';
import { AdxAdunitService } from './adx-content-ad-unit/adx-adunitService';
import { AdxAppOverviewService } from './adx-app-overview/adx-app-overview.service';
import { AdxAppAdtypeService } from './adx-app-ad-types/adx-app-adtypeService';
import { AdxAppService } from './adx-app-sites/adx-appService';
import { AdxAppGeoService } from './adx-app-geo/adx-geoService';
import { AdxAppMobileosService } from './adx-app-mobileos/adx-app-mobileosService';
import { LoginComponent } from './login/login.component';
import { AuthService } from './login/auth.service'
import { UserService } from './login/user.service'
import { AuthGuard } from './login/auth.guard';
import { HeaderComponent } from './header/header.component';
import { AdxAppSitesComponent } from './adx-app-sites/adx-app-sites.component';
import { AdxAppDeviceComponent } from './adx-app-device/adx-app-device.component';
import { AdxAppGeoComponent } from './adx-app-geo/adx-app-geo.component';
import { AdxAppMobileosComponent } from './adx-app-mobileos/adx-app-mobileos.component';
import { AdxAppDeviceService } from './adx-app-device/adx-app-deviceService';
import { AdxAppAdunitService } from './adx-app-adunit/adx-app-adunitService';
import { AdxAppAdunitComponent } from './adx-app-adunit/adx-app-adunit.component';
import { AdxVideoOverviewComponent } from './adx-video-overview/adx-video-overview.component';
import { AdxVideoAdtypeComponent } from './adx-video-adtype/adx-video-adtype.component';
import { AdxVideoSitesComponent } from './adx-video-sites/adx-video-sites.component';
import { AdxVideoDeviceComponent } from './adx-video-device/adx-video-device.component';
import { AdxVideoGeoComponent } from './adx-video-geo/adx-video-geo.component';
import { AdxVideoMobosComponent } from './adx-video-mobos/adx-video-mobos.component';
import { AdxVideoAdunitComponent } from './adx-video-adunit/adx-video-adunit.component';
import { AdxVideoOverviewService } from './adx-video-overview/adx-video-overview.service';
import { AdxVideoAdtypeService } from './adx-video-adtype/adx-vidadtypeService';
import { AdxVideoSiteService } from './adx-video-sites/adx-VideoSiteService';
import { AdxVideoDeviceService } from './adx-video-device/adx-video-deviceService';
import { AdxVideoGeoService } from './adx-video-geo/adx-video-geoService';
import { AdxVidMobileosService } from './adx-video-mobos/adx-vid-mobileosService';
import { AdxVidAdunitService } from './adx-video-adunit/adx-vid-adunitService';
import { AudienceComponent } from './audience/audience.component';
import { RtrnvsnewComponent } from './rtrnvsnew/rtrnvsnew.component';
import { RtrnbypaageComponent } from './rtrnbypaage/rtrnbypaage.component';
import { RtrnbydeviceComponent } from './rtrnbydevice/rtrnbydevice.component';
import { TraficsourceComponent } from './traficsource/traficsource.component';
import { TraficsrcbyvalComponent } from './traficsrcbyval/traficsrcbyval.component';
import { TraficsrcbygeoComponent } from './traficsrcbygeo/traficsrcbygeo.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { ExitpageComponent } from './exitpage/exitpage.component';
import { CookieService } from 'ngx-cookie-service';
import {MomentTimezoneModule} from 'angular-moment-timezone';
// Import angular-fusioncharts
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import *  as FusionMaps from 'fusioncharts/fusioncharts.maps';
import * as World from 'fusioncharts/maps/fusioncharts.world';
import * as worldwithcountries from 'fusionmaps/maps/fusioncharts.worldwithcountries';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { BlankpageComponent } from './blankpage/blankpage.component';
import { AdsOverviewComponent } from './ads-overview/ads-overview.component';
import { AdsSitesNewComponent } from './ads-sites-new/ads-sites-new.component';
import { AdsAdtypeNewComponent } from './ads-adtype-new/ads-adtype-new.component';
import { AdsDeviceNewComponent } from './ads-device-new/ads-device-new.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { LogoutComponent } from './logout/logout.component';
import { HeaderBidderComponent } from './header-bidder/header-bidder.component';
import { CyberadsOverviewComponent } from './cyberads-overview/cyberads-overview.component';
import { HeaderBidderGeoComponent } from './header-bidder-geo/header-bidder-geo.component';
import { HeaderBidderDeviceComponent } from './header-bidder-device/header-bidder-device.component';
import { ProDashboardComponent } from './pro-dashboard/pro-dashboard.component';
import { InsightsComponent } from './insights/insights.component';
import { FormsModule } from '@angular/forms';
import { ComplainTicketComponent } from './complain-ticket/complain-ticket.component';
import { NewTicketComponent } from './new-ticket/new-ticket.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { CdpComponent } from './cdp/cdp.component';
import { SubUserRegestrationComponent } from './sub-user-regestration/sub-user-regestration.component';
import { RemnantInventoryComponent } from './remnant-inventory/remnant-inventory.component';
import { AdvertiserComponent } from './advertiser/advertiser.component';
import { FooterComponent } from './footer/footer.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ListInvoiceComponent } from './list-invoice/list-invoice.component';
import { AdstxtComponent } from './adstxt/adstxt.component';
import { NetworkDataComponent } from './network-sites/networkdata.component';
import { Network_Site_Service } from './network-sites/network_Site_Service';
import { DataTablesModule } from 'angular-datatables';
import { NetworkBreakupComponent } from './network-breakup/network-breakup.component';
import { NetworkbreakupdataService } from './network-breakup/networkbreakup.service';
import { ViewProfileComponent } from './view-profile/view-profile.component';
import { ViewprofiledataService } from './view-profile/viewprofile.service';
import { ViewProfilesComponent } from './view-profile/view-profiles.component';
import { UpgradesiteComponent } from './upgrade-site/upgradesite.component';
import { NetworkEarningComponent } from './network-earning/network-earning.component';
import { NetworkEarningService } from './network-earning/network-earning.service';
import { from } from 'rxjs';
import { ToolsComponent } from './tools/tools.component';
import { AdvanceResponsiveComponent } from './tools/advance-responsive.component';
import { CampaignToolsComponent } from './tools/campaign-tools.component';
import { StickyAdsComponent } from './tools/sticky-ads.component';
import { DFPStickyAdsComponent } from './tools/DFP-Sticky-ads.component';
import { DFPResponsiveTagGeneratorComponent } from './tools/DFP-responsive-tag-generator.component';
import { DFPResponsiveAndRefreshComponent } from './tools/DFP-responsive-and-refresh.component';
import { DFPRefreshTagComponent } from './tools/DFP-refresh-tag.component';
import { GemAdmanagerInvite } from './gem-invite/gem-invite.component';
import { GemAdmanagerService } from './gem-invite/geminvite.service';
import { GemAppInvite } from './app-invite/app-invite.component';
import { AppService } from './app-invite/appinvite.service';
import { GamReportOverviewComponent } from './gam-reporting-overview/gam-reporting-overview.component';
import { GamReportingOverviewService } from './gam-reporting-overview/gam-reporting-overview.service';
import { GamReportAdTypesComponent } from './gam-reporting-adtypes/gam-reporting-adtypes.component';
import { GamReportingAdTypesService } from './gam-reporting-adtypes/gam-adtypes.service';
import { GamReportAdUnitComponent } from './gam-reporting-adunit/gam-reporting-adunit.component';
import { GamReportingAdUnitService } from './gam-reporting-adunit/gam-adunit.service';
import { GamReportSiteComponent } from './gam-reporting-sites/gam-reporting-site.component';
import { GamReportingSiteService } from './gam-reporting-sites/gam-site.service';
import { GamReportDeviceComponent } from './gam-reporting-device/gam-reporting-device.component';
import { GamReportingDeviceService } from './gam-reporting-device/gam-device.service';
import { GamReportGeoComponent } from './gam-reporting-geo/gam-reporting-geo.component';
import { GamReportingGeoService } from './gam-reporting-geo/gam-geo.service';

import { GamAppOverviewComponent } from './gam-app-overview/gam-app-overview.component';
import { GamAppOverviewService } from './gam-app-overview/gam-app-overview.service';
import { GamAppAdTypesComponent } from './gam-app-ad-types/gam-app-ad-types.component';
import { GamAppAdTypesService } from './gam-app-ad-types/gam-app-adtype.service';
import { GamAppAdunitComponent } from './gam-app-adunit/gam-app-adunit.component';
import { GamAppAdunitService } from './gam-app-adunit/gam-app-adunitService';
import { GamAppSitesComponent } from './gam-app-sites/gam-app-sites.component';
import { GamAppSitesService } from './gam-app-sites/gam-appService';
import { GamAppDeviceComponent } from './gam-app-device/gam-app-device.component';
import { GamAppDeviceService } from './gam-app-device/gam-app-deviceService';
import { GamAppGeoComponent } from './gam-app-geo/gam-app-geo.component';
import { GamAppGeoService } from './gam-app-geo/gam-geoService';

import { GamVideoOverviewComponent } from './gam-video-overview/gam-video-overview.component';
import { GamVideoOverviewService } from './gam-video-overview/gam-video-overview.service';
import { GamVideoAdtypeComponent } from './gam-video-adtype/gam-video-adtype.component';
import { GamVideoAdtypeService } from './gam-video-adtype/gam-vidadtypeService';
import { GamVideoSitesComponent } from './gam-video-sites/gam-video-sites.component';
import { GamVideoSiteService } from './gam-video-sites/gam-VideoSiteService';
import { GamVideoDeviceComponent } from './gam-video-device/gam-video-device.component';
import { GamVideoDeviceService } from './gam-video-device/gam-video-deviceService';
import { GamVideoGeoComponent } from './gam-video-geo/gam-video-geo.component';
import { GamVideoGeoService } from './gam-video-geo/gam-video-geoService';
import { GamVideoAdunitComponent } from './gam-video-adunit/gam-video-adunit.component';
import { GamVidAdunitService } from './gam-video-adunit/gam-vid-adunitService';


// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, FusionMaps, World, worldwithcountries, FusionTheme);



const appRoutes = [
  // { path: '**', redirectTo: '/upgrade-site' },
  { path: 'login', component: LoginComponent},

  { path: 'dashboard', component: DashboardComponent},
  {
    path: "dashboard/adx",
    loadChildren: "../app/adx/adx.module#AdxModule"
  },
  {
    path: "dashboard/adsense",
    loadChildren: "../app/adsense/adsense.module#AdsenseModule"
  },
  
    { path: 'adx-for-app-overview', component: AdxAppOverviewComponent },
    { path: 'header_bidder', component: HeaderBidderComponent },
    { path: 'Cyb_overview', component: CyberadsOverviewComponent },
    { path: 'adx-for-app-adtypes', component: AdxAppAdTypesComponent },
    { path: 'adx-for-app-apps', component: AdxAppSitesComponent },
    { path: 'adx-for-app-device', component: AdxAppDeviceComponent },
    { path: 'adx-for-app-geo', component: AdxAppGeoComponent },
    { path: 'adx-for-app-mobileos', component: AdxAppMobileosComponent },
    { path: 'adx-for-app-adunit', component: AdxAppAdunitComponent },
    { path: 'adx-for-content-overview', component: AdxContentOverviewComponent },
    { path: 'adx-for-content-adtypes', component: AdxContentAdTypesComponent },
    { path: 'adx-for-content-site', component: AdxContentSiteComponent },
    { path: 'adx-for-content-device', component: AdxDeviceComponent },
    { path: 'adx-content-geo', component: AdxContentGeoComponent },
    { path: 'adx-content-mobileos', component: AdxContentMobileosComponent },
    { path: 'adx-content-ad-unit', component: AdxContentAdUnitComponent },
    { path: 'adx-for-video-overview', component: AdxVideoOverviewComponent },
    { path: 'adx-for-video-adtypes', component: AdxVideoAdtypeComponent },
    { path: 'adx-for-video-site', component: AdxVideoSitesComponent },
    { path: 'adx-for-video-device', component: AdxVideoDeviceComponent },
    { path: 'adx-video-geo', component: AdxVideoGeoComponent },
    { path: 'adx-video-mobileos', component: AdxVideoMobosComponent },
    { path: 'adx-video-ad-unit', component: AdxVideoAdunitComponent },
    { path: 'Audience', component: AudienceComponent },
    { path: 'return-vs-new', component: RtrnvsnewComponent },
    { path: 'return-by-page', component: RtrnbypaageComponent },
    { path: 'return-by-device', component: RtrnbydeviceComponent },
    { path: 'trafic-source', component: TraficsourceComponent },
    { path: 'trafic-source-comp', component: TraficsrcbyvalComponent },
    { path: 'trafic-geo', component: TraficsrcbygeoComponent },
    { path: 'Landingpage', component: LandingpageComponent },
    { path: 'Exitpage', component: ExitpageComponent },
    { path: 'blankpage', component: BlankpageComponent },
    { path: 'ads-overview', component: AdsOverviewComponent },
    { path: 'ads-adtypes-new', component: AdsAdtypeNewComponent },
    { path: 'ads-device-new', component: AdsDeviceNewComponent },
    { path: 'ads-sites-new', component: AdsSitesNewComponent },
    { path: 'bankdetail', component: BankDetailsComponent },
    { path: 'header-bidder-geo', component: HeaderBidderGeoComponent },
    { path: 'header-bidder-device', component: HeaderBidderDeviceComponent },
    { path: 'pro-dashboard', component: ProDashboardComponent },
    { path: 'insights', component: InsightsComponent },
    { path: 'complain-ticket', component: ComplainTicketComponent },
    { path: 'new-ticket', component: NewTicketComponent },
    { path: 'cdp', component: CdpComponent },
    { path: 'sub-user', component: SubUserRegestrationComponent },
    { path: 'ticket-detail/:id', component: TicketDetailsComponent , pathMatch: 'full'},
    { path: 'remnant-inventory', component: RemnantInventoryComponent },
    { path: 'advertiser', component: AdvertiserComponent },
    { path: 'invoice/:m', component: InvoiceComponent },
    { path: 'list-invoice', component: ListInvoiceComponent },
    { path: 'adstxt', component: AdstxtComponent },
    { path: 'logout', component: LogoutComponent},
    { path: 'network-for-site', component: NetworkDataComponent},
    { path: 'app-network-earning', component: NetworkEarningComponent},
    { path: 'app-tools', component: ToolsComponent},
    { path: 'app-advance-responsive', component: AdvanceResponsiveComponent},
    { path: 'app-campaign-tools', component: CampaignToolsComponent},
    { path: 'app-sticky-ads', component: StickyAdsComponent},
    { path: 'app-DFP-Sticky-ads', component: DFPStickyAdsComponent},
    { path: 'app-DFP-responsive-tag-generator', component: DFPResponsiveTagGeneratorComponent},
    { path: 'app-DFP-responsive-and-refresh', component: DFPResponsiveAndRefreshComponent},
    { path: 'app-DFP-refresh-tag', component: DFPRefreshTagComponent},
    { path: 'view-breakup/:m', component: NetworkBreakupComponent},
    { path: 'view-profile/:m', component: ViewProfileComponent},
    { path: 'view-profiles/:m', component: ViewProfilesComponent},
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'upgrade-site', component: UpgradesiteComponent },
    { path: 'gam_invite', component:GemAdmanagerInvite},
    { path: 'app_invite', component:GemAppInvite},
    { path: 'gam-report', component:GamReportOverviewComponent},
    { path: 'gam-adtypes', component:GamReportAdTypesComponent},
    { path: 'gam-adunit', component:GamReportAdUnitComponent},
    { path: 'gam-sites', component:GamReportSiteComponent},
    { path: 'gam-device', component:GamReportDeviceComponent},
    { path: 'gam-geo', component:GamReportGeoComponent},

    { path: 'gam-app-overview', component:GamAppOverviewComponent},
    { path: 'gam-app-adtypes', component:GamAppAdTypesComponent},
    { path: 'gam-app-adunit', component:GamAppAdunitComponent},
    { path: 'gam-app-sites', component:GamAppSitesComponent},
    { path: 'gam-app-device', component:GamAppDeviceComponent},
    { path: 'gam-app-geo', component:GamAppGeoComponent},

    { path: 'gam-video-overview', component:GamVideoOverviewComponent},
    { path: 'gam-video-adtypes', component:GamVideoAdtypeComponent},
    { path: 'gam-video-adunit', component:GamVideoAdunitComponent},
    { path: 'gam-video-sites', component:GamVideoSitesComponent},
    { path: 'gam-video-device', component:GamVideoDeviceComponent},
    { path: 'gam-video-geo', component:GamVideoGeoComponent},

    
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AdxAppOverviewComponent,
    AdxAppAdTypesComponent,
    AdxContentOverviewComponent,
    AdxContentAdTypesComponent,
    AdxContentSiteComponent,
    AdxDeviceComponent,
    AdxContentMobileosComponent,
    AdxContentGeoComponent,
    AdxContentAdUnitComponent,
    HeaderComponent,
    AdxAppSitesComponent,
    AdxAppDeviceComponent,
    AdxAppGeoComponent,
    AdxAppMobileosComponent,
    AdxAppAdunitComponent,
    AdxVideoOverviewComponent,
    AdxVideoAdtypeComponent,
    AdxVideoSitesComponent,
    AdxVideoDeviceComponent,
    AdxVideoGeoComponent,
    AdxVideoMobosComponent,
    AdxVideoAdunitComponent,
    AudienceComponent,
    RtrnvsnewComponent,
    RtrnbypaageComponent,
    RtrnbydeviceComponent,
    TraficsourceComponent,
    TraficsrcbyvalComponent,
    TraficsrcbygeoComponent,
    LandingpageComponent,
    ExitpageComponent,
    BlankpageComponent,
    AdsOverviewComponent,
    AdsSitesNewComponent,
    AdsAdtypeNewComponent,
    AdsDeviceNewComponent,
    BankDetailsComponent,
    LogoutComponent,
    HeaderBidderComponent,
    CyberadsOverviewComponent,
    HeaderBidderGeoComponent,
    HeaderBidderDeviceComponent,
    ProDashboardComponent,
    InsightsComponent,
    ComplainTicketComponent,
    NewTicketComponent,
    TicketDetailsComponent,
    RemnantInventoryComponent,
    CdpComponent,
    SubUserRegestrationComponent,
    AdvertiserComponent,
    FooterComponent,
    InvoiceComponent,
    ListInvoiceComponent,
    AdstxtComponent,
    NetworkDataComponent,
    NetworkBreakupComponent,
    ViewProfileComponent,
    ViewProfilesComponent,
    UpgradesiteComponent,
    NetworkEarningComponent,
    ToolsComponent,
    AdvanceResponsiveComponent,
    CampaignToolsComponent,
    StickyAdsComponent,
    DFPStickyAdsComponent,
    DFPResponsiveTagGeneratorComponent,
    DFPResponsiveAndRefreshComponent,
    DFPRefreshTagComponent,
    GemAdmanagerInvite,
    GemAppInvite,
    GamReportOverviewComponent,
    GamReportAdTypesComponent,
    GamReportAdUnitComponent,
    GamReportSiteComponent,
    GamReportDeviceComponent,
    GamReportGeoComponent,

    GamAppOverviewComponent,
    GamAppAdTypesComponent,
    GamAppAdunitComponent,
    GamAppSitesComponent,
    GamAppDeviceComponent,
    GamAppGeoComponent,

    GamVideoOverviewComponent,
    GamVideoAdtypeComponent,
    GamVideoAdunitComponent,
    GamVideoSitesComponent,
    GamVideoDeviceComponent,
    GamVideoGeoComponent


    
  ],
  imports: [
    BrowserModule,FusionChartsModule,MomentTimezoneModule,FormsModule,
    RouterModule.forRoot(appRoutes),
    ChartModule,HttpClientModule,HttpModule,DataTablesModule
  ],
  exports: [RouterModule],
  providers: [AuthService, CookieService ,UserService,AuthGuard,AdxContentOverviewService,AdxAdtypeService,AdxSiteService,AdxDeviceService,AdxMobileosService,AdxGeoService,AdxAdunitService,AdxAppOverviewService,AdxAppAdtypeService,AdxAppService,AdxAppMobileosService,AdxAppGeoService,AdxAppDeviceService,AdxAppAdunitService,AdxVideoOverviewService,AdxVideoAdtypeService,AdxVideoSiteService,AdxVideoDeviceService,AdxVideoGeoService,AdxVidMobileosService,AdxVidAdunitService,Network_Site_Service,NetworkbreakupdataService,GemAdmanagerService,AppService,GamReportingOverviewService,GamReportingAdTypesService,GamReportingAdUnitService,GamReportingSiteService,GamReportingDeviceService,GamReportingGeoService,GamAppOverviewService,GamAppAdTypesService,GamAppAdunitService,GamAppSitesService,GamAppDeviceService,GamAppGeoService,GamVideoOverviewService,GamVideoAdtypeService,GamVideoSiteService,GamVideoDeviceService,GamVideoGeoService,GamVidAdunitService,ViewprofiledataService,{provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting, highmaps,Drilldown]}],
  bootstrap: [AppComponent]
})
export class AppModule { }
