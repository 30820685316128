import { Component, OnInit, ViewChild, ElementRef   } from '@angular/core';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {Router} from "@angular/router";
// import {FormControl, FormGroup, Validators, FormGroupDirective} from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.css']
})
export class TicketDetailsComponent implements OnInit {
  apiURL: string = 'https://angular.cybermediaservices.net/assets/web_api/support_ticket/send-mail.php';
  test:any;
  data:any;
  test1:any;
  data1:any;
  test3:any;
  data3:any;
  id:any;
  att:any='';
  tkt:any='';
  subject:any='';
  comments:any='';
  status:boolean=true;
  msg: boolean = false;

  @ViewChild('f') form: any;

  display='none';
  
  constructor(private httpClient: HttpClient,private route: ActivatedRoute,private router: Router) { }
  model: any = {};
  pub:any;
  acc:any;
  reply:boolean=true;
  attach:boolean=true;
  errshow:boolean=true;
  modalshow:boolean=false;
  comment:any='';
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getTicketDetails(this.id);
    this.getTicketReplies(this.id);
  }

  onCloseHandled(){
    this.display='none';
  }

  openModal(rid){
    this.display='block';
    this.httpClient.get(this.apiURL+'?q=reply&rid='+rid).subscribe((response)=>{
      this.test3=response;
      this.data3=this.test3.result;
      this.comment=this.data3[0].comments;
      console.log(this.comment);
      
     }) 
    
  }

  public getTicketDetails(id){
    this.httpClient.get(this.apiURL+'?q=details&tid='+id).subscribe((response)=>{
    this.test=response;
    this.data=this.test.result;
    this.att=this.data[0].attachment;
    this.tkt=this.data[0].id;
    this.subject=this.data[0].subject;
    this.comments=this.data[0].comments;
    if(this.data[0].status=='0'){
      this.status=false;
    }
    
    if(this.att==''){this.attach=false;}
    console.log(this.data);
    
  }) 
}

public getTicketReplies(tid){
  this.httpClient.get(this.apiURL+'?q=replies&tid='+tid).subscribe((response)=>{
  this.test1=response;
  this.data1=this.test1.result;
  if(this.data1.length==0){this.reply=false;}else{this.reply=true;}
  console.log(this.data1);
  
}) 
}

close_ticket(tid){
 this.httpClient.get(this.apiURL+'?q=close_tkt&tid='+tid).subscribe((response)=>{
  this.msg=true;
        setTimeout (() => {
          this.router.navigate(['/complain-ticket']);
         }, 5000);
}) ;
}


onClickSubmit(data) {
  this.errshow=true;
  console.log('ticket form submission');
  data['tid']=this.id;
  data['commentby']='Publisher';
  const formData: FormData = new FormData();
  formData.append('data', JSON.stringify(data));
  return this.httpClient.post(this.apiURL+'?q=newreply',formData).subscribe((response)=>{
      this.getTicketReplies(this.id);  
      this.errshow=false;
      console.log(this.errshow);
  }) ;
  
 }

}
