import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { AdvertiserService } from './advertiser.service';
declare var $: any;

@Component({
  selector: 'app-advertiser',
  templateUrl: './advertiser.component.html',
  styleUrls: ['./advertiser.component.css']
})
export class AdvertiserComponent implements OnInit {
  multichart: Chart;
  public errorMsg;
  public vertical;
  public multichartdata = [];
  public tabulardata = [];
  public loading: boolean = true;

  getmultichart(x) {
    let multichart = new Chart({	
    chart: {
      zoomType: 'xy',
      marginBottom:220,

    },
    credits: {
      enabled: false
    },
    exporting: { enabled: false },

    colors: ['#8fc321', '#ff9f00' ,'#cd7f01', '#0182c3', '#e91d63', '#4f5d68','#00a698',
     '#00689d', '#c90649', '#008477', '#739c1a' , '#404a53'],


    title: {
      text: null,
    },
    subtitle: {
      text: '',
    },


    xAxis: [{
      categories: x.vertical,
      crosshair: true,

      title: {
          text: 'Advertiser Verticals',
          style: {
                color: '#000',
          }
      },

    labels: {
              formatter: function() {
                return this.value
              }
            }

      }],
    yAxis: [{ // Primary yAxis
      labels: {
          format: '{value}',
          style: {
                color: '#000',
          }
      },



      title: {
          text: 'Impression',
          style: {
                color: '#000',
          }
      },
      opposite: false,

    }, { // Secondary yAxis
      gridLineWidth: 0,
      title: {
          text: 'eCPM',
          style: {
                color: '#000',
          }
      },
      labels: {
          format: '$ {value}',
          style: {
              color: '#000',
          }
      },
      opposite: true,

    }],
    tooltip: {
         shared: true,
    },

    // tooltip: {
    //   shared: true,
    //   formatter: function () {
    //       var points = this.points;
    //       var pointsLength = points.length;
    //       var num=(points[0].key)+1;
    //       var tooltipMarkup = pointsLength ? '<span style="font-size: 10px">' + num + '</span><br/>' : '';
    //       var index;
    //       var y_value;

    //       for(index = 0; index < pointsLength; index += 1) {
    //         y_value = (points[index].y);

    //         tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>'+ y_value +'</b><br/>';
    //       }
          
    //       return tooltipMarkup;
    //   }
    // },
    plotOptions: {
      series: {
          dataLabels: {
              enabled: false,
      formatter: function() {
                  return '<b>' + this.point.name + '</b>: $' + this.percentage.toFixed(2);
              },
          }

      }
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      x: 0,
      verticalAlign: 'bottom',
      y: 5,
      floating: true,
      backgroundColor:'#FFFFFF',
      borderColor: '#CCC',
      borderWidth: 0,
      shadow: false,
      enabled:true,

    },
    series: [{
      name: 'Impression',
      type: 'column',
      data: x.impression,

      

    }
    , {
      name: 'eCPM',
      type: 'line',
      yAxis: 1,
      data: x.CPM,

    }
  ]
    });
    this.multichart = multichart;
}


  constructor(private _AdvertiserService:AdvertiserService) { }

  ngOnInit() {
    this._AdvertiserService.getadvertiserdata(localStorage.getItem('anlytic_id'),localStorage.getItem('uniq_id'))
    .subscribe(data => {
      this.loading = false;
      this.multichartdata = data;
      this.getmultichart(this.multichartdata);
      this.tabulardata = data['table_data'];
      this.vertical = data['vertical_name'];
    },
    error => this.errorMsg = error);
  }

}
