import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { LandingpageService } from './landingpage.service';
declare var $: any;

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  chart: Chart;
  donutchart:Chart;
  donutchart1:Chart;
  public lpchartdata = [];
  public donutchatdata1 = [];
  public topdata = []; 
  public lptabledata = []; 
   public dateranges = []; 
   public errorMsg;
   public donutchartdata =[];
   public donutdata =[];
   public loading: boolean = true;
   setheightpage()
 {
	$(document).ready(function(){
		var page_height='';
        $(".page-content").css("height", page_height);
        $(".page-content-wrap").css("height", page_height);
	});
 }
   
   getlpchart(x)
   {
       
 let donutchart = new Chart({	
  chart: {
    zoomType: 'xy',
 marginBottom:100,

},
credits: {
    enabled: false
},
exporting: { enabled: false },

colors: ['#0182c3', '#e91d63', '#ff9f00', '#4f5d68','#00a698',
'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],


title: {
    text: null,
},
subtitle: {
    text: '',
},


xAxis: [{
    // categories: x.categories,
    crosshair: true,

    title: {
        text: 'S.No. of Landing Page',
        style: {
             color: '#000',
        }
    },

labels: {
                formatter: function() {
                    return this.value+1
                }
            }

    }],
yAxis: [{ // Primary yAxis
    labels: {
        format: '{value}%',
        style: {
             color: '#000',
        }
    },



    title: {
        text: 'Bounce Rate',
        style: {
             color: '#000',
        }
    },
    opposite: true

}, { // Secondary yAxis
    gridLineWidth: 0,
    title: {
        text: 'Pageview',
        style: {
             color: '#000',
        }
    },
    labels: {
        format: '{value}',
        style: {
           color: '#000',
        }
    }

}, { // Tertiary yAxis
    gridLineWidth: 0,
    title: {
        text: 'Session Duration',
        style: {
            color: '#000',
        }
    },
    labels: {
        format: '{value} Sec.',
        style: {
           // color: Highcharts.getOptions().colors[1]
      color: '#000',
        }
    },
    opposite: true
}],
// tooltip: {
//      shared: true,
//     formatter: function() {
//         return this.x+1 ;
//     }
// },

tooltip: {
    shared: true,
    formatter: function () {
        var points = this.points;
        var pointsLength = points.length;
        var num=(points[0].key)+1;
        var tooltipMarkup = pointsLength ? '<span style="font-size: 10px">' + num + '</span><br/>' : '';
        var index;
        var y_value;

        for(index = 0; index < pointsLength; index += 1) {
          y_value = (points[index].y);

          tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>'+ y_value +'</b><br/>';
        }
        
        return tooltipMarkup;
    }
},
plotOptions: {
    series: {
        dataLabels: {
            enabled: false,
    formatter: function() {
                return '<b>' + this.point.name + '</b>: ' + this.percentage.toFixed(2) + ' %';
            },
        }

    }
},

legend: {
    layout: 'horizontal',
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 20,
    floating: true,
    backgroundColor:'#FFFFFF',
 borderColor: '#CCC',
    borderWidth: 0,
    shadow: false,
    enabled:true,

},
series: [{
    name: 'Pageview',
    type: 'column',
    yAxis: 1,
    data: x.data,
    

}, {
    name: 'Session Duration',
    type: 'spline',
    yAxis: 2,
     data: x.data1,
    // marker: {
    //     enabled: false
    // },
    // dashStyle: 'shortdot',
    // tooltip: {
    //     valueSuffix: '%'
    // }

}, {
    name: 'Bounce Rate',
    type: 'spline',
    data: x.data2,
  
}]
});
     this.donutchart = donutchart;
}

  constructor(private _LandingpageService:LandingpageService) { }

  ngOnInit() {
    this.setheightpage();
    this._LandingpageService.getdonutchart(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.lpchartdata = data;this.topdata = data['topdata'];this.getlpchart(this.lpchartdata);this.lptabledata = data['tabledata'];},
    error => this.errorMsg = error);
  }

}
