import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-DFP-Sticky-ads',
  templateUrl: './DFP-Sticky-ads.component.html',
  styleUrls: ['./tools.component.css']
})
export class DFPStickyAdsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
