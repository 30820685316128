import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-ads',
  templateUrl: './sticky-ads.component.html',
  styleUrls: ['./sticky-ads.component.css']
})
export class StickyAdsComponent implements OnInit {
  public adxId;
  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('adx_id')!= '' && localStorage.getItem('adx_id')!=''){
		  this.adxId=localStorage.getItem('adx_id');
		}else{
		  this.adxId=false;
		}
    // console.log("adx_id ==>"+localStorage.getItem('adx_id'));
  }

}
