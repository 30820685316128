import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { videodevice } from './adx-video-device';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxVideoDeviceService {  
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_video/cyberads_video_device_networks.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_video/cyberads_video_device.php";
    }
    console.log("AdxVideo usertype =>"+this.usertype);
  }

  getVideoDevice(daterange,acc_mgr_id,acc_name,new_acc_name,uniqid): Observable<videodevice[]>{
    return this.http.get<videodevice[]>(this._url+ "?"+daterange+"&acc_mgr_id="+acc_mgr_id+"&acc_name="+acc_name+"&new_acc_name="+new_acc_name+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}