import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { geo } from './gam-geo';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamReportingGeoService {  
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_report_geo.php"; 
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_report_geo.php";
    }
  }

  getGeo(daterange,child_net_code,uniqid): Observable<geo[]>{
    return this.http.get<geo[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}