import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { invoicedata } from './invoicedata';
import { tap, catchError } from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoicedataService {
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/network_invoicedata.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/invoicedata.php";
    }
  }

  getInvoicedata(adxid,acc_mgr_id,adxname,uniqid,monthyear,new_adx_name): Observable<invoicedata[]>{
    return this.http.get<invoicedata[]>(this._url+ "?adxid="+adxid+"&acc_mgr_id="+acc_mgr_id+"&adxname="+adxname+"&uniq_id="+uniqid+"&monthyear="+monthyear+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
