import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { TopcontenttotalService } from './topcontenttotal.service';
import { BarcharttotalService } from './barcharttotal.service';
import { PioecharttotaalService } from './pioecharttotaal.service';
import { DonutcharttotalService } from './donutcharttotal.service';
import { HetmaptotalService } from './hetmaptotal.service';
import { HeatmaphourlytotalService } from './heatmaphourlytotal.service';
import { indbenchservice } from './indbench.service';
import { DashboardService } from './dashboard.service';
import { AdvertiserService } from './advertiser.service';
import { AdunitdetailService } from '../remnant-inventory/adunitdetail.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { Compiler } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import * as moment from 'moment';
declare var swal: any;
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	public strtdate: string;
	public enddate: Date;
	private isValid: boolean = true;
	private isValidheatmap: boolean = true;
	title = "";
	grphdate;
	private loading: boolean = true;
	private loadingpie: boolean = true;
	private loadinglinechart: boolean = true;
	private loadingdonut: boolean = true;
	private loadingheatmap: boolean = true;
	private loadingheatmapdata: boolean = true;
	private loadingindbench: boolean = true;
	private adsmenu: boolean = true;
	private indbench_flag: boolean;
	public topdatas = [];
	public month_details = [];
	public dateranges = [];
	public indbenchdata = [];
	public errorMsg;
	appdata;
	displaydata;
	viddata;
	public adxdata;
	public adxmenu;
	public adsdata;
	public remnant_data;
	public piedata = [];
	public donutdata = [];
	public flagdata = [];
	public graph_data = [];
	public topadsgraphdata = [];
	public topappgraphdata = [];
	public topcontentgraphdata = [];
	public topvideographdata = [];
	public piechartdata = [];
	public selwebsite;
	public heatmapprogress = [];
	public heatmapdata = [];
	public heatmapdate = [];
	public donutchartdata = [];
	public heatmaphourlydata = [];
	public heatmaphourlydata1 = [];
	public heatmaphourlydata2 = [];
	public heatmaphourlydata3 = [];
	public heatmaphourlydata4 = [];
	public heatmaphourlydata5 = [];
	public heatmaphourlydata6 = [];
	public heatmaphourlydata7 = [];
	public heatmaphourlydata8 = [];
	public heatmaphourlydata9 = [];
	public seriesresult = [];
	public vertical;
	public multichartdata = [];
	public advertabulardata = [];
	public showIndbench;
	public showAdvertiser;
	public backbtn;
	public userName;
	public diviceshow;
	//   public pub_net_pop_name;
	chart: Chart;
	multichart: Chart;
	piechart: Chart;
	donutchart: Chart;
	heatmap: Chart;
	heatmap1: Chart;
	heatmap2: Chart;
	heatmap3: Chart;
	heatmap4: Chart;
	heatmap5: Chart;
	heatmap6: Chart;
	heatmap7: Chart;
	heatmap8: Chart;
	heatmap9: Chart;
	cookieValue = 'UNKNOWN';
	cookieValue1 = 'UNKNOWN';
	cookieValue2 = 'UNKNOWN';
	cookieValue3 = 'UNKNOWN';
	cookieValue4 = 'UNKNOWN';
	cookieValue5 = 'UNKNOWN';
	cookieValue6 = 'UNKNOWN';
	cookieValue7 = 'UNKNOWN';
	cookieValue8 = 'UNKNOWN';
	cookieValue9 = 'UNKNOWN';
	cookieValue10 = 'UNKNOWN';
	cookieValue11 = 'UNKNOWN';
	cookieValue12 = 'UNKNOWN';
	cookieValue13 = 'UNKNOWN';

	setheightpage() {
		$(document).ready(function () {
			var page_height = '';
			$(".page-content").css("height", page_height);
		});
	}
	getgraphdata(y, a, r, f) {
		if (f.adxflag == 0) {
			this.adxdata = {
				name: 'CyberAds',
				data: 0,
				color: '#e91e63',
				visible: false
			};
		}
		else {
			this.adxdata = {
				name: 'CyberAds',
				data: y.revenue,
				color: '#e91e63',
			};
		}
		if (f.adsflag == 0) {
			this.adsdata = {
				name: 'AdSense',
				data: a.revenue,
				color: '#0182c3',
				visible: false
			};
		}
		else {
			this.adsdata = {
				name: 'AdSense',
				data: a.revenue,
				color: '#0182c3',
			};
		}
		if (f.pro_flag == 0) {
			this.remnant_data = {
				name: 'Unfilled',
				data: r.rev_graph,
				color: '#8fc321',
				visible: false
			}
		} else {
			this.remnant_data = {
				name: 'Unfilled',
				data: r.rev_graph,
				color: '#8fc321',
			}
		}


		let chart = new Chart({
			chart: {

				spacingBottom: 5,
				spacingTop: 10,
				spacingLeft: 10,
				spacingRight: 10,

				type: 'area',
				marginBottom: 70,
				style: {
					fontFamily: 'arial',
				},


			},
			title: {
				text: '',
			},

			legend: {
				align: 'center',
				verticalAlign: 'bottom',
				x: 0,
				y: 10,
				floating: true,
				borderWidth: 0,
				backgroundColor: '#FFFFFF'
			},
			xAxis: {
				categories: y.date,
			},
			yAxis: {
				title: {
					text: 'Estimated Revenue ($)'
				}
			},


			tooltip: {
				shared: true,
				valuePrefix: '$',


			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },


			plotOptions: {
				series: {
					dataLabels: {
						//enabled: true,
						// format: '${y}',
						borderRadius: 5,
						//backgroundColor: 'rgba(252, 255, 197, 0.7)',
						borderWidth: 0,
						borderColor: 'rgba(255, 255, 255, 0.18)',
						y: -6
					},



				},

				area: {
					fillOpacity: 0.08
				}
			},
			series: [this.adxdata, this.adsdata, this.remnant_data]
		});
		this.chart = chart;
		function init_daterangepicker() {
			setTimeout(() => {
				if ("undefined" != typeof $.fn.daterangepicker) {
					console.log("init_daterangepicker");
					var a = function (a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
						b = {
							startDate: moment().subtract(7, "days"),
							endDate: moment(),
							// minDate: moment().subtract(3, "month"),
							minDate: new Date('2019-08-01'),
							maxDate: moment(),
							dateLimit: {
								days: 90
							},
							showDropdowns: !0,
							showWeekNumbers: !0,
							timePicker: !1,
							timePickerIncrement: 1,
							timePicker12Hour: !0,
							ranges: {
								Today: [moment(), moment()],
								Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
								"Last 7 Days": [moment().subtract(6, "days"), moment()],
								"Last 30 Days": [moment().subtract(29, "days"), moment()],
								"This Month": [moment().startOf("month"), moment()],
								"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
							},
							opens: "left",
							buttonClasses: ["btn bg-pink"],
							applyClass: "btn-small btn-primary",
							cancelClass: "btn-small",
							format: "MM/DD/YYYY",
							separator: " to ",
							locale: {
								applyLabel: "Submit",
								cancelLabel: "Clear",
								fromLabel: "From",
								toLabel: "To",
								customRangeLabel: "Custom",
								daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
								monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
								firstDay: 1
							}
						};

					$(".custom-daterangepicker span").html(localStorage.getItem('enddate') + "-" + localStorage.getItem('startdate'));
					$(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function () {
						console.log("show event fired")
					}), $(".custom-daterangepicker").on("hide.daterangepicker", function () {
						console.log("hide event fired")
					}), $(".custom-daterangepicker").on("apply.daterangepicker", function (a, b) {

						let strtdate = "strtdate=" + b.startDate.format("YYYY-MM-DD") + "&enddate=" + b.endDate.format("YYYY-MM-DD");
						$("#dateinput").val(strtdate);
						let startdate = b.startDate.format("MMMM D, YYYY");
						let enddate = b.endDate.format("MMMM D, YYYY");
						localStorage.setItem('strtdate', strtdate);
						localStorage.setItem('startdate', enddate);
						localStorage.setItem('enddate', startdate);
						window.location.reload();


						console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
					}), $(".custom-daterangepicker").on("cancel.daterangepicker", function (a, b) {
						console.log("cancel event fired")
					}), $("#options1").click(function () {
						$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
					}), $("#options2").click(function () {
						$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
					}), $("#destroy").click(function () {
						$(".custom-daterangepicker").data("daterangepicker").remove()
					})
				}
			}, 2000);

		}

		function init_daterangepicker_right() {
			setTimeout(() => {
				if ("undefined" != typeof $.fn.daterangepicker) {
					console.log("init_daterangepicker_right");
					var a = function (a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
						b = {
							startDate: moment().subtract(6, "days"),
							endDate: moment(),
							// minDate: moment().subtract(3, "month"),
							minDate: new Date('2019-08-01'),
							maxDate: moment(),
							dateLimit: {
								days: 90
							},
							showDropdowns: !0,
							showWeekNumbers: !0,
							timePicker: !1,
							timePickerIncrement: 1,
							timePicker12Hour: !0,
							ranges: {
								Today: [moment(), moment()],
								Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
								"Last 7 Days": [moment().subtract(6, "days"), moment()],
								"Last 30 Days": [moment().subtract(29, "days"), moment()],
								"This Month": [moment().startOf("month"), moment()],
								"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
							},
							opens: "right",
							buttonClasses: ["btn btn-default"],
							applyClass: "btn-small btn-primary",
							cancelClass: "btn-small",
							format: "MM/DD/YYYY",
							separator: " to ",
							locale: {
								applyLabel: "Submit",
								cancelLabel: "Clear",
								fromLabel: "From",
								toLabel: "To",
								customRangeLabel: "Custom",
								daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
								monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
								firstDay: 1
							}
						};
					$("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function () {
						console.log("show event fired")
					}), $("#reportrange_right").on("hide.daterangepicker", function () {
						console.log("hide event fired")
					}), $("#reportrange_right").on("apply.daterangepicker", function (a, b) {
						console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
					}), $("#reportrange_right").on("cancel.daterangepicker", function (a, b) {
						console.log("cancel event fired")
					}), $("#options1").click(function () {
						$("#reportrange_right").data("daterangepicker").setOptions(b, a)
					}), $("#options2").click(function () {
						$("#reportrange_right").data("daterangepicker").setOptions(b, a)
					}), $("#destroy").click(function () {
						$("#reportrange_right").data("daterangepicker").remove()
					})
				}
			}, 2000);
		}

		function init_daterangepicker_single_call() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_1"
			}, function (a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal2").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_2"
			}, function (a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal3").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_3"
			}, function (a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal4").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_4"
			}, function (a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}))
		}

		function init_daterangepicker_reservation() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function (a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#reservation-time").daterangepicker({
				timePicker: !0,
				timePickerIncrement: 30,
				locale: {
					format: "MM/DD/YYYY h:mm A"
				}
			}))
		}

		function init_SmartWizard() {
			"undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
				transitionEffect: "slide"
			}), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
		}




		// var originalLeave = $.fn.popover.Constructor.prototype.leave;
		$.fn.popover.Constructor.prototype.leave = function (a) { }, $("body").popover(), $(document).ready(function () {
			init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
		});
	}
	getpiechartdata(x) {
		let piechart = new Chart({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			credits: {
				enabled: false
			},
			title: {
				text: ''
			},
			tooltip: {
				pointFormat: '${point.y}'
			},
			exporting: { enabled: false },
			plotOptions: {
				series: {
					cursor: 'pointer',
					events: {
						// click: function (event) {
						// 	window.location.href = '';
						// }
					}
				},
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: 'Revenue',
				// colorByPoint: true,
				data: [{
					name: '(Unmatched ad requests)',
					y: x.Unmatchedadrequests,
					sliced: true,
					selected: true,
					color: '#0182c3',
				}, {
					name: 'Animated image',
					y: x.Animatedimage,
					color: '#00a698',
				}, {
					name: 'Video',
					y: x.Video,
					color: '#8fc321'
				}, {
					name: 'Text',
					y: x.Text,
					color: '#ff9f00',
				},
				{
					name: 'Image',
					y: x.Image,
					color: '#ee2201',
				},
				{
					name: 'Rich media',
					y: x.Richmedia,
					color: '#4f5d68',
				},
					// {
					// 	name: 'Matched Content',
					// 	y: x.Matchedcontent,
					// 	color: '#4f5d68',
					// },

				]
			}]
		});
		this.piechart = piechart;
	}
	getdonutchart(y) {
		if (y.Desktop != null) {
			this.isValid = true;
		}
		else {
			this.isValid = false;
		}
		let donutchart = new Chart({
			chart: {
				type: 'pie',
				spacingBottom: 5,
				spacingTop: 0,
				spacingLeft: 0,
				spacingRight: 0,
				//type: 'areaspline',
				marginBottom: 0,
				style: {
					fontFamily: 'arial',
				},
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			colors: ['#0182c3', '#e91d63', '#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
				'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
			],
			title: {
				text: "",
				// style: {
				//     fontSize: 13,
				//     fontWeight: 'bold',
				// },
			},
			tooltip: {
				// valueSuffix: '%',
				// enabled: true,
				pointFormat: '{point.y}'
			},
			plotOptions: {
				series: {
					cursor: 'pointer',
					events: {
						// click: function (event) {
						// 	window.location.href = '';
						// }
					}
				},
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					size: '60%',
					innerSize: '50%',
					dataLabels: {
						enabled: true,
					},
					showInLegend: true,
				},

			},
			series: [{
				name: 'Visitors',
				// colorByPoint: true,
				data: [{
					name: 'Mobile',
					y: y.Highendmobiledevices,
					sliced: true,
					selected: true,
					// url:'rich-media.html',
				}, {
					name: 'Desktop',
					y: y.Desktop,
					// url:'rich-media.html',
				},
				{
					name: 'Tablet',
					y: y.Tablets,
					// url:'rich-media.html',
				},
				{
					name: 'Other devices',
					y: y.Otherdevices,
					// url:'rich-media.html',
				},
				]
			}]
		});
		this.donutchart = donutchart;

	}
	getheatmaphourly1(y) {
		let heatmap1 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			exporting: { enabled: false },
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap1 = heatmap1;
	}
	getheatmaphourly2(y) {
		let heatmap2 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap2 = heatmap2;
	}
	getheatmaphourly3(y) {
		let heatmap3 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap3 = heatmap3;
	}
	getheatmaphourly4(y) {
		let heatmap4 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,
			}]

		});
		this.heatmap4 = heatmap4;
	}
	getheatmaphourly5(y) {
		let heatmap5 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap5 = heatmap5;
	}
	getheatmaphourly6(y) {
		let heatmap6 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap6 = heatmap6;
	}
	getheatmaphourly7(y) {
		let heatmap7 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap7 = heatmap7;
	}
	getheatmaphourly8(y) {
		let heatmap8 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap8 = heatmap8;
	}
	getheatmaphourly9(y) {
		let heatmap9 = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 40,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap9 = heatmap9;
	}
	getheatmaphourly(y) {
		let heatmap = new Chart({
			chart: {
				type: 'heatmap',
				marginTop: 20,
				marginBottom: 80,
				plotBorderWidth: 0
			},
			exporting: { enabled: false },
			credits: {
				enabled: false
			},
			title: {
				text: null,
			},
			xAxis: {
				categories: y.date,
				title: null,
				gridLineColor: 'transparent',
			},
			yAxis: {
				categories: ['0', '10 PM', '08 PM', '06 PM', '04 PM', '02 PM', '12 PM', '10 AM', '08 AM', '06 AM', '04 AM', '02 AM', '12 AM'],
				reversed: true,
				title: null,
				gridLineColor: 'transparent',
				opposite: true,
				labels: {
					style: {
						color: '#666',
						fontFamily: 'arial',
					}
				}
			},
			colorAxis: {
				stops: [
					[0, '#f1f1f1'],
					[0.5, '#e91e63'],
					[0.9, '#00859a']
				],
				min: 5,

			},
			legend: {

				enabled: true,
				floating: true,
				verticalAlign: 'bottom',
				align: 'bottom', symbolWidth: 510,
				layout: 'horizontal',
				margin: 0,
				itemWidth: 50,
				y: 15,
				x: 0,
				symbolHeight: 8

			},
			tooltip: {
				enabled: false,
				formatter: function () {
					return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> Earnings <br><b>' +
						this.point.value + '</b>$ on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
				}
			},

			series: [{
				name: 'monday',
				data: y.heatmapdata,

			}]

		});
		this.heatmap = heatmap;

	}
	getheatmapprogressbar(p) {
		for (var k = 0; k < 10; k++) {
			$("div#progress" + k).css({ 'background-color': '#4f5d68' });
			$("div#progress" + k).css({ 'width': p[k].viewpercentage });
		}
	}
	getdaterange() {
		//localStorage.clear();
		var asiaTime = new Date().toLocaleString("en-US", { timeZone: "Pacific/Honolulu" });
		var today = new Date(asiaTime);
		var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
		var strtmnth = ("0" + (lastWeek.getMonth() + 1)).slice(-2);
		var strtday = ("0" + lastWeek.getDate()).slice(-2);
		var endmnth = ("0" + (today.getMonth() + 1)).slice(-2);
		var endday = ("0" + today.getDate()).slice(-2);
		var finalstrtdate = [lastWeek.getFullYear(), strtmnth, strtday].join("-");
		var finalenddate = [today.getFullYear(), endmnth, endday].join("-");
		let strtdate = "strtdate=" + finalstrtdate + "&enddate=" + finalenddate;
		localStorage.setItem('strtdate', strtdate);
	}

	getmultichart(x) {
		let multichart = new Chart({
			chart: {
				zoomType: 'xy',
				marginBottom: 220,

			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },

			colors: ['#8fc321', '#ff9f00', '#cd7f01', '#0182c3', '#e91d63', '#4f5d68', '#00a698',
				'#00689d', '#c90649', '#008477', '#739c1a', '#404a53'],


			title: {
				text: null,
			},
			subtitle: {
				text: '',
			},


			xAxis: [{
				categories: x.vertical,
				crosshair: true,

				title: {
					text: 'Advertiser Verticals',
					style: {
						color: '#000',
					}
				},

				labels: {
					formatter: function () {
						return this.value
					}
				}

			}],
			yAxis: [{ // Primary yAxis
				labels: {
					//   format: '{value}',
					style: {
						color: '#000',
					}
				},



				title: {
					text: 'Impression',
					style: {
						color: '#000',
					}
				},
				opposite: false,

			}, { // Secondary yAxis
				gridLineWidth: 0,
				title: {
					text: 'eCPM',
					style: {
						color: '#000',
					}
				},
				labels: {
					format: '$ {value}',
					style: {
						color: '#000',
					}
				},
				opposite: true,

			}],
			tooltip: {
				shared: true,
			},

			// tooltip: {
			//   shared: true,
			//   formatter: function () {
			//       var points = this.points;
			//       var pointsLength = points.length;
			//       var num=(points[0].key)+1;
			//       var tooltipMarkup = pointsLength ? '<span style="font-size: 10px">' + num + '</span><br/>' : '';
			//       var index;
			//       var y_value;

			//       for(index = 0; index < pointsLength; index += 1) {
			//         y_value = (points[index].y);

			//         tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>'+ y_value +'</b><br/>';
			//       }

			//       return tooltipMarkup;
			//   }
			// },
			plotOptions: {
				series: {
					dataLabels: {
						enabled: false,
						formatter: function () {
							return '<b>' + this.point.name + '</b>: $' + this.percentage.toFixed(2);
						},
					}

				}
			},

			legend: {
				layout: 'horizontal',
				align: 'center',
				x: 0,
				verticalAlign: 'bottom',
				y: 5,
				floating: true,
				backgroundColor: '#FFFFFF',
				borderColor: '#CCC',
				borderWidth: 0,
				shadow: false,
				enabled: true,

			},
			series: [{
				name: 'Impression',
				type: 'column',
				data: x.impression,



			}
				, {
				name: 'eCPM',
				type: 'line',
				yAxis: 1,
				data: x.CPM,

			}
			]
		});
		this.multichart = multichart;
	}


	constructor(private _topcontentService: TopcontenttotalService, private router: Router, private _topgraphService: BarcharttotalService, private _piechartService: PioecharttotaalService, private _donutchartService: DonutcharttotalService, private _heatmapService: HetmaptotalService, private _hourlyheatmapService: HeatmaphourlytotalService, private cookieService: CookieService, private _indbenchservice: indbenchservice, private _dashboardService: DashboardService, private _AdunitdetailService: AdunitdetailService, private _AdvertiserService: AdvertiserService, private _location: Location, private _compiler: Compiler) { }
	ngOnInit(): void {

		if (this.cookieService.get('uniq_id') != "") {
			if (this.cookieService.get('uniq_id') != localStorage.getItem('uniq_id')) {
				this.getdaterange();
				console.log('>>' + this.cookieService.get('acc_name'));
				this.cookieValue = this.cookieService.get('acc_name');
				this.cookieValue8 = this.cookieService.get('old_acc_name');
				this.cookieValue1 = this.cookieService.get('adx_id');
				this.cookieValue2 = this.cookieService.get('ads_id');
				this.cookieValue3 = this.cookieService.get('anlytic_id');
				this.cookieValue4 = this.cookieService.get('uniq_id');
				this.cookieValue5 = this.cookieService.get('startdate');
				this.cookieValue6 = this.cookieService.get('enddate');
				this.cookieValue7 = this.cookieService.get('isdemo');
				this.cookieValue9 = this.cookieService.get('accmgr');
				this.cookieValue10 = this.cookieService.get('childNetworkCode');
				this.cookieValue11 = this.cookieService.get('usertype');
				this.cookieValue12 = this.cookieService.get('new_MCMpub_name');
				this.cookieValue13 = this.cookieService.get('access_token');
				localStorage.setItem('acc_name', this.cookieValue);
				localStorage.setItem('old_acc_name', this.cookieValue8);
				localStorage.setItem('adx_id', this.cookieValue1);
				localStorage.setItem('ads_id', this.cookieValue2);
				localStorage.setItem('anlytic_id', this.cookieValue3);
				localStorage.setItem('uniq_id', this.cookieValue4);
				localStorage.setItem('startdate', this.cookieValue5);
				localStorage.setItem('enddate', this.cookieValue6);
				localStorage.setItem('website_name', 'all');
				localStorage.setItem('isdemo', this.cookieValue7);
				localStorage.setItem('accmgr', this.cookieValue9);
				localStorage.setItem('child_net_code', this.cookieValue10);
				localStorage.setItem('usertype', this.cookieValue11);
				localStorage.setItem('new_MCMpub_name', this.cookieValue12);
				localStorage.setItem('access_token', this.cookieValue13);
				this.cookieService.deleteAll("/", ".cybermediaservices.net");
				// window.open('dashboard', '_self');
			}
		} else {
			this.cookieService.deleteAll("/", ".cybermediaservices.net");
		}


		// if(localStorage.getItem('usertype')=="2"){
		// 	this.pub_net_pop_name = localStorage.getItem('userName');
		// }else{
		// 	this.pub_net_pop_name = localStorage.getItem('acc_name');
		// }
		// swal.fire({
		// 	html: "<div style='text-align:left;'>Dear <strong>"+this.pub_net_pop_name+"</strong>, <br><br>Due to changes in Google API we are updating Safe data. The accurate reconciled figures will be available from 04-12-2020 onwards. <br><br>Apologies for the inconvenience. In case of any urgent concerns please connect with your account manager. <br><br><br><br>Sincerely, <br>Safe Team</div>",
		// 	icon: "warning",
		// 	button: "Ok",
		// });


		if (localStorage.getItem('ads_id') != '' && localStorage.getItem('ads_id') != 'ca-') {
			this.adsmenu = true;
		}
		else {
			this.adsmenu = false;
		}
		if (localStorage.getItem('adx_id') != '' && localStorage.getItem('acc_name') != '') {
			this.adxmenu = true;
		}
		else {
			this.adxmenu = false;
		}
		if (localStorage.getItem('usertype') == "2") {
			this.showIndbench = false;
			this.showAdvertiser = false;
			this.diviceshow = true;
		} else {
			this.showIndbench = true;
			this.showAdvertiser = true;
			this.diviceshow = false;
		}
		if (localStorage.getItem('networkred') != '' && localStorage.getItem('networkred') == 'net') {
			this.backbtn = true;
		} else {
			this.backbtn = false;
		}
		setTimeout(() => {
			let websites_nme = localStorage.getItem('website_name');
			//alert(websites_nme);
			$("#web_name option[value='" + websites_nme + "']").attr("selected", "selected");

		}, 3000);


		this._AdunitdetailService.getadunitsize(localStorage.getItem('uniq_id'), localStorage.getItem('strtdate'))
			.subscribe(data => {
				this.graph_data = data['graph_data'];
				//   this.getlinegraphdata(this.graph_data);
			},
				error => this.errorMsg = error);

		this._indbenchservice.getindbench(localStorage.getItem('uniq_id'), localStorage.getItem('anlytic_id')).subscribe(data => {
			this.loadingindbench = false;
			this.indbenchdata = data['ind_bench'];
			this.indbench_flag = data['ind_bench'][0]['no_analytic_flag'];
			console.log(this.indbench_flag);
		},
			error => this.errorMsg = error);

		this._dashboardService.getDashboard(localStorage.getItem('acc_name')).subscribe(data => {
			this.loading = false;
			if (data['diff'] >= 1) {

				swal.fire({
					title: "Popup",
					text: "Oops! Your data is being reconciled.Thank you for your patience. Happy Earnings!",
					icon: "warning",
					button: "Ok",
				});
			}
		},
			error => this.errorMsg = error);

		this._topcontentService.getTopcontent(localStorage.getItem('strtdate'), localStorage.getItem('acc_mgr_id'), localStorage.getItem('acc_name'), localStorage.getItem('ads_id'), localStorage.getItem('old_acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('child_net_code'))
			.subscribe(data => {
				this.topdatas = data['app_top_data'];
				if (data['app_top_data']["0"]['today'] == "0.00" && data['app_top_data']["0"]['yesterday'] == "0.00" && data['app_top_data']["0"]['last7days'] == "0.00" && data['app_top_data']["0"]['this_month'] == "0.00" && data['app_top_data']["0"]['previous_month'] == "0.00") {
					swal.fire({
						title: "Empty Data",
						text: "No Data For Selected Date Range!",
						icon: "warning",
						button: "Ok",
					});
				}

				this.month_details = data['month_details'];
				this.dateranges = data["app_graph_date"];
				this.setheightpage();
			},
				error => this.errorMsg = error);

		this._topgraphService.getTopgraph(localStorage.getItem('strtdate'), localStorage.getItem('acc_mgr_id'), localStorage.getItem('acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('old_acc_name'), localStorage.getItem('child_net_code'))
			.subscribe(data => {
				this.loadinglinechart = false; this.topappgraphdata = data['adx'], this.topadsgraphdata = data['adsense_data'], this.flagdata = data['flag'],
					this.getgraphdata(this.topappgraphdata, this.topadsgraphdata, this.graph_data, this.flagdata);
			},
				error => this.errorMsg = error);


		this._piechartService.getadspiechart(localStorage.getItem('strtdate'), localStorage.getItem('acc_mgr_id'), localStorage.getItem('acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('website_name'), localStorage.getItem('ads_id'), localStorage.getItem('old_acc_name'), localStorage.getItem('child_net_code'))
			.subscribe(data => {
				this.loadingpie = false; this.piechartdata = data['pie_chart_data'], this.piedata = data['pie_data'];
				this.getpiechartdata(this.piechartdata);
			},
				error => this.errorMsg = error);


		this._donutchartService.getdonutchart(localStorage.getItem('strtdate'), localStorage.getItem('acc_mgr_id'), localStorage.getItem('anlytic_id'), localStorage.getItem('acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('website_name'), localStorage.getItem('ads_id'), localStorage.getItem('child_net_code'))
			.subscribe(data => {
				this.loadingdonut = false; this.donutchartdata = data['donut_chart_data'], this.donutdata = data['donut_data'];
				this.getdonutchart(this.donutchartdata);
			},
				error => this.errorMsg = error);

		this._AdvertiserService.getadvertiserdata(localStorage.getItem('anlytic_id'), localStorage.getItem('uniq_id'))
			.subscribe(data => {
				this.loading = false;
				this.multichartdata = data;
				this.getmultichart(this.multichartdata);
				this.advertabulardata = data['table_data'];
				this.vertical = data['vertical_name'];
			},
				error => this.errorMsg = error);

		if (localStorage.getItem('anlytic_id') != '') {
			this._heatmapService.getheatmapdata(localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
				.subscribe(data => {
					this.heatmapdata = data['result'], this.heatmapdate = data['app_graph_date'], this.heatmapprogress = data['heatmap_Progress'];
					if (data['result'] != null) {
						this.isValidheatmap = true;
						this._hourlyheatmapService.getheatmaphourlydata(0, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
							.subscribe(data => {
								this.loadingheatmap = false; this.heatmaphourlydata = data;
								this.getheatmaphourly(this.heatmaphourlydata);
								this._hourlyheatmapService.getheatmaphourlydata(1, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
									.subscribe(data => {
										this.loadingdonut = false; this.heatmaphourlydata1 = data;
										this.getheatmaphourly1(this.heatmaphourlydata1);
										this._hourlyheatmapService.getheatmaphourlydata(2, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
											.subscribe(data => {
												this.loadingdonut = false; this.heatmaphourlydata2 = data;
												this.getheatmaphourly2(this.heatmaphourlydata2);
												this._hourlyheatmapService.getheatmaphourlydata(3, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
													.subscribe(data => {
														this.loadingdonut = false; this.heatmaphourlydata3 = data;
														this.getheatmaphourly3(this.heatmaphourlydata3);
														this._hourlyheatmapService.getheatmaphourlydata(4, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
															.subscribe(data => {
																this.loadingdonut = false; this.heatmaphourlydata4 = data;
																this.getheatmaphourly4(this.heatmaphourlydata4);
																this._hourlyheatmapService.getheatmaphourlydata(5, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
																	.subscribe(data => {
																		this.loadingdonut = false; this.heatmaphourlydata5 = data;
																		this.getheatmaphourly5(this.heatmaphourlydata5);
																		this._hourlyheatmapService.getheatmaphourlydata(6, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
																			.subscribe(data => {
																				this.loadingdonut = false; this.heatmaphourlydata6 = data;
																				this.getheatmaphourly6(this.heatmaphourlydata6);
																				this._hourlyheatmapService.getheatmaphourlydata(7, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
																					.subscribe(data => {
																						this.loadingdonut = false; this.heatmaphourlydata7 = data;
																						this.getheatmaphourly7(this.heatmaphourlydata7);
																						this._hourlyheatmapService.getheatmaphourlydata(8, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
																							.subscribe(data => {
																								this.loadingdonut = false; this.heatmaphourlydata8 = data;
																								this.getheatmaphourly8(this.heatmaphourlydata8);
																								this._hourlyheatmapService.getheatmaphourlydata(9, localStorage.getItem('strtdate'), localStorage.getItem('anlytic_id'), localStorage.getItem('isdemo'))
																									.subscribe(data => {
																										this.loadingdonut = false; this.heatmaphourlydata9 = data;
																										this.getheatmaphourly9(this.heatmaphourlydata9);
																									},
																										error => this.errorMsg = error);
																							},
																								error => this.errorMsg = error);

																					},
																						error => this.errorMsg = error);
																			},
																				error => this.errorMsg = error);
																	},
																		error => this.errorMsg = error);
															},
																error => this.errorMsg = error);
													},
														error => this.errorMsg = error);
											},
												error => this.errorMsg = error);
									},
										error => this.errorMsg = error);
							},
								error => this.errorMsg = error);
					}
					else {
						this.isValidheatmap = false;
					}
				},
					error => this.errorMsg = error);
		}
		else {
			this.isValidheatmap = false;
		}
	}

	selectwebsite(event: any) {
		//update the ui
		this.selwebsite = event.target.value;
		let webname = event.target.value;
		localStorage.setItem('website_name', webname);
		window.location.reload();
	}

	backToHome() {
		// alert(localStorage.getItem('lastuseremail')); 
		this.userName = localStorage.getItem('lastuseremail');
		this._topcontentService.showBackToHomeData(this.userName).subscribe(data => {
			if (data.success) {
				localStorage.clear();
				this._compiler.clearCache();
				this.getdaterange();
				localStorage.setItem('acc_name', data.pub_name);
				localStorage.setItem('adx_id', data.adx_id);
				localStorage.setItem('ads_id', data.ads_id);
				localStorage.setItem('anlytic_id', data.anlytic_id);
				localStorage.setItem('uniq_id', data.uniq_id);
				localStorage.setItem('website_name', 'all');
				localStorage.setItem('startdate', data.startdate);
				localStorage.setItem('enddate', data.enddate);
				localStorage.setItem('old_acc_name', data.oldname);
				localStorage.setItem('isdemo', data.isdemo);
				localStorage.setItem('acc_mgr_id', data.acc_mgr_id);
				localStorage.setItem('issubuser', data.issubuser);
				localStorage.setItem('usertype', data.usertype);
				localStorage.setItem('accmgr', 'false');
				localStorage.setItem('useremail', data.email);
				localStorage.setItem('userName', this.userName);
				// this._location.back();
				window.location.href = '/network-for-site';
			} else {
				alert(data.message);
			}
		}, error => this.errorMsg = error);

	}


}
