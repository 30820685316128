import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { DonutfirstService } from './donutfirst.service';
import { DonutrtrnbydevService } from './donutrtrnbydev.service';

@Component({
  selector: 'app-rtrnvsnew',
  templateUrl: './rtrnvsnew.component.html',
  styleUrls: ['./rtrnvsnew.component.css']
})
export class RtrnvsnewComponent implements OnInit {
  chart: Chart;
  donutchart:Chart;
  donutchart1:Chart;
  donutchart2:Chart;
  donutchart3:Chart;
  donutchart4:Chart;
  donutchart5:Chart;
  public donutchatdata = [];
  public donutchatdata1 = [];
   public dateranges = [];
   public tabulardata = []; 
   public topdata = []; 
   public topdate = [];
   public tabulardata1 = []; 
   public topdata1 = [];  
   public errorMsg;
   public donutchartdata =[];
   public donutdata =[];
   public loading: boolean = true;
   public donutchatdatadev = [];
  public donutchatdata1dev = [];
  public tabulardatadev = []; 
  public topdatadev = []; 
  public topdatedev = [];
  public tabulardata1dev = []; 
  public topdata1dev = [];  
  public donutdatadev =[];
  public donutdata1dev =[];
  public donutchatdatadevnew = [];
  public donutchatdata1devnew = [];
  public tabulardatadevnew = []; 
  public topdatadevnew = []; 
  public topdatedevnew = [];
  public tabulardata1devnew = []; 
  public topdata1devnew = [];  
  public donutdatadevnew =[];
  public donutdata1devnew =[];

     getdonutchart(x,y)
   {
 let donutchart = new Chart({	
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  credits: {
      enabled: false
  },
  exporting:{enabled: false},

title: {
       text: '( '+y.daterange+' )',
		style:{
		fontSize:16,
		fontWeight:'bold',
		},
      },
  tooltip: {
    valueSuffix: '%',
            enabled: true,
   	pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',

  },
  
    plotOptions: {
    pie: {
      size: '60%',
      innerSize: '50%',
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
		
        format: '<b>{point.name}</b><br>{point.y}',
        style: {
          color:'black'
        }
      },  showInLegend: true,
    },
	
	
	/*legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                width: 200,
                verticalAlign: 'middle',
                useHTML: true,
                labelFormatter: function() {
                    return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
				}
            },
	*/
	
	// for url assign 
	series: {
                cursor: 'pointer',
              
            }
  },
  

 series: [{
    name: 'Visitors',
    data: [
      {
        name: 'New Visitor',
        y: y.returnvisitor,
      color:'#00a698',
  
      },
      {
      name: 'Returning Visitor',
      y: y.newvisitor,
      sliced: true,
      selected: true,
	  color:'#0182c3',
    }     
     ]
  }]
});
   this.donutchart = donutchart;
   
   let donutchart1 = new Chart({	
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    credits: {
        enabled: false
    },
    exporting:{enabled: false},

  title: {
         text: '( '+x.daterange+' )',
      style:{
      fontSize:16,
      fontWeight:'bold',
      },
        },
    tooltip: {
      valueSuffix: '%',
              enabled: true,
       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  
    },
    
      plotOptions: {
      pie: {
        size: '60%',
        innerSize: '50%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
      
          format: '<b>{point.name}</b><br>{point.y}',
          style: {
            color:'black'
          }
        },  showInLegend: true,
      },
    
    
    /*legend: {
                  enabled: true,
                  layout: 'vertical',
                  align: 'right',
                  width: 200,
                  verticalAlign: 'middle',
                  useHTML: true,
                  labelFormatter: function() {
                      return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
          }
              },
    */
    
    // for url assign 
    series: {
                  cursor: 'pointer',
                
              }
    },
    
  
   series: [{
      name: 'Visitors',
      data: [
        {
          name: 'New Visitor',
          y: x.returnvisitor,
        color:'#ff9f00',
    
        },
        {
        name: 'Returning Visitor',
        y: x.newvisitor,
        sliced: true,
        selected: true,
      color:'#8fc321',
      }
       
       ]
    }]
  });
     this.donutchart1 = donutchart1;
}


getdonutchart1(x,y)
   {
 let donutchart2 = new Chart({	
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  credits: {
      enabled: false
  },
exporting: { enabled: false },

  colors: ['#0182c3', '#e91d63', '#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
    '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

title: {
       text:'',
		style:{
		fontSize:16,
		fontWeight:'bold',
		},
      },
  tooltip: {
	   valueSuffix: '%',
            enabled: true,
   	pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  
  
    plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      size: '60%',
      innerSize: '50%',
      dataLabels: {
        enabled: true,
		
        format: '<b>{point.name}</b><br>{point.y}',
        style: {
          color: 'black'
        }
      },  showInLegend: true,
    },
	
	
	/*legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                width: 200,
                verticalAlign: 'middle',
                useHTML: true,
                labelFormatter: function() {
                    return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
				}
            },
	*/
	
	// for url assign 
	series: {
		 
                cursor: 'pointer',
                
            }
  },
  

 series: [{
    name: 'Visitors',
    data: [{
      name: 'Mobile',
      y: y.returnvisitorbydev.mobile,
      sliced: true,
      selected: true,
	 // url:'rich-media.html',
	 

    }, {
      name: 'Desktop',
      y: y.returnvisitorbydev.desktop,
	// url:'rich-media.html',
	  
    },
	
	 {
      name: 'Tablet',
      y: y.returnvisitorbydev.tablet,
	 	 // url:'rich-media.html',
	  
    },
	
	//  {
  //     name: 'TV',
  //     y: 0,
	//  	  //url:'rich-media.html',
	  
  //   }
     
     ]
  }]
});
   this.donutchart2 = donutchart2;
   
   let donutchart3 = new Chart({	
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    credits: {
        enabled: false
    },
    exporting:{enabled: false},
    
    colors: ['#ff9f00', '#00a698', '#8fc321', '#ff6600', '#e91d63', '#4f5d68', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],
  
  title: {
    text: '',
      style:{
      fontSize:16,
      fontWeight:'bold',
      },
        },
    tooltip: {
       valueSuffix: '%',
              enabled: true,
       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    
      plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: '60%',
                  innerSize: '50%',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br>{point.y}',
          style: {
            color:'black'
          }
        },  showInLegend: true,
      },
    
    
    /*legend: {
                  enabled: true,
                  layout: 'vertical',
                  align: 'right',
                  width: 200,
                  verticalAlign: 'middle',
                  useHTML: true,
                  labelFormatter: function() {
                      return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
          }
              },
    */
    
    // for url assign 
    series: {
       
                  cursor: 'pointer',
                  
              }
    },
    
  
   series: [{
      name: 'Visitors',
      data: [{
        name: 'Mobile',
        y: x.returnvisitorbydev.mobile,
        sliced: true,
        selected: true,
    // url:'rich-media.html',
  
      }, {
        name: 'Desktop',
        y: x.returnvisitorbydev.desktop,
      // url:'rich-media.html',
         },
       
        {
        name: 'Tablet',
        y: x.returnvisitorbydev.tablet,
    // url:'rich-media.html',
      },
    // {
    //     name: 'TV',
    //     y: 0,
    //  //url:'rich-media.html',
    //   }
       
       ]
    }]
  });
     this.donutchart3 = donutchart3;
}

getdonutchart2(x,y)
   {
 let donutchart4 = new Chart({	
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  credits: {
      enabled: false
  },
exporting: { enabled: false },

  colors: ['#739c1a', '#008477', '#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
    '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

title: {
       text:'',
		style:{
		fontSize:16,
		fontWeight:'bold',
		},
      },
  tooltip: {
	   valueSuffix: '%',
            enabled: true,
   	pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  
  
    plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      size: '60%',
      innerSize: '50%',
      dataLabels: {
        enabled: true,
		
        format: '<b>{point.name}</b><br>{point.y}',
        style: {
          color: 'black'
        }
      },  showInLegend: true,
    },
	
	
	/*legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                width: 200,
                verticalAlign: 'middle',
                useHTML: true,
                labelFormatter: function() {
                    return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
				}
            },
	*/
	
	// for url assign 
	series: {
		 
                cursor: 'pointer',
                
            }
  },
  

 series: [{
    name: 'Visitors',
    data: [{
      name: 'Mobile',
      y: y.returnvisitorbydev.mobile,
      sliced: true,
      selected: true,
	 // url:'rich-media.html',
	 

    }, {
      name: 'Desktop',
      y: y.returnvisitorbydev.desktop,
	// url:'rich-media.html',
	  
    },
	
	 {
      name: 'Tablet',
      y: y.returnvisitorbydev.tablet,
	 	 // url:'rich-media.html',
	  
    },
	
	//  {
  //     name: 'TV',
  //     y: 0,
	//  	  //url:'rich-media.html',
	  
  //   }
     
     ]
  }]
});
   this.donutchart4 = donutchart4;
   
   let donutchart5 = new Chart({	
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    credits: {
        enabled: false
    },
    exporting:{enabled: false},
    
    colors: ['#00689d', '#ff6600', '#00a698', '#8fc321', '#ff6600', '#e91d63', '#4f5d68', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],
  
  title: {
    text: '',
      style:{
      fontSize:16,
      fontWeight:'bold',
      },
        },
    tooltip: {
       valueSuffix: '%',
              enabled: true,
       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    
      plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: '60%',
                  innerSize: '50%',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br>{point.y}',
          style: {
            color:'black'
          }
        },  showInLegend: true,
      },
    
    
    /*legend: {
                  enabled: true,
                  layout: 'vertical',
                  align: 'right',
                  width: 200,
                  verticalAlign: 'middle',
                  useHTML: true,
                  labelFormatter: function() {
                      return '<div style="text-align: left; width:130px;">' + this.name + '</div><div style="width:40px; text-align:right;">' + this.y + '%</div>';
          }
              },
    */
    
    // for url assign 
    series: {
       
                  cursor: 'pointer',
                  
              }
    },
    
  
   series: [{
      name: 'Visitors',
      data: [{
        name: 'Mobile',
        y: x.returnvisitorbydev.mobile,
        sliced: true,
        selected: true,
    // url:'rich-media.html',
  
      }, {
        name: 'Desktop',
        y: x.returnvisitorbydev.desktop,
      // url:'rich-media.html',
         },
       
        {
        name: 'Tablet',
        y: x.returnvisitorbydev.tablet,
    // url:'rich-media.html',
      },
    // {
    //     name: 'TV',
    //     y: 0,
    //  //url:'rich-media.html',
    //   }
       
       ]
    }]
  });
     this.donutchart5 = donutchart5;
}



  constructor(private _DonutfirstService:DonutfirstService, private _DonutrtrnbydevService:DonutrtrnbydevService) { }

  ngOnInit() {
    this._DonutfirstService.getdonutchart(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.donutchatdata = data['donut1_chart_data'];this.donutchatdata1 = data['donut2_chart_data'];this.getdonutchart(this.donutchatdata,this.donutchatdata1);this.tabulardata = data['tabulardata'];this.topdata = data['topbardata'];this.tabulardata1 = data['tabulardata1'];this.topdata1 = data['topbardata1'];this.topdate = data['daterange'];},
    error => this.errorMsg = error);

    this._DonutrtrnbydevService.getdonutchartrtrndev(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.donutchatdatadev = data['donut1_chart_data'];this.donutchatdata1dev = data['donut2_chart_data'];this.donutdatadev = data['donutdata1'];this.donutdata1dev = data['donutdata2'];this.getdonutchart1(this.donutchatdatadev,this.donutchatdata1dev);this.tabulardatadev = data['tabulardata'];this.topdatadev = data['topbardata'];this.tabulardata1dev = data['tabulardata1'];this.topdata1dev = data['topbardata1'];this.topdatedev = data['daterange'];},
    error => this.errorMsg = error);

    this._DonutrtrnbydevService.getdonutchartnewdev(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.donutchatdatadevnew = data['donut1_chart_data'];this.donutchatdata1devnew = data['donut2_chart_data'];this.donutdatadevnew = data['donutdata1'];this.donutdata1devnew = data['donutdata2'];this.getdonutchart2(this.donutchatdatadevnew,this.donutchatdata1devnew);this.tabulardatadevnew = data['tabulardata'];this.topdatadevnew = data['topbardata'];this.tabulardata1devnew = data['tabulardata1'];this.topdata1devnew = data['topbardata1'];this.topdatedevnew = data['daterange'];},
    error => this.errorMsg = error);
  }

}
