import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListinvoicedataService } from './listinvoicedata.service';

@Component({
  selector: 'app-list-invoice',
  // templateUrl: './list-invoice.component_test.html',
  templateUrl: './list-invoice.component.html',
  styleUrls: ['./list-invoice.component.css']
})
export class ListInvoiceComponent implements OnInit {

  public errorMsg;
  public invoicelist = [];
  public showbreakup;
  public todayDate;
  public netwrktbl;

  constructor(private _listinvoicedataService:ListinvoicedataService,private _router: Router) { }

  ngOnInit() {
    if(localStorage.getItem('usertype')=="2"){
      this.showbreakup = true;
      this.netwrktbl = true;
    }else{
      this.showbreakup = false;
      this.netwrktbl = false;
    }
    this._listinvoicedataService.getInvoicedata(localStorage.getItem('uniq_id'),localStorage.getItem('acc_mgr_id'),localStorage.getItem('acc_name'),localStorage.getItem("access_token"),localStorage.getItem("useremail"))
    .subscribe(data => {
      this.invoicelist = data;
      var today = new Date();
      today.setDate(today.getDate()-40);
      var monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
      this.todayDate = monthNames[today.getMonth()]+" "+today.getFullYear();
      // console.log("today Date => "+monthNames[today.getMonth()]+" "+today.getFullYear());
      // console.log("today Date 111=> "+today); 
      console.log(data['Message']);
      if(data['Message']=='Invalid Token!'){
        alert("Sorry! Your token has expired. Please login to safe account to access invoice.");
        this._router.navigate(['/login']);
      }
    },
      error => this.errorMsg = error);
  }

}
