import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { topgraph } from './adxtopgrphcontent';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BarcharttotalService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_pro/header_bidder_with_direct_deal.php";
  
  constructor(private http:HttpClient) { }

  getTopgraph(daterange,acc_name,uniq_id,new_adx_name): Observable<topgraph[]>{
    return this.http.get<topgraph[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}