import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { device } from './gam-device';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamReportingDeviceService {  
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_report_device.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_report_device.php";
    }
  }

  getDevice(daterange,uniqid,child_net_code): Observable<device[]>{
    return this.http.get<device[]>(this._url+ "?"+daterange+"&uniqid="+uniqid+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}