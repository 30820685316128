import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { linechart } from './linechart';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/summary.php";

  constructor(private http:HttpClient) { }

  getSummary(anlyticid): Observable<linechart[]>{
    return this.http.get<linechart[]>(this._url+"?analyid="+anlyticid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
