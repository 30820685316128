import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GamAppOverviewComponent } from './gam-app-overview.component';



@Injectable()
export class GamAppOverviewService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/app_gam/gam_app_overview.php";

  constructor(private http:HttpClient) { }

  getAdxappoverview(daterange,child_net_code): Observable<GamAppOverviewComponent[]>{
    return this.http.get<GamAppOverviewComponent[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}