import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { topgraph } from './adxtopgrphcontent';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopbarGraphService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads/barchart_cyberads.php";

  constructor(private http:HttpClient) { }

  getbargraph(daterange,acc_name,new_adx_name,uniq_id): Observable<topgraph[]>{
    return this.http.get<topgraph[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&new_acc_name="+new_adx_name+"&uniq_id="+uniq_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}