import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { site } from './adx-site';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxSiteService {  

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_display/cyberads_display_sites.php";

  constructor(private http:HttpClient) { }

  getSite(daterange,acc_name,uniqid,new_adx_name): Observable<site[]>{
    return this.http.get<site[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniqid="+uniqid+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}