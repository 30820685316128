import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { landing } from './landing';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RtnbyarticelService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/rtnbyarticle.php";

  constructor(private http:HttpClient) { }

  getdonutchart(anlyticid): Observable<landing[]>{
    return this.http.get<landing[]>(this._url+"?analyid="+anlyticid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
