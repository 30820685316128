import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { site } from './gam-site';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamReportingSiteService {  

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_report_sites.php";

  constructor(private http:HttpClient) { }

  getSite(daterange,uniqid,child_net_code): Observable<site[]>{
    return this.http.get<site[]>(this._url+ "?"+daterange+"&uniqid="+uniqid+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}