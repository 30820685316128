import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { adtype } from './adx-app-adtype';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxAppAdtypeService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_app/cyberads_app_adtype.php";

  constructor(private http:HttpClient) { }

  getAppAdtypes(daterange,acc_name,demo,uniq_id): Observable<adtype[]>{
    return this.http.get<adtype[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&isdemo="+demo+"&uniq_id="+uniq_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}