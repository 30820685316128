import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AdxAdtypeService } from './adx-adtypeService';
declare var $: any;
import * as moment from 'moment';
declare var swal: any;
@Component({
  selector: 'adx-for-content-adtypes',
  templateUrl: './adx-content-ad-types.component.html',
  styleUrls: ['./adx-content-ad-types.component.css']
})
export class AdxContentAdTypesComponent implements OnInit {
	piechart;
  public tabledata = [];
  public innertabledata = [];
  public imgtabledata = [];
  public txttabledata = [];
  public vidtabledata = [];
  public animgtabledata = [];
  public unmtabledata = [];
  public lvl1data1 = [];
  public lvl1data2 = [];
  public lvl1data3 = [];
  public lvl1data4 = [];
  public lvl1data5 = [];
  public lvl1data6 = [];
  public graphdata = [];
  public daterange = [];
  public sumupdata = [];
  public graphdatelvl3 = [];
  public imginnertabledata = [];
  public unminnertabledata = [];
  public aniinnertabledata = [];
  public vidinnertabledata = [];
  public txtinnertabledata = [];
  public tabledatalvl1 = [];
  public sumlvl1data = [];
  public imgtabledatalvl1 = [];
  public txttabledatalvl1 = [];
  public vidtabledatalvl1 = [];
  public animgtabledatalvl1 = [];
  public unmtabledatalvl1 = [];
  public innertabledatalvl1 = [];
  public imginnertabledatalvl1 = [];
  public vidinnertabledatalvl1 = [];
  public aniinnertabledatalvl1 = [];
  public unminnertabledatalvl1 = [];
  public txtinnertabledatalvl1 = [];
  public errorMsg;
  public show:boolean = false;
  public showimg:boolean = false;
  public showtxt:boolean = false;
  public showvid:boolean = false;
  public showanimg:boolean = false;
  public showunm:boolean = false;
  public classname :any = 'fa fa-plus';
  public classnameimg :any = 'fa fa-plus';
  public classnamevid :any = 'fa fa-plus';
  public classnametxt :any = 'fa fa-plus';
  public classnameunm :any = 'fa fa-plus';
  public classnameanimg :any = 'fa fa-plus';
  public loading: boolean = true;
  public showlvl:boolean = false;
  public showimglvl1:boolean = false;
  public showtxtlvl1:boolean = false;
  public showvidlvl1:boolean = false;
  public showanimglvl1:boolean = false;
  public showunmlvl1:boolean = false;
  public classnamelvl1 :any = 'fa fa-plus show-span';
  public classnameimglvl1 :any = 'fa fa-plus show-span';
  public classnamevidlvl1 :any = 'fa fa-plus show-span';
  public classnametxtlvl1 :any = 'fa fa-plus show-span';
  public classnameunmlvl1 :any = 'fa fa-plus show-span';
  public classnameanimglvl1 :any = 'fa fa-plus show-span';

  getpiechart(x,y,z)
  {
  this.piechart = new Chart({
    chart: {
      events: {  
        drilldown: function(e) {
           console.log(e.seriesOptions.name);
           $("table").hide();
           //$('#'+e.seriesOptions.name).not("#"+e.seriesOptions.name).hide();
           $("#"+e.seriesOptions.name).show();
        //  if(e.seriesOptions.name=='Richmedia')
        //  {
        //   $("#Richmedia").show();
        //   $("#maintable").hide();
        //  }
        //  if(e.seriesOptions.name=='Image')
        //  {
        //   $("#Image").show();
        //   $("#maintable").hide();
        //  }
        //  if(e.seriesOptions.name=='Animatedimage')
        //  {
        //   $("#Animatedimage").show();
        //   $("#maintable").hide();
        //  }
        //  if(e.seriesOptions.name=='(Unmatchedadrequests)')
        //  {
        //   $("#(Unmatchedadrequests)").show();
        //   $("#maintable").hide();
        //  }
        //  if(e.seriesOptions.name=='Video')
        //  {
        //   $("#Video").show();
        //   $("#maintable").hide();
        //  }
        //  if(e.seriesOptions.name=='Text')
        //  {
        //   $("#Text").show();
        //   $("#maintable").hide();
        //  }
        //  else{
        //   $("#"+e.seriesOptions.name).show();
        //   // $("#maintable").hide();
        //   // $("#Video").hide();
        //   // $("#(Unmatchedadrequests)").hide();
        //   // $("#Text").hide();
        //   // $("#Richmedia").hide();
        //   // $("#Image").hide();
        //   // $("#Animatedimage").hide();
        //  }
        },
        drillup: function(e) {
          console.log(this.series[0].name);
          if(this.series[0].name=='Richmedia')
          {
           $("#maintable").show();
           $("#Richmedia").hide();
          }
          if(this.series[0].name=='Image')
          {
           $("#maintable").show();
           $("#Image").hide();
          }
          if(this.series[0].name=='Animatedimage')
          {
           $("#maintable").show();
           $("#Animatedimage").hide();
          }
          if(this.series[0].name=='Unmatchedadrequests')
          {
           $("#maintable").show();
           $("#Unmatchedadrequests").hide();
          }
          if(this.series[0].name=='Video')
          {
           $("#maintable").show();
           $("#Video").hide();
          }
          if(this.series[0].name=='Text')
          {
           $("#maintable").show();
           $("#Text").hide();
          }
          else
          {
            $("#"+this.series[0].name).hide();
            let str1=this.series[0].name;
            if((str1.indexOf("Richmedia") != -1) && (str1 !='Richmedia')) 
            {
              $("#Richmedia").show();
            }
            if((str1.indexOf("Image") != -1) && (str1 !='Image')) 
            {
              $("#Image").show();
            }
            if((str1.indexOf("Animatedimage") != -1) && (str1 !='Animatedimage')) 
            {
              $("#Animatedimage").show();
            }
            if((str1.indexOf("Unmatchedadrequests") != -1) && (str1 !='Unmatchedadrequests')) 
            {
              $("#Unmatchedadrequests").show();
            }
            if((str1.indexOf("Video") != -1) && (str1 !='Video')) 
            {
              $("#Video").show();
            }
            if((str1.indexOf("Text") != -1) && (str1 !='Text')) 
            {
              $("#Text").show();
            }
          }
         }
    },
   plotBackgroundColor: null,
   plotBorderWidth: null,
   plotShadow: false,
   type: 'pie'
 },
 
 title: {
   text: 'CyberAds Network For Display - Adtypes'
 },
 xAxis: {
  categories: z.dates
    },
  yAxis: {
    showEmpty: false,
        title: {
            text: 'Estimated Revenue ($)'
        },
        labels: {
            enabled: true
        }
},
legend: {
  enabled: true,
},
 credits: {
  enabled: false
},
 exporting: { enabled: false },
 colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
 '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

 tooltip: {
   pointFormat: '{series.name}: <b>${point.y}</b>'
 },
 plotOptions: {
   pie: {
     allowPointSelect: true,
     cursor: 'pointer',
     dataLabels: {
       enabled: true,
       format: '<b>{point.name}</b>: ${point.y}',
       
     }, showInLegend: true,
   }
 },
 
 series: [{
   name: 'AdTypes Overview',
  
     data: x
 }],
 drilldown: {
drillUpButton: {
  position: {
      x: 0,
      y: -35,
  }
},
series: y
}


   }); 

   function init_daterangepicker() {
    if ("undefined" != typeof $.fn.daterangepicker) {
      console.log("init_daterangepicker");
      var a = function(a, b, c) {
          console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
        },
        b = {
          startDate: moment().subtract(7, "days"),
          endDate: moment(),
          // minDate: moment().subtract(3, "month"),
          minDate: new Date('2019-08-01'),
          maxDate: moment(),
          dateLimit: {
            days: 90
          },
          showDropdowns: !0,
          showWeekNumbers: !0,
          timePicker: !1,
          timePickerIncrement: 1,
          timePicker12Hour: !0,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment()],
            "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
          },
          opens: "left",
          buttonClasses: ["btn bg-pink"],
          applyClass: "btn-small btn-primary",
          cancelClass: "btn-small",
          format: "MM/DD/YYYY",
          separator: " to ",
          locale: {
            applyLabel: "Submit",
            cancelLabel: "Clear",
            fromLabel: "From",
            toLabel: "To",
            customRangeLabel: "Custom",
            daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            firstDay: 1
          }
        };
      $(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
        console.log("show event fired")
      }), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
        console.log("hide event fired")
      }), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
                    
  let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
  $("#dateinput").val(strtdate);
  let startdate=b.startDate.format("MMMM D, YYYY");
    let enddate=b.endDate.format("MMMM D, YYYY");
    localStorage.setItem('strtdate', strtdate);
   localStorage.setItem('startdate', enddate);
		localStorage.setItem('enddate', startdate);
    window.location.reload();
  

        console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
      }), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
        console.log("cancel event fired")
      }), $("#options1").click(function() {
        $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
      }), $("#options2").click(function() {
        $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
      }), $("#destroy").click(function() {
        $(".custom-daterangepicker").data("daterangepicker").remove()
      })
    }
    
  }

  function init_daterangepicker_right() {
    if ("undefined" != typeof $.fn.daterangepicker) {
      console.log("init_daterangepicker_right");
      var a = function(a, b, c) {
          console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
        },
        b = {
          startDate: moment().subtract(6, "days"),
          endDate: moment(),
          // minDate: moment().subtract(3, "month"),
          minDate: new Date('2019-08-01'),
          maxDate: moment(),
          dateLimit: {
            days: 90
          },
          showDropdowns: !0,
          showWeekNumbers: !0,
          timePicker: !1,
          timePickerIncrement: 1,
          timePicker12Hour: !0,
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment()],
            "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
          },
          opens: "right",
          buttonClasses: ["btn btn-default"],
          applyClass: "btn-small btn-primary",
          cancelClass: "btn-small",
          format: "MM/DD/YYYY",
          separator: " to ",
          locale: {
            applyLabel: "Submit",
            cancelLabel: "Clear",
            fromLabel: "From",
            toLabel: "To",
            customRangeLabel: "Custom",
            daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            firstDay: 1
          }
        };
      $("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
        console.log("show event fired")
      }), $("#reportrange_right").on("hide.daterangepicker", function() {
        console.log("hide event fired")
      }), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
        console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
      }), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
        console.log("cancel event fired")
      }), $("#options1").click(function() {
        $("#reportrange_right").data("daterangepicker").setOptions(b, a)
      }), $("#options2").click(function() {
        $("#reportrange_right").data("daterangepicker").setOptions(b, a)
      }), $("#destroy").click(function() {
        $("#reportrange_right").data("daterangepicker").remove()
      })
    }
  }

  function init_daterangepicker_single_call() {
    "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
      singleDatePicker: !0,
      singleClasses: "picker_1"
    }, function(a, b, c) {
      console.log(a.toISOString(), b.toISOString(), c)
    }), $("#single_cal2").daterangepicker({
      singleDatePicker: !0,
      singleClasses: "picker_2"
    }, function(a, b, c) {
      console.log(a.toISOString(), b.toISOString(), c)
    }), $("#single_cal3").daterangepicker({
      singleDatePicker: !0,
      singleClasses: "picker_3"
    }, function(a, b, c) {
      console.log(a.toISOString(), b.toISOString(), c)
    }), $("#single_cal4").daterangepicker({
      singleDatePicker: !0,
      singleClasses: "picker_4"
    }, function(a, b, c) {
      console.log(a.toISOString(), b.toISOString(), c)
    }))
  }

  function init_daterangepicker_reservation() {
    "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
      console.log(a.toISOString(), b.toISOString(), c)
    }), $("#reservation-time").daterangepicker({
      timePicker: !0,
      timePickerIncrement: 30,
      locale: {
        format: "MM/DD/YYYY h:mm A"
      }
    }))
  }

  function init_SmartWizard() {
    "undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
      transitionEffect: "slide"
    }), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
  }




  var originalLeave = $.fn.popover.Constructor.prototype.leave;
  $.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
    init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
  }); 

  }
	constructor(private _adxadtypeService:AdxAdtypeService) { }

	ngOnInit() { 

		this._adxadtypeService.getAdtypes(localStorage.getItem('strtdate'),localStorage.getItem('acc_mgr_id'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'))
		.subscribe(data => {
      this.loading = false;
      this.graphdata = data['level1'];
      this.lvl1data1 = data['finaldata'];
      if(data['finaldata']==null){
				swal.fire({
					title: "Empty Data",
					text: "No Data For Selected Date Range!",
					icon: "warning",
					button: "Ok",
				});
			}
      this.sumlvl1data = data['Sum_table_data'];
      this.tabledata = data["adtype_table_data"]["Richmedia"];
      this.imgtabledata = data["adtype_table_data"]["Image"];
      this.txttabledata = data["adtype_table_data"]["Text"];
      this.vidtabledata = data["adtype_table_data"]["Video"];
      this.animgtabledata = data["adtype_table_data"]["Animatedimage"];
      this.unmtabledata = data["adtype_table_data"]["Unmatchedadrequests"];
      this.innertabledata = data["adtype_innertable_data"]["Richmedia"];
      this.imginnertabledata = data["adtype_innertable_data"]["Image"];
      this.vidinnertabledata = data["adtype_innertable_data"]["Video"];
      this.aniinnertabledata = data["adtype_innertable_data"]["Animatedimage"];
      this.unminnertabledata = data["adtype_innertable_data"]["Unmatchedadrequests"];
      this.txtinnertabledata = data["adtype_innertable_data"]["Text"];
      this.tabledatalvl1 = data["adtype_table_data_lvl1"]["Richmedia"];
      this.imgtabledatalvl1 = data["adtype_table_data_lvl1"]["Image"];
      this.txttabledatalvl1 = data["adtype_table_data_lvl1"]["Text"];
      this.vidtabledatalvl1 = data["adtype_table_data_lvl1"]["Video"];
      this.animgtabledatalvl1 = data["adtype_table_data_lvl1"]["Animatedimage"];
      this.unmtabledatalvl1 = data["adtype_table_data_lvl1"]["Unmatchedadrequests"];
      this.innertabledatalvl1 = data["adtype_innertable_data_lvl1"]["Richmedia"];
      this.imginnertabledatalvl1 = data["adtype_innertable_data"]["Image"];
      this.vidinnertabledatalvl1 = data["adtype_innertable_data"]["Video"];
      this.aniinnertabledatalvl1 = data["adtype_innertable_data"]["Animatedimage"];
      this.unminnertabledatalvl1 = data["adtype_innertable_data"]["Unmatchedadrequests"];
      this.txtinnertabledatalvl1 = data["adtype_innertable_data"]["Text"];
      this.daterange = data["adtype_date_range"];
      this.graphdatelvl3 = data["level3_dates"];
	  this.getpiechart(this.graphdata,this.lvl1data1,this.graphdatelvl3);
	  },
		error => this.errorMsg = error);
		
  }

  downloadadxadtype() { 
    if(localStorage.getItem('usertype')=='2'){
			document.location.href='https://angular.cybermediaservices.net/assets/web_api/download-adx-adtype_networks.php?'+localStorage.getItem("strtdate")+"&acc_mgr_id="+localStorage.getItem("acc_mgr_id")+'&acc_name='+localStorage.getItem("acc_name")+'&is_demo='+localStorage.getItem("isdemo")+'&new_acc_name='+localStorage.getItem("old_acc_name");
		}else{
			document.location.href='https://angular.cybermediaservices.net/assets/web_api/download-adx-adtype.php?'+localStorage.getItem("strtdate")+'&acc_name='+localStorage.getItem("acc_name")+'&is_demo='+localStorage.getItem("isdemo")+'&new_acc_name='+localStorage.getItem("old_acc_name");
		}
    

  }
  
  toggle() {
    this.show = !this.show;
    if(this.show)  
      this.classname = "fa fa-minus";
    else
      this.classname = "fa fa-plus";
  }
  toggleimg() {
    this.showimg = !this.showimg;
    if(this.showimg)  
      this.classnameimg = "fa fa-minus";
    else
      this.classnameimg = "fa fa-plus";
  }
  togglevid() {
    this.showvid = !this.showvid;
    if(this.showvid)  
      this.classnamevid = "fa fa-minus";
    else
      this.classnamevid = "fa fa-plus";
  }
  toggletxt() {
    this.showtxt = !this.showtxt;
    if(this.showtxt)  
      this.classnametxt = "fa fa-minus";
    else
      this.classnametxt = "fa fa-plus";
  }
  toggleanimg() {
    this.showanimg = !this.showanimg;
    if(this.showanimg)  
      this.classnameanimg = "fa fa-minus";
    else
      this.classnameanimg = "fa fa-plus";
  }
  toggleunm() {
    this.showunm = !this.showunm;
    if(this.showunm)  
      this.classnameunm = "fa fa-minus";
    else
      this.classnameunm = "fa fa-plus";
  }

  togglelvl(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleimglvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  togglevidlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggletxtlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleanimglvl1(a) {
   $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleunmlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }


}