import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-DFP-responsive-and-refresh',
  templateUrl: './DFP-responsive-and-refresh.component.html',
  styleUrls: ['./tools.component.css']
})
export class DFPResponsiveAndRefreshComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
