import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { adver } from './adver';


@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/advertiser_top_graph.php";

  constructor(private http:HttpClient) { }

  getadvertiserdata(anlyticid,uniqid): Observable<adver[]>{
    return this.http.get<adver[]>(this._url+"?analyid="+anlyticid+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
