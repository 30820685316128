import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { NetworkEarningService } from './network-earning.service';
declare var $: any;
import * as moment from 'moment';
declare var swal: any;

@Component({
  selector: 'app-network-earning',
  templateUrl: './network-earning.component.html',
  styleUrls: ['./network-earning.component.css']
})
export class NetworkEarningComponent implements OnInit {

lineChart;
  public tabledata = [];
  public month_details = [];
  public topheaddata = [];
  public graphdata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  public loading: boolean = true;  
    
	getgraphdata(y)
    {
		this.lineChart = new Chart({
    		chart: {
		        type: 'line'
		    },
		    title: {
		        text: 'CyberAds Network For Display - Overview'
		    },
		    xAxis: {
	            categories:y.date,
	        },
		    yAxis: {
				showEmpty: false,
	            title: {
	                text: 'Estimated Revenue ($)'
	            },
	            labels: {
	                enabled: true
	            }
			},
			legend: {
				enabled: false,
			},
	        tooltip: {
	            headerFormat: '',
	            pointFormat: '<span style="color:{point.color}"><b>${point.y:.2f}</b> {point.name}</span>'
	        },
		    credits: {
		        enabled: false
		    },
		    exporting: { enabled: false },
		    plotOptions: {
	            series: {
	                dataLabels: {
	                    enabled: false,
	                    format: '<b>${point.y:.2f}</b> {point.name}',
	                    
	                }
	            }
	        },
	        colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
	            '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
	        ],
		    series: [
		        {
		            data: y.revenue,
		        }
		    ]
		});
		function init_daterangepicker() {
                setTimeout(() => {
			if ("undefined" != typeof $.fn.daterangepicker) {
				console.log("init_daterangepicker");
				var a = function(a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
					b = {
						startDate: moment().subtract(7, "days"),
						endDate: moment(),
						// minDate: moment().subtract(3, "month"),
						minDate: new Date('2019-08-01'),
						maxDate: moment(),
						dateLimit: {
							days: 90
						},
						showDropdowns: !0,
						showWeekNumbers: !0,
						timePicker: !1,
						timePickerIncrement: 1,
						timePicker12Hour: !0,
						ranges: {
							Today: [moment(), moment()],
							Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							"Last 7 Days": [moment().subtract(6, "days"), moment()],
							"Last 30 Days": [moment().subtract(29, "days"), moment()],
							"This Month": [moment().startOf("month"), moment()],
							"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
						},
						opens: "left",
						buttonClasses: ["btn bg-pink"],
						applyClass: "btn-small btn-primary",
						cancelClass: "btn-small",
						format: "MM/DD/YYYY",
						separator: " to ",
						locale: {
							applyLabel: "Submit",
							cancelLabel: "Clear",
							fromLabel: "From",
							toLabel: "To",
							customRangeLabel: "Custom",
							daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							firstDay: 1
						}
					};
					
				$(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate'));
				$(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
					console.log("show event fired")
				}), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
					console.log("hide event fired")
				}), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
											
		let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
		$("#dateinput").val(strtdate);
		let startdate=b.startDate.format("MMMM D, YYYY");
		let enddate=b.endDate.format("MMMM D, YYYY");
		localStorage.setItem('strtdate', strtdate);
		localStorage.setItem('startdate', enddate);
		localStorage.setItem('enddate', startdate);
		window.location.reload();
		
	
					console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				}), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
					console.log("cancel event fired")
				}), $("#options1").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				}), $("#options2").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				}), $("#destroy").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").remove()
				})
			}
		 }, 2000);	
		}
	
		function init_daterangepicker_right() {
                 setTimeout(() => {
			if ("undefined" != typeof $.fn.daterangepicker) {
				console.log("init_daterangepicker_right");
				var a = function(a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
					b = {
						startDate: moment().subtract(6, "days"),
						endDate: moment(),
						// minDate: moment().subtract(3, "month"),
						minDate: new Date('2019-08-01'),
						maxDate: moment(),
						dateLimit: {
							days: 90
						},
						showDropdowns: !0,
						showWeekNumbers: !0,
						timePicker: !1,
						timePickerIncrement: 1,
						timePicker12Hour: !0,
						ranges: {
							Today: [moment(), moment()],
							Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							"Last 7 Days": [moment().subtract(6, "days"), moment()],
							"Last 30 Days": [moment().subtract(29, "days"), moment()],
							"This Month": [moment().startOf("month"), moment()],
							"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
						},
						opens: "right",
						buttonClasses: ["btn btn-default"],
						applyClass: "btn-small btn-primary",
						cancelClass: "btn-small",
						format: "MM/DD/YYYY",
						separator: " to ",
						locale: {
							applyLabel: "Submit",
							cancelLabel: "Clear",
							fromLabel: "From",
							toLabel: "To",
							customRangeLabel: "Custom",
							daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							firstDay: 1
						}
					};
				$("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
					console.log("show event fired")
				}), $("#reportrange_right").on("hide.daterangepicker", function() {
					console.log("hide event fired")
				}), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
					console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				}), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
					console.log("cancel event fired")
				}), $("#options1").click(function() {
					$("#reportrange_right").data("daterangepicker").setOptions(b, a)
				}), $("#options2").click(function() {
					$("#reportrange_right").data("daterangepicker").setOptions(b, a)
				}), $("#destroy").click(function() {
					$("#reportrange_right").data("daterangepicker").remove()
				})
			}
                 }, 2000);
		}
	
		function init_daterangepicker_single_call() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_1"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal2").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_2"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal3").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_3"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal4").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_4"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}))
		}
	
		function init_daterangepicker_reservation() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#reservation-time").daterangepicker({
				timePicker: !0,
				timePickerIncrement: 30,
				locale: {
					format: "MM/DD/YYYY h:mm A"
				}
			}))
		}
	
		function init_SmartWizard() {
			"undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
				transitionEffect: "slide"
			}), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
		}
	
	
	
	
		var originalLeave = $.fn.popover.Constructor.prototype.leave;
		$.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
			init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
		});  

}

  constructor(private _networkearningService:NetworkEarningService) { }

  ngOnInit() {

this._networkearningService.getNetworkearning(localStorage.getItem('strtdate'),localStorage.getItem('acc_mgr_id'),localStorage.getItem('acc_name'),localStorage.getItem('old_acc_name'),localStorage.getItem('uniq_id'))
		.subscribe(data => {
			this.loading = false;
			if(data['content_table_data']==null){
				swal.fire({
					title: "Empty Data",
					text: "No Data For Selected Date Range!",
					icon: "warning",
					button: "Ok",
				});
			}
			this.tabledata = data["content_table_data"];
			this.topheaddata = data["top_head_data"];
			this.graphdata = data["content_graph_date"];
			this.month_details = data['month_details'];
			this.daterange = data["content_date_range"];
			this.sumupdata = data["sum_table_data"];
	  this.getgraphdata(this.graphdata); 
	  },
		error => this.errorMsg = error);
	}
	downloadadxcontent() { 
		if(localStorage.getItem('usertype')=='2'){
			document.location.href='https://angular.cybermediaservices.net/assets/web_api/download_networks_earnings_content.php?'+localStorage.getItem("strtdate")+"&acc_mgr_id="+localStorage.getItem("acc_mgr_id")+'&acc_name='+localStorage.getItem("acc_name")+'&new_acc_name='+localStorage.getItem("old_acc_name");
		}else{
			document.location.href='https://angular.cybermediaservices.net/assets/web_api/download_network_content.php?'+localStorage.getItem("strtdate")+'&acc_name='+localStorage.getItem("acc_name")+'&new_acc_name='+localStorage.getItem("old_acc_name");
		}
		
	}
  }

