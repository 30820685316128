import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { heatmap } from './adxheatmapcontent';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeatmaphourlytotalService {
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/heatmap_hourly.php";

  constructor(private http:HttpClient) { }

  getheatmaphourlydata(x,daterange,anlyticid,demo): Observable<heatmap[]>{
    return this.http.get<heatmap[]>(this._url+'?id='+x+'&'+daterange+"&analyid="+anlyticid+"&is_demo="+demo)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}