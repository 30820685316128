import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-adstxt',
  templateUrl: './adstxt.component.html',
  styleUrls: ['./adstxt.component.css']
})
export class AdstxtComponent implements OnInit {
  display='none'; //default Variable
  public adsenseid: boolean = true;
  public adxid: boolean = true;
  public adsid:string;
  public notifydata = [];
  public errorMsg;
  public mySubscription: any;
  // constructor(private router: Router) {
  //   this.router.routeReuseStrategy.shouldReuseRoute = function () {

  //   return false;
  
  // };
  
  // this.mySubscription = this.router.events.subscribe((event) => {
  
  //   if (event instanceof NavigationEnd) {
  
  //     // Trick the Router into believing it's last link wasn't previously loaded
  
  //     this.router.navigated = false;
  
  //   }
  
  // });}
  setheightpage()
  {
   $(document).ready(function(){
     var page_height= $(document).height();
     $(".page-content").css("height", page_height);
   });
  }
  ngOnInit() {
    

    if(localStorage.getItem('ads_id')!= '' && localStorage.getItem('ads_id')!='ca-')
    {
      this.adsenseid=true;
      this.adsid=localStorage.getItem('ads_id');
    }
    else{
      this.adsenseid=false;
    }
    if(localStorage.getItem('adx_id')!= '' && localStorage.getItem('adx_id')!= null)
    {
      this.adxid=true;
    }
    else{
      this.adxid=false;
    }
    console.log(this.adxid);
    console.log(this.adsenseid);
    this.setheightpage();
  }
  // ngOnDestroy() {

  //   if (this.mySubscription) {
  
  //     this.mySubscription.unsubscribe();
  
  //   }
  
  // }
  /* To copy Text from Textarea */
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    var btn = $("#copybtn");
    this.setTooltip(btn, 'Copied');
    this.hideTooltip(btn);
  }
  
  setTooltip(btn, message) {
    btn.tooltip('hide')
      .attr('data-original-title', message)
      .tooltip('show');
  }
  
  hideTooltip(btn) {
    setTimeout(function() {
      btn.tooltip('hide');
    }, 1000);
  }
}
