import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

interface myData {
  success: boolean,
  message: string
  pub_name: string
  adx_id: string
  ads_id: string
  anlytic_id: string
  uniq_id: string
  startdate: string
  enddate: string
  oldname: string
  isdemo: string
  acc_mgr_id: string
  usertype: string
  issubuser: string
  email:string
  childNetworkCode:string 
  new_MCMpub_name:string 
  access_token:string
  // strtdate: string
}

@Injectable()
export class AuthService {

  private loggedInStatus = false

  constructor(private http: HttpClient) { }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value
  }

  get isLoggedIn() {
    return this.loggedInStatus
  }

  getUserDetails(username, password) {
    return this.http.post<myData>('https://angular.cybermediaservices.net/assets/web_api/user.php', {
      username,
      password
    })
  }

  // getUserDetails(email: any, password: any) {
  //   return this.http.post<myData>('https://safedev.cybermediaservices.in/assets/api/admin/auth/signin.php', {
  //     email,
  //     password
  //   })
  // }

}
