import { Component, OnInit } from '@angular/core';
import { SaveUserService } from './save-user.service';
import { CheckUserServiceService } from './check-user-service.service';
import { DelUserService } from './del-user.service';
import { EditUserService } from './edit-user.service';
import { UserdetailServiceService } from './userdetail-service.service';
import { EditUserDetailService } from './edit-user-detail.service';
import {Router} from "@angular/router";
declare var $: any;

@Component({
  selector: 'app-sub-user-regestration',
  templateUrl: './sub-user-regestration.component.html',
  styleUrls: ['./sub-user-regestration.component.css','../alertBox.css']
})
export class SubUserRegestrationComponent implements OnInit {
  nrSelect;
  usercount;
  adduserbtn;
  show: boolean = false;
  editstatus: boolean = false;
  public deluserid;
  errshow:boolean=true;
  element: HTMLElement;
  chkuser: boolean = false;
  chkmail: boolean = false;
  admgr: boolean = false;
  adexec: boolean = false;
  authr: boolean = false;
  test:any=[];
  data:any[];
  public table_data = [];
  public errorMsg;
  public status;
  loading:any;
  model: any = {};
  editmodel: any = {};
  display='none'; //default Variable
  display1='none'; //default Variable
  display2='none'; //default Variable
  setheightpage()
  {
   $(document).ready(function(){
     var page_height='';
     $(".page-content").css("height", page_height);
   });
  }
  constructor(private api:SaveUserService,private checkuser:CheckUserServiceService,private _UserdetailService:UserdetailServiceService,private router: Router,private deluser:DelUserService,private edituser:EditUserService,private save_details_edituser:EditUserDetailService,) { }

  ngOnInit() {
    
    this.nrSelect = 'nSelect Role';
    this._UserdetailService.getuserdtl(localStorage.getItem('uniq_id'))
    .subscribe(data => {
      this.table_data = data['table_data'];
      this.usercount=data['usercount'];
      console.log(this.usercount);
      if(this.usercount < 5)
      {
        this.adduserbtn = 1;
      }
      else
      {
        this.adduserbtn = 0;
      }
      if(data['table_data']==null)
      {
        this.status = 0;
      }
      else
      {
        this.status = 1;
      }
      
      this.setheightpage();
      
    },
    error => this.errorMsg = error);

}
onBlurMethod(uname){
  this.checkuser.chkuser(uname).subscribe((response)=>{
  if(response)
  {
    this.chkuser=false;
  }
  else{
    this.element = document.getElementById('uname');
  //this.element.focus();
  this.model.uname='';
    this.chkuser=true;
  }
}) 
}
onBlurmail(mail){
  this.checkuser.chkmail(mail).subscribe((response)=>{
  if(response)
  {
    this.chkmail=false;
  }
  else{
    this.chkmail=true;
    this.element = document.getElementById('email');
    this.model.email='';
    //this.element.focus();
    
  }
}) 
}
onBluredit(){
  this.editstatus=true;
}
openModalDialog(){
  this.display='block'; //Set block css
  this.nrSelect = 'nSelect Role';
}
openModaleditrole(){
  this.display1='block'; //Set block css
}
openModaldelconfirmbox(uid){
  this.display2='block'; //Set block css
  this.deluserid=uid;
}

editdata(id)
{
  this.edituser.edituser(id).subscribe((response:any)=>{
    if(response){
      this.editmodel.username=response.username;
      this.editmodel.id=response.id;
      this.editmodel.editemail=response.email;
      this.editmodel.editfname=response.f_name;
      this.editmodel.editlname=response.l_name;
      this.editmodel.editmob=response.mob;
      if(response.user_type_id>0){ this.editmodel.editRole=response.user_type_id;}else{this.editmodel.editRole="";}
      this.openModaleditrole();

    }
    else{
      alert("User details not found.");
    }
  })
  
}

delconfirm()
{
  var deluserid=$("#confirmdeluid").text();
  this.deluser.deluser(deluserid).subscribe((delresp)=>{
    if(delresp){
      this.closeModaldelconfirmbox();
      this.ngOnInit();

    }
    else{
      this.closeModaldelconfirmbox();
      this.ngOnInit();
    }
  }) 
}

deldata(delid,delname)
{
  this.openModaldelconfirmbox(delid);
  $("#confirmdeluid").text(delid);
  $("#confirmdelunmae").text(delname);
}

closeModalDialog(){
this.display='none'; //set none css after close dialog.
this.errshow=false;
this.chkmail=false;
this.chkuser=false;
this.admgr=false;
this.adexec=false;
this.authr=false;
}
errtrue(){
  
  this.errshow=true;
}
dynamictext(roleid){
  this.editstatus=true;
  if(roleid==5){
    this.admgr=true;
    this.adexec=false;
    this.authr=false;
  }
  else if(roleid==6){
    this.adexec=true;
    this.admgr=false;
    this.authr=false;
  }
  else if(roleid==7){
    this.authr=true;
    this.admgr=false;
    this.adexec=false;
  }
  else
  {
    this.admgr=false;
    this.adexec=false;
    this.authr=false;
  }
}
closeModaleditrole(){
  this.display1='none'; //set none css after close dialog
  this.editstatus=false;
  this.errshow=false;
  this.chkmail=false;
this.chkuser=false;
this.admgr=false;
this.adexec=false;
this.authr=false;
  }
closeModaldelconfirmbox(){
  this.display2='none';
}
onClickSubmit(data) {
  this.errshow=false;
  const formData: FormData = new FormData();
  formData.append('data', JSON.stringify(data));
  this.api.userdata(formData,localStorage.getItem('uniq_id')).subscribe((response)=>{
    if(response){
      alert("Thanks! User successfully registered.");
      this.closeModalDialog();
      this.ngOnInit();
    }
    else{
      alert("Error Please fill again.");
      this.closeModalDialog();
      this.ngOnInit();
    }
   
  }) 
}
onClickUpdate(editdata) {
  this.errshow=false;
  const formData: FormData = new FormData();
  formData.append('data', JSON.stringify(editdata));
  new Response(formData).text().then(console.log);
  this.save_details_edituser.save_edituser(formData).subscribe((edit_save_detail_response)=>{
    this.errshow=false;
    console.log(edit_save_detail_response);
    if(edit_save_detail_response){
      alert("User details has been updated.");
      this.closeModaleditrole();
      // location.reload(true);
      this.ngOnInit();
    }
    else{
      alert("Error Please update again.");
      this.closeModaleditrole();
      // location.reload(true);
      this.ngOnInit();
    }
   
  }) 
}

}