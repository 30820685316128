import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
  })

export class LoginComponent implements OnInit {
    constructor(private Auth: AuthService, 
        private router: Router) {  }
        getdaterange()
        {
          var asiaTime = new Date().toLocaleString("en-US", {timeZone: "Pacific/Honolulu"});
          var today = new Date(asiaTime);
         var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
         var strtmnth = ("0" + (lastWeek.getMonth()+1)).slice(-2);
         var strtday  = ("0" + lastWeek.getDate()).slice(-2);
         var endmnth = ("0" + (today.getMonth()+1)).slice(-2);
         var endday  = ("0" + today.getDate()).slice(-2);
         var finalstrtdate= [ lastWeek.getFullYear(), strtmnth, strtday ].join("-");
         var finalenddate = [ today.getFullYear(), endmnth, endday ].join("-");
         let strtdate="strtdate="+finalstrtdate+"&enddate="+finalenddate;
         localStorage.setItem('strtdate', strtdate);
        }        

ngOnInit() {
  this.getdaterange();
  this.loadScript('../assets/js/jquery.min.js');
  this.loadScript('../assets/js/particles.js');
  this.loadScript('../assets/js/owl.carousel.js');
  this.loadScript('../assets/js/custom.js');
  $("#error_msg").hide();
  
}

public loadScript(url: string) {
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  body.appendChild(script);
}

loginUser(event) {
event.preventDefault()
const target = event.target
const username = target.querySelector('#username').value
const password = target.querySelector('#password').value

this.Auth.getUserDetails(username, password).subscribe(data => {
if(data.success) {
  localStorage.clear();
  localStorage.setItem('acc_name', data.pub_name);
  localStorage.setItem('adx_id', data.adx_id);
  localStorage.setItem('ads_id', data.ads_id);
  localStorage.setItem('anlytic_id', data.anlytic_id);
  localStorage.setItem('uniq_id', data.uniq_id);
  localStorage.setItem('website_name', 'all');
  localStorage.setItem('startdate', data.startdate);
  localStorage.setItem('enddate', data.enddate);
  localStorage.setItem('old_acc_name', data.oldname);
  localStorage.setItem('isdemo', data.isdemo);
  localStorage.setItem('acc_mgr_id', data.acc_mgr_id);
  localStorage.setItem('issubuser', data.issubuser);
  localStorage.setItem('usertype', data.usertype); 
  localStorage.setItem('accmgr', 'false');
  localStorage.setItem('useremail', data.email);
  localStorage.setItem('userName', username);
  localStorage.setItem('child_net_code', data.childNetworkCode); 
  localStorage.setItem('new_MCMpub_name', data.new_MCMpub_name); 
  localStorage.setItem('access_token', data.access_token); 
  this.getdaterange(); 
  console.log("sandeep new  Useremail =>"+data.email);

  if(data.usertype=='7'){
    this.router.navigate(['/insights']);
  }else{
    this.router.navigate(['/dashboard']);
  }
  if(data.access_token==null){
    this.router.navigate(['/login']);
  }
  // window.open('dashboard','_self');
  this.Auth.setLoggedIn(true);
} else {
  $("#error_msg").show();
  $("#error_msg").html(data.message);
  // window.alert(data.message);
}
})
}

}