import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AdxAppDeviceService } from './adx-app-deviceService';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-adx-app-device',
  templateUrl: './adx-app-device.component.html',
  styleUrls: ['./adx-app-device.component.css']
})
export class AdxAppDeviceComponent implements OnInit {

	piechart;
	public lvl2data = [];
  public lvl2data1 = [];
  public lvl2data2 = [];
  public lvl2data3 = [];
	public tabledata = [];
  public lvl1data1 = [];
  public graphdatelvl3 = [];
  public innertabledata = [];
  public highendtabledata = [];
  public highendinnertabledata = [];
  public tabtabledata = [];
  public tabinnertabledata = [];
  public contabledata = [];
  public coninnertabledata = [];
  public graphdata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  public show:boolean = false;
  public showhighend:boolean = false;
  public showtab:boolean = false;
  public showcon:boolean = false;
  public classnamelvl1 :any = 'fa fa-plus show-span';
  public classlvl1 :any = 'fa fa-plus show-span';
  public classnamevidlvl1 :any = 'fa fa-plus show-span';
  public classnametxtlvl1 :any = 'fa fa-plus show-span';
  public classnameunmlvl1 :any = 'fa fa-plus show-span';
  public classnameanimglvl1 :any = 'fa fa-plus show-span';
	public loading: boolean = true;
  getpiechart(x,y,z)
    {
		this.piechart = new Chart({
			chart: {
				events: {  
          drilldown: function(e) {
            console.log(e.seriesOptions.name);
             $("table").hide();
             $("."+e.seriesOptions.name).show();
          },
          drillup: function(e) {       
            if(this.series[0].name==this.series[0].name.split('by')[0])
            {
              $("table").hide();
              $("#maintable").show();
            }
            else
            {
              $("table").hide();
              $("."+this.series[0].name.split('by')[0]).show();
            }
             
           }
      },
	   plotBackgroundColor: null,
	   plotBorderWidth: null,
	   plotShadow: false,
	   type: 'pie'
   },
   title: {
	   text: 'CyberAds Network For App - Device'
   },
   xAxis: {
    categories: z.dates
      },
	 yAxis: {
    showEmpty: false,
		title: {
		
			text: 'Estimated Revenue ($)'
		},
		labels: {
			enabled: true
	}
		},
	 exporting: { enabled: false },
	 credits: {
		enabled: false
	},
   colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
   '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

   tooltip: {
	   pointFormat: '{series.name}: <b>${point.y}</b>'
   },
   plotOptions: {
	   pie: {
		   allowPointSelect: true,
		   cursor: 'pointer',
		   dataLabels: {
			   enabled: true,
			   format: '<b>{point.name}</b>: ${point.y}',
			   
		   }, showInLegend: true,
	   }
   },
   series: [
		{
			name: "Device",
			
			data: x,
		}
		],
		drilldown: {
      drillUpButton: {
        position: {
            x: 0,
            y: -35,
        }
      },
			series: y
			}
     }); 
     function init_daterangepicker() {
      if ("undefined" != typeof $.fn.daterangepicker) {
        console.log("init_daterangepicker");
        var a = function(a, b, c) {
            console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
          },
          b = {
            startDate: moment().subtract(7, "days"),
            endDate: moment(),
            // minDate: moment().subtract(3, "month"),
            minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            },
            opens: "left",
            buttonClasses: ["btn bg-pink"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
          $(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
						console.log("show event fired")
					}), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
						console.log("hide event fired")
					}), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
												
			let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
			$("#dateinput").val(strtdate);
			let startdate=b.startDate.format("MMMM D, YYYY");
			let enddate=b.endDate.format("MMMM D, YYYY");
			localStorage.setItem('strtdate', strtdate);
			localStorage.setItem('startdate', enddate);
			localStorage.setItem('enddate', startdate);
			window.location.reload();
    
  
          console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
          console.log("cancel event fired")
        }), $("#options1").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").remove()
        })
      }
      
    }
  
    function init_daterangepicker_right() {
      if ("undefined" != typeof $.fn.daterangepicker) {
        console.log("init_daterangepicker_right");
        var a = function(a, b, c) {
            console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
          },
          b = {
            startDate: moment().subtract(6, "days"),
            endDate: moment(),
            // minDate: moment().subtract(3, "month"),
            minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
            opens: "right",
            buttonClasses: ["btn btn-default"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
        $("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
          console.log("show event fired")
        }), $("#reportrange_right").on("hide.daterangepicker", function() {
          console.log("hide event fired")
        }), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
          console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
          console.log("cancel event fired")
        }), $("#options1").click(function() {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function() {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function() {
          $("#reportrange_right").data("daterangepicker").remove()
        })
      }
    }
  
    function init_daterangepicker_single_call() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_1"
      }, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal2").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_2"
      }, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal3").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_3"
      }, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal4").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_4"
      }, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }))
    }
  
    function init_daterangepicker_reservation() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }), $("#reservation-time").daterangepicker({
        timePicker: !0,
        timePickerIncrement: 30,
        locale: {
          format: "MM/DD/YYYY h:mm A"
        }
      }))
    }
  
    function init_SmartWizard() {
      "undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
        transitionEffect: "slide"
      }), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
    }
  
  
  
  
    var originalLeave = $.fn.popover.Constructor.prototype.leave;
    $.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
      init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
    }); 
    }
	constructor(private _adxappdeviceService:AdxAppDeviceService) { }

	ngOnInit() {

		this._adxappdeviceService.getappDevice(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('isdemo'),localStorage.getItem('uniq_id'))
		.subscribe(data => {this.loading = false;this.graphdata = data['level1'];this.lvl1data1 = data['finaldata'],this.tabledata = data["adtype_table_data"],
   this.lvl2data = data['adtype_table_data_lvl1']["0"],
    this.lvl2data1 = data['adtype_table_data_lvl1']["1"],
    this.lvl2data2 = data['adtype_table_data_lvl1']["2"],
    this.lvl2data3 = data['adtype_table_data_lvl1']["3"],
    this.daterange = data["adtype_date_range"],
    this.sumupdata = data['Sum_table_data'],
    this.graphdatelvl3 = data["level3_dates"],
	  this.getpiechart(this.graphdata,this.lvl1data1,this.graphdatelvl3);
	  },
		error => this.errorMsg = error);
		
  }
  
  downloadadxappdevice() { 

  document.location.href='https://angular.cybermediaservices.net/assets/web_api/download-adx-app-device.php?'+localStorage.getItem("strtdate")+'&acc_name='+localStorage.getItem("acc_name")+'&is_demo='+localStorage.getItem("isdemo");
  }

	toggle(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
	}
	togglelvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
}