import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { LinechartService } from './linechart.service';
import { DonutaudienceService } from './donutaudience.service';
import { SummaryService } from './summary.service';
import { LpHighbounceService } from './lp-highbounce.service';
declare var $: any;

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {
  chart: Chart;
 donutchart:Chart;
 barchart: Chart;
 trendChart: Chart;
 public linechartdata = [];
  public errorMsg;
  public daterange = [];
  public donutchartdata =[];
  public donutdata =[];
  public topdata =[];
  public seriesresult=[];
  public tabulardata_audience=[];
  public tabulardata_behaviour=[];
  public audience_session = [];
  public tabulardata_bouncerate =[];
  public trenddata = [];
  public tabulardata_lp_highbounce = [];
  public lp_highbounce_daterange = [];
  public loading: boolean = true;
  public pageloadtime_loading: boolean =true;
  setheightpage()
  {
   $(document).ready(function(){
     var page_height='';
     $(".page-content").css("height", page_height);
   });
  }

  getgraphdata(x)
     {
     let chart = new Chart({
    chart: {
        type: 'areaspline',
		marginBottom: 70, 
		
    },
    title: {
        text:'',
    },
	
    legend: {
         align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 10,
        floating: true,
        borderWidth: 0,
        backgroundColor:'#FFFFFF'
    },
    xAxis: {
        categories: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ],
    },
    yAxis: {
        title: {
            text: 'Visitors'
        }
    },
	
	  
    tooltip: {
        shared: true,
        valueSuffix: ' Visitors',
		
		
    },
    credits: {
        enabled: false
    },
  exporting:{enabled: false},

    plotOptions: {
		 series: {      
        },
        areaspline: {
            fillOpacity: 0.2
        }
    },
    series: [{
        name: x.daterangel2l,
        data: x.graphvaluel2lweek,
		color: '#0a89d8'
    }, {
        name: x.daterangelastweek,
       data: x.graphvaluelastweek,
	   color: '#ee2201',
    }]
});
       this.chart = chart;   
   }

   getdonutchart(y)
    {
	let donutchart = new Chart({	
		chart: {
			type: 'pie',
			 spacingBottom: 5,
			spacingTop: 0,
			spacingLeft: 0,
			spacingRight: 0,
			//type: 'areaspline',
			 marginBottom: 0,
			 style: {
			 fontFamily: 'arial',
			 },
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,  
		},
		credits: {
			enabled: false
    },
  exporting:{enabled: false},
    
		colors: ['#0182c3', '#e91d63', '#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
			'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
		],
		title: {
			text: "",
			// style: {
			//     fontSize: 13,
			//     fontWeight: 'bold',
			// },
		},
		tooltip: {
			// valueSuffix: '%',
			// enabled: true,
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				size:'60%',
				innerSize:'50%',
				dataLabels: {
					enabled: true,
				},
				showInLegend: true,
			},
					
		},
		series: [{
			name: 'Visitors',
			// colorByPoint: true,
			data: [{
					name: 'Mobile',
          y: y.Mobile,
					sliced: true,
					selected: true,
					// url:'rich-media.html',
				}, {
					name: 'Desktop',
          y: y.Desktop,
					// url:'rich-media.html',
				},
				{
					name: 'Tablet',
					y: y.Tablet,
					// url:'rich-media.html',
				},
			]
		}]
	});
	  this.donutchart = donutchart;	  
}


getbargraphdata()
    {
let barchart = new Chart({
  chart: {
    type: 'column',
    marginBottom: 85,
    style: {
      fontFamily: 'arial',
    },
  },
  title: {
    text: '',
  },
  colors: ['#0182c3', '#e91d63', '#00a698', '#4f5d68', '#c90649',
    '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
  ],

  credits: { enabled: false, },
  xAxis: {
    title: {
      text: 'Age Group',
    },
    categories: ['18-24','25-34', '35-44', '45-54', '55-64', '65+'],
  },
  yAxis: {
    
    min: 0,
    title: {
      text: 'Visitors',
    },
    stackLabels: {
      enabled: false,

    }
  },
  legend: {
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 10,
    floating: true,
    backgroundColor:'white',
    borderColor: '#CCC',
    borderWidth: 0,
    shadow: false,
    enabled: true,
  },
  exporting: { enabled: false },
  tooltip: {
    // headerFormat: '{point.x}<br/>',
    // pointFormat: '<b>{series.name}: ${point.y}</b>'
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
        color: 'white'
      }
    }
  },
  series: [{
    name: 'Male',
    data: [15, 3, 4, 7, 2, 4],
    stack: 'male'
}, {
    name: 'Female',
    data: [3, 4, 14, 2, 5, 8],
    stack: 'female'
}]
});
  this.barchart = barchart; 

}


gettrenddata(x)
    {
		this.trendChart = new Chart({
    		chart: {
		        type: 'spline'
		    },
		    title: {
            text: '',
            style: {
              fontSize: '12px' 
           },
        },
        
		    xAxis: {
              categories:x.date,
              title: {
                text: 'Days'
            },
	        },
		    yAxis: {
				showEmpty: false,
	            title: {
	                text: 'Avg. Page Load time (Sec.)'
	            },
	            labels: {
	                enabled: true
	            }
			},
			legend: {
				enabled: false,
			},
	        tooltip: {
	            headerFormat: '',
	            pointFormat: '<span style="color:{point.color}"><b>{point.y:.2f}</b> Sec. {point.name}</span>'
	        },
		    credits: {
		        enabled: false
		    },
		    exporting: { enabled: false },
		    plotOptions: {
	            series: {
	                dataLabels: {
	                    enabled: false,
	                    format: '<b>{point.y:.2f}</b> Sec.{point.name}',
	                    
	                }
	            }
	        },
	        colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
	            '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
	        ],
          series: [{
            // name: 'Installation',
            data: x.time,
          }]
    
    });
  }

   
  constructor(private _LinechartService:LinechartService,private _donutchartservice:DonutaudienceService,private _summaryservice:SummaryService,private _lp_highbounceservice:LpHighbounceService) { }

  ngOnInit() {
    this._LinechartService.getLinecahrt(localStorage.getItem('anlytic_id'))
		.subscribe(data => {this.loading = false;this.linechartdata = data['linechartdata'];this.topdata = data['daterangetop'];this.setheightpage();this.getgraphdata(this.linechartdata),this.getbargraphdata();},
    error => this.errorMsg = error);
    
    this._donutchartservice.getdonutcahrt(localStorage.getItem('anlytic_id'))
		.subscribe(data => {this.loading = false;this.donutdata = data['donut_data'];this.donutchartdata = data['donut_chart_data'];this.getdonutchart(this.donutchartdata);},
    error => this.errorMsg = error);
    
    this._summaryservice.getSummary(localStorage.getItem('anlytic_id'))
		.subscribe(data => {this.loading = false;this.daterange = data['daterange'];this.tabulardata_audience = data['tabulardata_audience'];this.audience_session= data['audience_session'];this.tabulardata_behaviour = data['tabulardata_behaviour'];this.tabulardata_bouncerate = data['tabulardata_bouncerate'];this.trenddata = data['pageload_graph_data'];this.gettrenddata(this.trenddata);},
    error => this.errorMsg = error);
    

    this._lp_highbounceservice.getSummary(localStorage.getItem('anlytic_id'))
		.subscribe(data => {this.pageloadtime_loading = false;this.lp_highbounce_daterange = data['daterange'];this.tabulardata_lp_highbounce = data['tabledata_lp_highbounce'];},
		error => this.errorMsg = error);
  }

}
