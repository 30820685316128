import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { RtnbyarticelService } from './rtnbyarticel.service';

@Component({
  selector: 'app-rtrnbypaage',
  templateUrl: './rtrnbypaage.component.html',
  styleUrls: ['./rtrnbypaage.component.css']
})
export class RtrnbypaageComponent implements OnInit {
    chart: Chart;
    articlechart:Chart;
    public donutchatdata = [];
    public topdata = [];
     public dateranges = [];
     public eptabledata = []; 
     public errorMsg;
     public donutchartdata =[];
     public donutdata =[];
     public loading: boolean = true;
     getdonutchart(x)
     {
    console.log(x);
   let articlechart = new Chart({	
    chart: {
      zoomType: 'xy',
   marginBottom:180,
  
  },
  credits: {
      enabled: false
  },
  
  colors: ['#0182c3', '#e91d63', '#ff9f00', '#4f5d68','#00a698',
  '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],
  
  
  title: {
      text: null,
  },
  subtitle: {
      text: '',
  },
  
  
  xAxis: [{
      categories: x.categories,
      crosshair: true,
  
  labels: {
                  formatter: function() {
                      return this.value
                  }
              }
  
      }],
  yAxis: [{ // Primary yAxis
      labels: {
          format: '{value}%',
          style: {
               color: '#000',
          }
      },
  
  
  
      title: {
          text: 'Bounce Rate',
          style: {
               color: '#000',
          }
      },
      opposite: true
  
  }, { // Secondary yAxis
      gridLineWidth: 0,
      title: {
          text: 'Pageview',
          style: {
               color: '#000',
          }
      },
      labels: {
          format: '{value}',
          style: {
             color: '#000',
          }
      }
  
  }, { // Tertiary yAxis
      gridLineWidth: 0,
      title: {
          text: 'Session',
          style: {
              color: '#000',
          }
      },
      labels: {
          format: '{value}%',
          style: {
             // color: Highcharts.getOptions().colors[1]
        color: '#000',
          }
      },
      opposite: true
  }],
  tooltip: {
      shared: true
  },
  
  plotOptions: {
      series: {
          dataLabels: {
              enabled: false,
      formatter: function() {
                  return '<b>' + this.point.name + '</b>: ' + this.percentage.toFixed(2) + ' %';
              },
          }
      }
  },
  
  legend: {
      layout: 'horizontal',
      align: 'center',
      x: 0,
      verticalAlign: 'bottom',
      y: 20,
      floating: true,
      backgroundColor:'#FFFFFF',
   borderColor: '#CCC',
      borderWidth: 0,
      shadow: false,
      enabled:true,
  
  },
  series: [{
      name: 'Pageview',
      type: 'column',
      yAxis: 1,
      data: x.data,
      
  
  }, {
      name: 'Session',
      type: 'spline',
      yAxis: 2,
      data: x.data1,
      // marker: {
      //     enabled: false
      // },
      // dashStyle: 'shortdot',
      // tooltip: {
      //     valueSuffix: '%'
      // }
  
  }, {
      name: 'Bounce Rate',
      type: 'spline',
      data: x.data2,
  //    tooltip: {
  //      valueSuffix: '%'
  //      } 
  }]
  });
       this.articlechart = articlechart;
  }
  
  constructor(private _RtnarticleService:RtnbyarticelService) { }
  
    ngOnInit() {
      this._RtnarticleService.getdonutchart(localStorage.getItem('anlytic_id'))
      .subscribe(data => {this.loading = false;this.donutchatdata = data;this.topdata = data['topdata'];this.getdonutchart(this.donutchatdata);this.eptabledata = data['tabledata'];},
      error => this.errorMsg = error);
    }
  
  }