import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-blankpage',
  templateUrl: './blankpage.component.html',
  styleUrls: ['./blankpage.component.css']
})
export class BlankpageComponent implements OnInit {
  setheightpage()
  {
   $(document).ready(function(){
     var page_height='';
     $(".page-content").css("height", page_height);
   });
  }
  constructor() { }

  ngOnInit() {
    this.setheightpage()
  }

}
