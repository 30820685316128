import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { topgraph } from './adxtopgrphcontent';

@Injectable({
  providedIn: 'root'
})
export class hbversusdirect_barchartService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_pro/hbversusdirect_barchart.php";
  // private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_pro/hbversusdirect_barchart_sandy.php";

  constructor(private http:HttpClient) { }

  gethbversusdirect(daterange,acc_name,uniq_id,new_adx_name): Observable<topgraph[]>{
    return this.http.get<topgraph[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
