import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { invoicedata } from '../invoice/invoicedata';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListinvoicedataService {
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      // this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/network_listinvoicedata.php";
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/network_listinvoicedata_token.php";
    }else{
      // this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/listinvoicedata.php";
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/listinvoicedata_token.php";
    }
  }

  getInvoicedata(uniqid,acc_mgr_id,acc_name,access_token,email): Observable<invoicedata[]>{
    return this.http.get<invoicedata[]>(this._url+ "?&uniq_id="+uniqid+"&acc_mgr_id="+acc_mgr_id+"&acc_name="+acc_name+"&access_token="+access_token+"&email="+email)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
