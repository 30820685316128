import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { adunit } from './gam-adunit';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamReportingAdUnitService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/GAM_reporting/gam_reportunits.php";
  
  constructor(private http:HttpClient) { }

  getAdunit(daterange,child_net_code): Observable<adunit[]>{
    return this.http.get<adunit[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}