import { Component, OnInit } from '@angular/core';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-complain-ticket',
  templateUrl: './complain-ticket.component.html',
  styleUrls: ['./complain-ticket.component.css']
})
export class ComplainTicketComponent implements OnInit {
  apiURL: string = 'https://angular.cybermediaservices.net/assets/web_api/support_ticket/send-mail.php';
  test:any;
  data:any;
  nodata:boolean=false;
  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    console.log('user id');
    console.log(localStorage.getItem('uniq_id'));
    this.getAllTickets(localStorage.getItem('uniq_id'));
  }

  
  public getAllTickets(id){
      this.httpClient.get(this.apiURL+'?q=tickets&uniq_id='+id).subscribe((response)=>{
      this.test=response;
      this.data=this.test.result;
      if(this.data.length==0){this.nodata=true;}
      console.log(this.data);
      
    }) 
  }

}
