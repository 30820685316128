import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { donutchartrtrndev } from './donutrtrnbydev';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DonutrtrnbydevService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/donutrtvbydev.php";
  private _url1: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/donutnewbydev.php";

  constructor(private http:HttpClient) { }

  getdonutchartrtrndev(anlyticid): Observable<donutchartrtrndev[]>{
    return this.http.get<donutchartrtrndev[]>(this._url+"?analyid="+anlyticid)
  }
  getdonutchartnewdev(anlyticid): Observable<donutchartrtrndev[]>{
    return this.http.get<donutchartrtrndev[]>(this._url1+"?analyid="+anlyticid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}

