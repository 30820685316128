import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { CyberadOverviewService } from './cyberad-overview.service';
import { TopContentServiceService } from './top-content-service.service';
import { PioecharttotaalService } from './pioecharttotaal.service';
import { DonutcharttotalService } from './donutcharttotal.service';
import { TopbarGraphService } from './topbar-graph.service';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-cyberads-overview',
  templateUrl: './cyberads-overview.component.html',
  styleUrls: ['./cyberads-overview.component.css']
})
export class CyberadsOverviewComponent implements OnInit {
	lineChart;
	public tabledata = [];
	public topdatas = [];	
	public dateranges = [];
	public topheaddata = [];
	public graphdata = [];
	public daterange = [];
	public sumupdata = [];
	public errorMsg;
	public piedata =[];
	public donutdata =[];
	public month_details = [];
	public graphflagdata = [];  
	public topcontentgraphdata = []; 
	public topvideographdata = [];
	public topappgraphdata = []; 
	public prographdata = [];
	public topbargraphdata = []; 
	public loading: boolean = true; 
	public ca_pro_flag;
	chart: Chart; 
	vschart: Chart;
	barchart: Chart;
	piechart:Chart;
	donutchart:Chart;
	grphdate;
	canw_gdata;
	catot_gdata
	capro_gdata;
	appdata;
	proappdata;
	displaydata;
	prodisplaydata;
	viddata;
	providdata;
	public piechartdata = [];
	public donutchartdata = [];
	public seriesresult=[];
	public ca_seriesresult=[];
	getgraphdata(x,y,z,p,f)
    {
		if(f.app==0)
		{
      this.appdata ={
			name: 'Network App',
			data: 0,
			visible: false,
			stack:'can_cap_app',
		};
		}
		else
		{
			this.appdata ={
				name: 'Network App',
				data: x.revenue,
				stack:'can_cap_app',
			};
			this.grphdate=x.date;
		}
		if(f.pro_app==0)
		{
      this.proappdata ={
			name: 'Pro App',
			data: 0,
			visible: false,
			stack:'can_cap_app',
		};
		}
		else
		{
			this.proappdata ={
				name: 'Pro App',
				data: x.revenue,
				stack:'can_cap_app',
			};
			this.grphdate=x.date;
		}
		if(f.content==0)
		{
			this.displaydata ={
			name: 'Network Display',
			data: 0,
			stack:'can_cap_display',
			visible: false
		};
		}
		else
		{
			this.displaydata ={
				name: 'Network Display',
				data: y.revenue,
				stack:'can_cap_display',
			};
			this.grphdate=y.date;
		}
		if(f.pro_content==0)
		{
			this.prodisplaydata ={
			name: 'Pro Display',
			data: 0,
			stack:'can_cap_display',
			visible: false
		};
		}
		else
		{
			this.prodisplaydata ={
				name: 'Pro Display',
				data: p.revenue,
				stack:'can_cap_display',
			};
			this.grphdate=y.date;
		}
		if(f.video==0)
		{
			this.viddata ={
			name: 'Network Video',
			data: 0,
			visible: false,
			stack:'can_cap_video',
		};
		}
		else
		{
			this.viddata ={
				name: 'Network Video',
				data: z.revenue,
				stack:'can_cap_video',
			};
			this.grphdate=z.date;
		}

		if(f.pro_video==0)
		{
			this.providdata ={
			name: 'Pro Video',
			data: 0,
			visible: false,
			stack:'can_cap_video',
		};
		}
		else
		{
			this.providdata ={
				name: 'Pro Video',
				data: z.revenue,
				stack:'can_cap_video',
			};
			this.grphdate=z.date;
		}
		
		this.seriesresult=[this.appdata,this.displaydata,this.viddata,this.prodisplaydata,this.proappdata,this.providdata]
		let barchart = new Chart({
			chart: {
				type: 'column',
				marginBottom: 85,
				style: {
					fontFamily: 'arial',
				},
			},
			title: {
				text: '',
			},
			colors: ['#0182c3', '#e91d63', '#00a698', '#4f5d68', '#c90649',
				'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
			],
	
			credits: { enabled: false, },
			xAxis: {
				categories:this.grphdate,
			},
			yAxis: {
				
				min: 0,
				title: {
					text: 'Estimated Revenue ($)',
				},
				stackLabels: {
					enabled: false,
	
				}
			},
			legend: {
				align: 'center',
				x: 0,
				verticalAlign: 'bottom',
				y: 10,
				floating: true,
				backgroundColor:'white',
				borderColor: '#CCC',
				borderWidth: 0,
				shadow: false,
				enabled: true,
			},
			exporting: { enabled: false },
			tooltip: {
				headerFormat: '{point.x}<br/>',
				pointFormat: '<b>{series.name}: ${point.y}</b>'
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false,
						color: 'white'
					}
				}
			},
			series: this.seriesresult
		});
		  this.barchart = barchart; 
		
	}
	getversusgraphdata(y)
	{
		if(this.ca_pro_flag=='true')
		{
	  this.capro_gdata =
	  {
			name: 'CyberAds Pro',
			data: y.ecpm_pro,
			color: '#e91e63',
	  };
		}
		else
		{
		  this.capro_gdata =
		  {
			  name: 'CyberAds Pro',
			  data: 0,
			  color: '#e91e63',
			  visible: false
		  };
		}
		let vschart = new Chart({
			chart: {

				spacingBottom: 5,
				spacingTop: 10,
				spacingLeft: 10,
				spacingRight: 10,
	
				type: 'area',
				marginBottom: 85,
				style: {
					fontFamily: 'arial',
				},
	
	
			},
			title: {
				text: '',
			},
	
			legend: {
				align: 'center',
				verticalAlign: 'bottom',
				x: 0,
				y: 10,
				floating: true,
				borderWidth: 0,
				backgroundColor:'#FFFFFF'
			},
			xAxis: {
				categories: y.date,
			},
			yAxis: {
				title: {
					text: 'CPM ($)'
				}
			},
	
			tooltip: {
				shared: true,
				valuePrefix: '$',
	
	
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
	
	
			plotOptions: {
				series: {
					dataLabels: {
						//enabled: true,
						//format: '{y}$',
						borderRadius: 5,
						//backgroundColor: 'rgba(252, 255, 197, 0.7)',
						borderWidth: 0,
						borderColor: 'rgba(255, 255, 255, 0.18)',
						y: -6
					},
	
	
	
				},
	
				area: {
					fillOpacity: 0.08
				}
			},
			series: [
				this.capro_gdata, 
			{
				name: 'CyberAds Network',
				data: y.ecpm_nw,
				color: '#0182c3',
			}

        ]
			//series: this.ca_seriesresult
		});
		  this.vschart = vschart;  
	}


	  getlinegraphdata(y)
	  {
		if(this.ca_pro_flag=='true')
		{
	  this.capro_gdata =
	  {
		  name: 'CyberAds Pro',
		  data: y.ad_server_rev,
		  color: '#e91e63',
	  };
		}
		else
		{
		  this.capro_gdata =
		  {
			  name: 'CyberAds Pro',
			  data: 0,
			  color: '#e91e63',
			  visible: false
		  };
		}
		this.catot_gdata =
			{
			  name: 'Total',
			  data: y.tot_revenue,
			  color: '#0182c3',
		  };
		this.canw_gdata =
		{
			name: 'CyberAds Network',
			data: y.adx_rev,
			color: '#4f5d68',
		};
	  

	this.ca_seriesresult=[this.catot_gdata,this.capro_gdata,this.canw_gdata]
		let chart = new Chart({
			chart: {

				spacingBottom: 5,
				spacingTop: 10,
				spacingLeft: 10,
				spacingRight: 10,
	
				type: 'area',
				marginBottom: 85,
				style: {
					fontFamily: 'arial',
				},
	
	
			},
			title: {
				text: '',
			},
	
			legend: {
				align: 'center',
				verticalAlign: 'bottom',
				x: 0,
				y: 10,
				floating: true,
				borderWidth: 0,
				backgroundColor:'#FFFFFF'
			},
			xAxis: {
				categories: y.date,
			},
			yAxis: {
				title: {
					text: 'Estimated Revenue ($)'
				}
			},
	
			tooltip: {
				shared: true,
				valuePrefix: '$',
	
	
			},
			credits: {
				enabled: false
			},
			exporting: { enabled: false },
	
	
			plotOptions: {
				series: {
					dataLabels: {
						//enabled: true,
						//format: '{y}$',
						borderRadius: 5,
						//backgroundColor: 'rgba(252, 255, 197, 0.7)',
						borderWidth: 0,
						borderColor: 'rgba(255, 255, 255, 0.18)',
						y: -6
					},
	
	
	
				},
	
				area: {
					fillOpacity: 0.08
				}
			},
			series: this.ca_seriesresult
		});
		  this.chart = chart; 
		}
		getpiechartdata(x)
		{
			let piechart = new Chart({
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				credits: {
					enabled: false
				},
				colors: ['#0182c3', '#00a698', '#8fc321', '#ff9f00', '#ee2201', '#4f5d68'
				],
				title: {
					text: ''
				},
				tooltip: {
					pointFormat: '${point.y}'
				},
				exporting: { enabled: false },
				plotOptions: {
					series: {
						cursor: 'pointer',
						events: {
							click: function (event) {
								//window.location.href = 'adx-for-content-adtypes';
							}
						}
					},
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true
						},
						showInLegend: true
					}
				},
				series: [
					{
					  name: "Geo",
					  
					  data: x,
					}
					],
			});
			  this.piechart = piechart; 
		}	
		getdonutchart(y)
		{
			console.log(y);
			let donutchart = new Chart({	
				chart: {
					type: 'pie',
					 spacingBottom: 5,
					spacingTop: 0,
					spacingLeft: 0,
					spacingRight: 0,
					//type: 'areaspline',
					 marginBottom: 0,
					 style: {
					 fontFamily: 'arial',
					 },
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,  
				},
				exporting: { enabled: false },
				credits: {
					enabled: false
				},
				colors: ['#0182c3', '#e91d63', '#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
					'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
				],
				title: {
					text: "",
					// style: {
					//     fontSize: 13,
					//     fontWeight: 'bold',
					// },
				},
				tooltip: {
					// valueSuffix: '%',
					// enabled: true,
					pointFormat: '${point.y}'
				},
				plotOptions: {
					series: {
						cursor: 'pointer',
						events: {
							// click: function (event) {
							// 	window.location.href = 'adx-for-content-device';
							// }
						}
					},
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						size:'60%',
						innerSize:'50%',
						dataLabels: {
							enabled: true,
						},
						showInLegend: true,
					},
							
				},
				series: [{
					name: 'Visitors',
					// colorByPoint: true,
					data: y
				}]
			});
			  this.donutchart = donutchart;
		  function init_daterangepicker() {
			  if ("undefined" != typeof $.fn.daterangepicker) {
				  //console.log("init_daterangepicker");
				  console.log(moment().format());
				  var a = function(a, b, c) {
						  //console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					  },
					  b = {
						  startDate: moment().subtract(7, "days"),
						  endDate: moment(),
						 // minDate: moment().subtract(3, "month"),
						 minDate: new Date('2019-08-01'),
						  maxDate: moment(),
						  dateLimit: {
							  days: 90
						  },
						  showDropdowns: !0,
						  showWeekNumbers: !0,
						  timePicker: !1,
						  timePickerIncrement: 1,
						  timePicker12Hour: !0,
						  ranges: {
							  Today: [moment(), moment()],
							  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							  "Last 7 Days": [moment().subtract(6, "days"), moment()],
							  "Last 30 Days": [moment().subtract(29, "days"), moment()],
							  "This Month": [moment().startOf("month"), moment()],
							  "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
						  },
						  opens: "left",
						  buttonClasses: ["btn bg-pink"],
						  applyClass: "btn-small btn-primary",
						  cancelClass: "btn-small",
						  format: "MM/DD/YYYY",
						  separator: " to ",
						  locale: {
							  applyLabel: "Submit",
							  cancelLabel: "Clear",
							  fromLabel: "From",
							  toLabel: "To",
							  customRangeLabel: "Custom",
							  daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							  firstDay: 1
						  }
					  };
				  $(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
					 // console.log("show event fired")
				  }), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
					  //console.log("hide event fired")
				  }), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
											  
		  let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
		  $("#dateinput").val(strtdate);
		  let startdate=b.startDate.format("MMMM D, YYYY");
		  let enddate=b.endDate.format("MMMM D, YYYY");
		  localStorage.setItem('strtdate', strtdate);
		  localStorage.setItem('startdate', enddate);
		localStorage.setItem('enddate', startdate);
		  window.location.reload();
		  
	  
					 // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				  }), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
					  //console.log("cancel event fired")
				  }), $("#options1").click(function() {
					  $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				  }), $("#options2").click(function() {
					  $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				  }), $("#destroy").click(function() {
					  $(".custom-daterangepicker").data("daterangepicker").remove()
				  })
			  }
			  
		  }
	  
		  function init_daterangepicker_right() {
			  if ("undefined" != typeof $.fn.daterangepicker) {
				 // console.log("init_daterangepicker_right");
				  var a = function(a, b, c) {
						  //console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					  },
					  b = {
						  startDate: moment().subtract(6, "days"),
						  endDate: moment(),
						  // minDate: moment().subtract(3, "month"),
						  minDate: new Date('2019-08-01'),
						  maxDate: moment(),
						  dateLimit: {
							  days: 90
						  },
						  showDropdowns: !0,
						  showWeekNumbers: !0,
						  timePicker: !1,
						  timePickerIncrement: 1,
						  timePicker12Hour: !0,
						  ranges: {
							  Today: [moment(), moment()],
							  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							  "Last 7 Days": [moment().subtract(6, "days"), moment()],
							  "Last 30 Days": [moment().subtract(29, "days"), moment()],
							  "This Month": [moment().startOf("month"), moment()],
							  "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
						  },
						  opens: "right",
						  buttonClasses: ["btn btn-default"],
						  applyClass: "btn-small btn-primary",
						  cancelClass: "btn-small",
						  format: "MM/DD/YYYY",
						  separator: " to ",
						  locale: {
							  applyLabel: "Submit",
							  cancelLabel: "Clear",
							  fromLabel: "From",
							  toLabel: "To",
							  customRangeLabel: "Custom",
							  daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							  firstDay: 1
						  }
					  };
				  $("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
					 // console.log("show event fired")
				  }), $("#reportrange_right").on("hide.daterangepicker", function() {
					 // console.log("hide event fired")
				  }), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
					 // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				  }), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
					 // console.log("cancel event fired")
				  }), $("#options1").click(function() {
					  $("#reportrange_right").data("daterangepicker").setOptions(b, a)
				  }), $("#options2").click(function() {
					  $("#reportrange_right").data("daterangepicker").setOptions(b, a)
				  }), $("#destroy").click(function() {
					  $("#reportrange_right").data("daterangepicker").remove()
				  })
			  }
		  }
	  
		  function init_daterangepicker_single_call() {
			  "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
				  singleDatePicker: !0,
				  singleClasses: "picker_1"
			  }, function(a, b, c) {
				  //console.log(a.toISOString(), b.toISOString(), c)
			  }), $("#single_cal2").daterangepicker({
				  singleDatePicker: !0,
				  singleClasses: "picker_2"
			  }, function(a, b, c) {
				 // console.log(a.toISOString(), b.toISOString(), c)
			  }), $("#single_cal3").daterangepicker({
				  singleDatePicker: !0,
				  singleClasses: "picker_3"
			  }, function(a, b, c) {
				 // console.log(a.toISOString(), b.toISOString(), c)
			  }), $("#single_cal4").daterangepicker({
				  singleDatePicker: !0,
				  singleClasses: "picker_4"
			  }, function(a, b, c) {
				  console.log(a.toISOString(), b.toISOString(), c)
			  }))
		  }
	  
		  function init_daterangepicker_reservation() {
			  "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
				  //console.log(a.toISOString(), b.toISOString(), c)
			  }), $("#reservation-time").daterangepicker({
				  timePicker: !0,
				  timePickerIncrement: 30,
				  locale: {
					  format: "MM/DD/YYYY h:mm A"
				  }
			  }))
		  }
	  
		  function init_SmartWizard() {
			  "undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
				  transitionEffect: "slide"
			  }), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
		  }
	  
	  
	  
	  
		  var originalLeave = $.fn.popover.Constructor.prototype.leave;
		  $.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
			  init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
		  });
	  }
	  constructor(private _CyberadOverviewService:CyberadOverviewService,private _topcontentService:TopContentServiceService,private _piechartService:PioecharttotaalService,private _donutchartService:DonutcharttotalService,private _TopbarGraphService:TopbarGraphService) { }
  
	  ngOnInit() {
		this._TopbarGraphService.getbargraph(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('old_acc_name'),localStorage.getItem('uniq_id'))
		.subscribe(data => {this.loading = false; this.topbargraphdata = data['app_graph_date'],this.topcontentgraphdata = data["content_graph_date"],this.topvideographdata = data["video_graph_date"],this.prographdata = data["pro"],this.graphflagdata = data["flag"];
	  this.getgraphdata(this.topbargraphdata,this.topcontentgraphdata,this.topvideographdata,this.prographdata,this.graphflagdata);
	  },
		error => this.errorMsg = error);

		this._topcontentService.getTopcontent(localStorage.getItem('strtdate'),localStorage.getItem('acc_mgr_id'),localStorage.getItem('acc_name'),localStorage.getItem('ads_id'),localStorage.getItem('old_acc_name'),localStorage.getItem('uniq_id'))
		.subscribe(data => {this.topdatas = data['app_top_data'],this.month_details = data['month_details'],this.dateranges = data["app_graph_date"];},
		error => this.errorMsg = error);

			this._CyberadOverviewService.getTopgraph(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'))
		.subscribe(data => {this.loading = false;this.tabledata = data["hbd_table_data"], this.topappgraphdata = data['hbd_graph_data'],this.sumupdata = data['sum_table_data'],this.ca_pro_flag = data['ca_pro_flag_data'],this.getversusgraphdata(this.topappgraphdata),this.getlinegraphdata(this.topappgraphdata);
	  },
		error => this.errorMsg = error); 

		this._piechartService.getadspiechart(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'))
		.subscribe(data =>{this.graphdata = data['level1'],this.piedata = data['pie_data'];
	  this.getpiechartdata(this.graphdata);
	  },
		error => this.errorMsg = error);
		this._donutchartService.getdonutchart(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'),localStorage.getItem('website_name'),localStorage.getItem('ads_id'))
		  .subscribe(data => {this.donutchartdata = data['donut_chart_data'],this.donutdata = data['donut_data'];
		this.getdonutchart(this.donutchartdata);
		},
		  error => this.errorMsg = error);
	  }
  
  }
  