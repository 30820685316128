import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { GemAdmanagerService } from './geminvite.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-gem-invite',
    templateUrl: './gem-invite.component.html',
    styleUrls: ['./gem-invite.component.css']
})
export class GemAdmanagerInvite implements OnInit {
  public monthyear;
  public errorMsg;
  public primary_email;
  public pub_uniq_id;
  public pub_acc_name;
  public gamAcc;
  public memberType;
  public newEmail;
  public domainName;
  public inviteURL;

  constructor(private httpClient: HttpClient,private _gemAdManagerService:GemAdmanagerService,private router: ActivatedRoute, private routes: Router) { }

  ngOnInit() {
    if(localStorage.getItem('usertype')=='2'){
        this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/gamInvitentwork.php";
    }else{
        this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/gamInvite.php";
        // this.inviteURL ="https://angular.cybermediaservices.net/assets/web_api/gamInvitecopy.php";
    }
    this.monthyear = this.router.snapshot.paramMap.get('m');
    this._gemAdManagerService.getGemAdManagerdata(localStorage.getItem('uniq_id'),localStorage.getItem('acc_mgr_id'))
    .subscribe(data => {
      this.primary_email = data['gamEmail'];
      this.pub_uniq_id = data['pub_uniq_id'];
      this.pub_acc_name = data['pub_acc_name'];
      this.gamAcc = data['gamAcc'];
    },
      error => this.errorMsg = error);
  }

  checkemailuse() {
    this.memberType = $("input[name='select']:checked").val();
    if(this.memberType=='no'){
        $("#div_id_new_email").show();
        $("#div_id_note1").hide();
        $("#div_id_note2").show();
        $("#div_id_email").hide();
    }
    if(this.memberType=='yes'){
        $("#div_id_new_email").hide();
        $("#div_id_note2").hide();
        $("#div_id_note1").show();
        $("#div_id_email").show();
    }
  }
  sendInvite(data){
    
    this.memberType = $("input[name='select']:checked").val();
    this.domainName = $("#id_company").val();
    
    
    if(this.memberType=='yes'){
        if($("#id_email").val()==''){
            alert("Plese Enter GAM Registered Email ID");
            return false;
        }else{
            data['primaryEmail'] = $("#id_email").val();
        }
    }
    if(this.memberType=='no'){
       this.newEmail =  $("#id_new_email").val();
       if(this.newEmail!=''){
        data['newEmail'] = this.newEmail;
        // alert(this.newEmail);
       }else{
        alert("Plese Enter new email");
        return false;
       }
    }
    if(this.domainName==''){
        alert("Please Enter Domain Name");
        return false;
    }
    
    if(this.gamAcc=='Y'){
        data['primaryEmail'] = this.primary_email;
    }
    data['memberType'] = this.memberType;
    data['domainName'] = this.domainName;
    data['pub_uniq_id'] = this.pub_uniq_id;
    data['pub_acc_name'] = this.pub_acc_name;
    data['gamAcc'] = this.gamAcc;
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    // this.httpClient.post(this.inviteURL+'?q=newinvite',formData).subscribe((response)=>{
    //     alert("Thank you Adding  Domain Name. Our team has started a analysis of your website.");
    //     // location.reload(); 
    // })
    this.httpClient.post(this.inviteURL+'?q=newinvite',formData).subscribe(data => {
        if(data['msg']=='success'){
            alert("Thank you Adding  Domain Name. Our team has started a analysis of your website.");
        }else{
            alert(data['text']);
        }
        location.reload(); 
      },error => this.errorMsg = error);
  }

}

