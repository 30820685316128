import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';

interface resultdata {
  month: string,
  pub_name:string,
  earnings: string,
  invalid: string,
  final_rev: string
  
}

@Injectable({
    providedIn: 'root'
  })
  export class AppService {
    public usertype: any;
    private _url: string = "";
  
    constructor(private http:HttpClient) {
      this.usertype = localStorage.getItem('usertype');
      if(this.usertype==2){
        this._url = "https://angular.cybermediaservices.net/assets/web_api/fetch_gem_network.php";
      }else{
        this._url = "https://angular.cybermediaservices.net/assets/web_api/fetch_gem_publisher.php";
      }
    }
  
    getGemAppdata(uniqid,acc_mgr_id): Observable<resultdata[]>{
      return this.http.get<resultdata[]>(this._url+ "?&uniq_id="+uniqid+"&acc_mgr_id="+acc_mgr_id)
    }
    errorHandler(error: HttpErrorResponse){
      return observableThrowError(error.message || "Server Error");
    }
  }