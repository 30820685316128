import { Component, OnInit } from '@angular/core';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit {
  apiURL: string = 'https://angular.cybermediaservices.net/assets/web_api/support_ticket/send-mail.php';
  show: boolean = false;
  msg: boolean = false;
  myFiles:any;
  data: any = {};
  formData:any;
  model: any = {};
  nrSelect='';
  constructor(private httpClient: HttpClient,private router: Router) { }

  ngOnInit() {
  }
  fileToUpload:File = null;

  handleFileInput(file: FileList){
   this.fileToUpload = file.item(0);
  }


  onClickSubmit(data) {
    this.show=true;
    console.log('ticket form submission');
    data['uniqid']=localStorage.getItem('uniq_id');
    const formData: FormData = new FormData();
       formData.append('data', JSON.stringify(data));
       if(this.fileToUpload){formData.append('file', this.fileToUpload, this.fileToUpload.name);}
       this.httpClient.post(this.apiURL+'?q=newticket',formData).subscribe((response)=>{
        //alert("Thanks! Successfully Submitted.");
        this.show=false;
        this.msg=true;
        setTimeout (() => {
          this.router.navigate(['/complain-ticket']);
         }, 5000);
        //this.router.navigate(['/complain-ticket']);
    }) 
    
     
   }


}
