import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { HeaderbidderService } from './headerbidder.service';
import { BarcharttotalService } from './barcharttotal.service';
import { hbversusdirect_barchartService } from './hbversusdirect-barchart-service.service';

declare var $: any;
import * as moment from 'moment';
@Component({
  selector: 'app-pro-dashboard',
  templateUrl: './pro-dashboard.component.html',
  styleUrls: ['./pro-dashboard.component.css']
})
export class ProDashboardComponent implements OnInit {
  public strtdate: string;
	public enddate: Date;
	private isValid: boolean = true;
	private isValidheatmap: boolean = true;
	  title = "";
	  grphdate;
	  private loading: boolean = true;
	  private loadingpie: boolean = true;
	  private loadingdonut: boolean = true;
	  private loadingheatmap: boolean = true;
	  private loadingheatmapdata: boolean = true;
	  private loadingindbench: boolean = true;
	  private adsmenu: boolean = true;
	  public topdatas = [];
	  public sumupdata  = [];
	  public dateranges = [];
	  public indbenchdata = []; 
	  public errorMsg;
	  hbdata;
	  directdata;
	  public adxdata;
	  public adxmenu;
	  public adsdata;
	  public piedata =[];
	  public donutdata =[];
	  public flagdata =[];
	  public month_details = [];
	  public topadsgraphdata =[];
	  public topappgraphdata = [];  
	  public topcontentgraphdata = []; 
	  public tophbdata = [];
	  public topdirectdata = [];
	  public chartflagdata = [];
	  public tabledata = []; 
	public seriesresult=[];
	public graphdata = [];
	public piechartdata = [];
	public donutchartdata = [];
	 chart: Chart;
	 barchart: Chart;
	 donutchart:Chart;
	 setheightpage()
	 {
		$(document).ready(function(){
			var page_height='';
			$(".page-content").css("height", page_height);
		});
	 }
	 getbarchartdata(x,y,f)
    {
		
		if(f.hb==0)
		{
      this.hbdata ={
			name: 'Header Bidder',
			data: 0,
			stack: 'hb_v/s_direct',
			visible: false
		};
		}
		else
		{
			this.hbdata ={
				name: 'Header Bidder',
				data: x.revenue,
				stack: 'hb_v/s_direct'
			};
			this.grphdate=x.date;
		}
		if(f.direct==0)
		{
			this.directdata ={
			name: 'Direct Deal',
			data: 0,
			stack: 'hb_v/s_direct',
			visible: false
		};
		}
		else
		{
			this.directdata ={
				name: 'Direct Deal',
				data: y.revenue,
				stack: 'hb_v/s_direct'
			};
			this.grphdate=y.date;
		}
		
		this.seriesresult=[this.hbdata,this.directdata]
		let barchart = new Chart({
			chart: {
				type: 'column',
				marginBottom: 85,
				style: {
					fontFamily: 'arial',
				},
			},
			title: {
				text: '',
			},
			colors: ['#0182c3', '#e91d63', '#4f5d68', '#4f5d68', '#00a698',
				'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
			],
	
			credits: { enabled: false, },
			xAxis: {
				categories:this.grphdate,
			},
			yAxis: {
				
				min: 0,
				title: {
					text: 'Estimated Revenue ($)',
				},
				stackLabels: {
					enabled: false,
	
				}
			},
			legend: {
				align: 'center',
				x: 0,
				verticalAlign: 'bottom',
				y: 10,
				floating: true,
				backgroundColor:'white',
				borderColor: '#CCC',
				borderWidth: 0,
				shadow: false,
				enabled: true,
			},
			exporting: { enabled: false },
			tooltip: {
				headerFormat: '{point.x}<br/>',
				pointFormat: '<b>{series.name}: ${point.y}</b>'
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false,
						color: 'white'
					}
				}
			},
			series: this.seriesresult
			// series: [{
			// 	name: 'Header_Bidder',
			// 	data: [5, 3, 4, 7, 2],
			// 	stack: 'hb_v/s_direct'
			// }, {
			// 	name: 'Direct_Deal',
			// 	data: [3, 4, 4, 2, 5],
			// 	stack: 'hb_v/s_direct'
			// } ]
		});
		  this.barchart = barchart; 
		
	}
	 getgraphdata(y)
		{
			let chart = new Chart({
				chart: {

					spacingBottom: 5,
					spacingTop: 10,
					spacingLeft: 10,
					spacingRight: 10,
		
					type: 'area',
					marginBottom: 85,
					style: {
						fontFamily: 'arial',
					},
		
		
				},
				title: {
					text: '',
				},
		
				legend: {
					align: 'center',
					verticalAlign: 'bottom',
					x: 0,
					y: 10,
					floating: true,
					borderWidth: 0,
					backgroundColor:'#FFFFFF'
				},
				xAxis: {
					categories: y.date,
				},
				yAxis: {
					title: {
						text: 'Estimated Revenue ($)'
					}
				},
		
		
				tooltip: {
					shared: true,
					valuePrefix: '$',
		
		
				},
				credits: {
					enabled: false
				},
				exporting: { enabled: false },
		
		
				plotOptions: {
					series: {
						dataLabels: {
							//enabled: true,
							//format: '{y}$',
							borderRadius: 5,
							//backgroundColor: 'rgba(252, 255, 197, 0.7)',
							borderWidth: 0,
							borderColor: 'rgba(255, 255, 255, 0.18)',
							y: -6
						},
		
		
		
					},
		
					area: {
						fillOpacity: 0.08
					}
				},
				series: [
					//{
		// 			name: 'Total',
		// 			data: y.tot_revenue,
		// 			color: '#0182c3',
        // },
        {
					name: 'Header Bidder',
					data: y.tot_revenue,
					color: '#0182c3',
        }
        // {
		// 			name: 'CyberAds Network',
		// 			data: y.adx_rev,
		// 			color: '#4f5d68',
		// 		}
	]
			});
			  this.chart = chart;
			  function init_daterangepicker() {
				if ("undefined" != typeof $.fn.daterangepicker) {
					//console.log("init_daterangepicker");
					var a = function(a, b, c) {
							//console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
						},
						b = {
							startDate: moment().subtract(7, "days"),
							endDate: moment(),
							// minDate: moment().subtract(3, "month"),
							minDate: new Date('2019-08-01'),
							maxDate: moment(),
							dateLimit: {
								days: 90
							},
							showDropdowns: !0,
							showWeekNumbers: !0,
							timePicker: !1,
							timePickerIncrement: 1,
							timePicker12Hour: !0,
							ranges: {
								Today: [moment(), moment()],
								Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
								"Last 7 Days": [moment().subtract(6, "days"), moment()],
								"Last 30 Days": [moment().subtract(29, "days"), moment()],
								"This Month": [moment().startOf("month"), moment()],
								"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
							},
							opens: "left",
							buttonClasses: ["btn bg-pink"],
							applyClass: "btn-small btn-primary",
							cancelClass: "btn-small",
							format: "MM/DD/YYYY",
							separator: " to ",
							locale: {
								applyLabel: "Submit",
								cancelLabel: "Clear",
								fromLabel: "From",
								toLabel: "To",
								customRangeLabel: "Custom",
								daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
								monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
								firstDay: 1
							}
						};
					$(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
					   // console.log("show event fired")
					}), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
						//console.log("hide event fired")
					}), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
												
			let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
			$("#dateinput").val(strtdate);
			let startdate=b.startDate.format("MMMM D, YYYY");
			let enddate=b.endDate.format("MMMM D, YYYY");
			localStorage.setItem('strtdate', strtdate);
			localStorage.setItem('startdate', enddate);
			localStorage.setItem('enddate', startdate);
			window.location.reload();
			
		
					   // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
					}), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
						//console.log("cancel event fired")
					}), $("#options1").click(function() {
						$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
					}), $("#options2").click(function() {
						$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
					}), $("#destroy").click(function() {
						$(".custom-daterangepicker").data("daterangepicker").remove()
					})
				}
				
			}
		
			function init_daterangepicker_right() {
				if ("undefined" != typeof $.fn.daterangepicker) {
				   // console.log("init_daterangepicker_right");
					var a = function(a, b, c) {
							//console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
						},
						b = {
							startDate: moment().subtract(6, "days"),
							endDate: moment(),
							// minDate: moment().subtract(3, "month"),
							minDate: new Date('2019-08-01'),
							maxDate: moment(),
							dateLimit: {
								days: 90
							},
							showDropdowns: !0,
							showWeekNumbers: !0,
							timePicker: !1,
							timePickerIncrement: 1,
							timePicker12Hour: !0,
							ranges: {
								Today: [moment(), moment()],
								Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
								"Last 7 Days": [moment().subtract(6, "days"), moment()],
								"Last 30 Days": [moment().subtract(29, "days"), moment()],
								"This Month": [moment().startOf("month"), moment()],
								"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
							},
							opens: "right",
							buttonClasses: ["btn btn-default"],
							applyClass: "btn-small btn-primary",
							cancelClass: "btn-small",
							format: "MM/DD/YYYY",
							separator: " to ",
							locale: {
								applyLabel: "Submit",
								cancelLabel: "Clear",
								fromLabel: "From",
								toLabel: "To",
								customRangeLabel: "Custom",
								daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
								monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
								firstDay: 1
							}
						};
					$("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
					   // console.log("show event fired")
					}), $("#reportrange_right").on("hide.daterangepicker", function() {
					   // console.log("hide event fired")
					}), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
					   // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
					}), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
					   // console.log("cancel event fired")
					}), $("#options1").click(function() {
						$("#reportrange_right").data("daterangepicker").setOptions(b, a)
					}), $("#options2").click(function() {
						$("#reportrange_right").data("daterangepicker").setOptions(b, a)
					}), $("#destroy").click(function() {
						$("#reportrange_right").data("daterangepicker").remove()
					})
				}
			}
		
			function init_daterangepicker_single_call() {
				"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
					singleDatePicker: !0,
					singleClasses: "picker_1"
				}, function(a, b, c) {
					//console.log(a.toISOString(), b.toISOString(), c)
				}), $("#single_cal2").daterangepicker({
					singleDatePicker: !0,
					singleClasses: "picker_2"
				}, function(a, b, c) {
				   // console.log(a.toISOString(), b.toISOString(), c)
				}), $("#single_cal3").daterangepicker({
					singleDatePicker: !0,
					singleClasses: "picker_3"
				}, function(a, b, c) {
				   // console.log(a.toISOString(), b.toISOString(), c)
				}), $("#single_cal4").daterangepicker({
					singleDatePicker: !0,
					singleClasses: "picker_4"
				}, function(a, b, c) {
					console.log(a.toISOString(), b.toISOString(), c)
				}))
			}
		
			function init_daterangepicker_reservation() {
				"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
					//console.log(a.toISOString(), b.toISOString(), c)
				}), $("#reservation-time").daterangepicker({
					timePicker: !0,
					timePickerIncrement: 30,
					locale: {
						format: "MM/DD/YYYY h:mm A"
					}
				}))
			}
		
			function init_SmartWizard() {
				"undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
					transitionEffect: "slide"
				}), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
			}
		
		
		
		
			var originalLeave = $.fn.popover.Constructor.prototype.leave;
			$.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
				init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
			}); 
		}
	
	getdonutchart(y)
	{
	let donutchart = new Chart({	
		chart: {
			type: 'pie',
			 spacingBottom: 5,
			spacingTop: 0,
			spacingLeft: 0,
			spacingRight: 0,
			//type: 'areaspline',
			 marginBottom: 0,
			 style: {
			 fontFamily: 'arial',
			 },
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,  
		},
		exporting: { enabled: false },
		credits: {
			enabled: false
		},
		colors: ['#e91d63','#0182c3','#7b1fa2', '#4f5d68', '#ff9f00', '#00a698',
			'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'
		],
		title: {
			text: "",
			// style: {
			//     fontSize: 13,
			//     fontWeight: 'bold',
			// },
		},
		tooltip: {
			// valueSuffix: '%',
			// enabled: true,
			pointFormat: '{point.y}'
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				events: {
					// click: function (event) {
					// 	window.location.href = 'adx-for-content-device';
					// }
				}
			},
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				size:'60%',
				innerSize:'50%',
				dataLabels: {
					enabled: true,
				},
				showInLegend: true,
			},
					
		},
		series: [{
			name: 'Visitors',
			// colorByPoint: true,
			data: y
		}]
	});
	  this.donutchart = donutchart;	
	}	
  constructor(private _topcontentService:HeaderbidderService,private _topgraphService:BarcharttotalService,private _hbversusdirect:hbversusdirect_barchartService) { }

  ngOnInit(): void{		
		if(localStorage.getItem('ads_id')!= '' && localStorage.getItem('ads_id')!='ca-')
		{
		  this.adsmenu=true;
		}
		else{
		  this.adsmenu=false;
		}
		if(localStorage.getItem('adx_id')!= '')
		{
		  this.adxmenu=true;
		}
		else{
		  this.adxmenu=false;
		}	
		setTimeout(() => {
			let websites_nme=localStorage.getItem('website_name');
			//alert(websites_nme);
		  $("#web_name option[value='"+ websites_nme +"']").attr("selected", "selected");
		  
		}, 3000);
		
		this._topcontentService.getTopcontent(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('ads_id'),localStorage.getItem('old_acc_name'),localStorage.getItem('uniq_id'))
		.subscribe(data => {this.topdatas = data['app_top_data'],this.month_details = data['month_details'],this.dateranges = data["app_graph_date"];},
		error => this.errorMsg = error);
	
			this._topgraphService.getTopgraph(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'))
		  .subscribe(data => {this.loading = false;this.tabledata = data["hbd_table_data"], this.topappgraphdata = data['hbd_graph_data'],this.sumupdata = data['sum_table_data'],
		this.getgraphdata(this.topappgraphdata);},
		error => this.errorMsg = error);

		this._hbversusdirect.gethbversusdirect(localStorage.getItem('strtdate'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),localStorage.getItem('old_acc_name'))
		  .subscribe(data => {this.loading = false;this.hbdata = data['hbd_graph_data'],this.directdata = data['direct_graph_data'],this.chartflagdata = data['flag'],this.getbarchartdata(this.hbdata,this.directdata,this.chartflagdata);
		},
		  error => this.errorMsg = error);
		 
}

}
