import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { donutchart } from './donutchart';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdunitdetailService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/inventory/fetch_cyberads_adunit.php";

  constructor(private http:HttpClient) { } 

  getadunitsize(uniqid,daterange): Observable<donutchart[]>{
    return this.http.get<donutchart[]>(this._url+"?"+daterange+"&uniqid="+uniqid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
