import { Injectable } from '@angular/core';
import {throwError as observableThrowError, Observable} from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { piechart } from './adxpiechartcontent';

@Injectable({
  providedIn: 'root'
})
export class InsightdataService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Analytics/insights.php";

  constructor(private http:HttpClient) { }

  getinsightdata(analyticid,uniq_id): Observable<piechart[]>{
    return this.http.get<piechart[]>(this._url+ "?accountid="+analyticid+"&uniqid="+uniq_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
