import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

interface myData {
  message: string,
  success: boolean
}

interface isLoggedIn {
  status: boolean
}

interface logoutStatus {
  success: boolean
}
@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  getSomeData() {
    return this.http.get<myData>('https://angular.cybermediaservices.net/assets/web_api/database.php')
  }

  isLoggedIn(): Observable<isLoggedIn> {
    return this.http.get<isLoggedIn>('https://angular.cybermediaservices.net/assets/web_api/isloggedin.php')
  }

  logout() {
    return this.http.get<logoutStatus>('https://angular.cybermediaservices.net/assets/web_api/logout.php')
  }

} 
