import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdxAppOverviewComponent } from './adx-app-overview.component';



@Injectable()
export class AdxAppOverviewService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_app/cyberads-for-app-overview.php";

  constructor(private http:HttpClient) { }

  getAdxappoverview(daterange,acc_name,isdemo,uniq_id): Observable<AdxAppOverviewComponent[]>{
    return this.http.get<AdxAppOverviewComponent[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&isdemo="+isdemo+"&uniq_id="+uniq_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}