import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { GamVideoGeoService } from './gam-video-geoService';
declare var $: any;
import * as moment from 'moment';
declare var swal: any;

@Component({
  selector: 'gam-video-geo',
  templateUrl: './gam-video-geo.component.html',
  styleUrls: ['./gam-video-geo.component.css']
})
export class GamVideoGeoComponent implements OnInit {
  piechart;
  public tabledata = [];
  public graphdata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  public lvl2data = [];
  public lvl2data1 = [];
  public lvl2data2 = [];
  public lvl2data3 = [];
  public lvl2data4 = [];
  public lvl2data5 = [];
  public lvl2data6 = [];
  public lvl2data7 = [];
  public lvl2data8 = [];
  public lvl2data9 = [];
  public lvl2data10 = [];
  public lvl1data1 = [];
  public lvl1data2 = [];
  public lvl1data3 = [];
  public lvl1data4 = [];
  public lvl1data5 = [];
  public lvl1data6 = [];
  public graphdatelvl3 = [];
	public show:boolean = false;
  public showcan:boolean = false;
  public showuk:boolean = false;
  public showaus:boolean = false;
  public showger:boolean = false;
	public showind:boolean = false;
	public showfr:boolean = false;
  public showbel:boolean = false;
  public shownet:boolean = false;
  public shownz:boolean = false;
  public classnamelvl1 :any = 'fa fa-plus show-span';
  public classlvl1 :any = 'fa fa-plus show-span';
  public classnamevidlvl1 :any = 'fa fa-plus show-span';
  public classnametxtlvl1 :any = 'fa fa-plus show-span';
  public classnameunmlvl1 :any = 'fa fa-plus show-span';
  public classnameanimglvl1 :any = 'fa fa-plus show-span';
  public loading: boolean = true;

  getpiechart(x,y,z)
    {
		this.piechart = new Chart({
			chart: {
        events: {  
        drilldown: function(e) {
          // console.log(e.seriesOptions.name);
           $("table").hide();
           $("."+e.seriesOptions.name).show();
        },
        drillup: function(e) {
          // console.log(this.series[0].name);
          // console.log(this.series[0].name.split('By')[0]);      
          if(this.series[0].name==this.series[0].name.split('-')[0])
          {
            $("table").hide();
            $("#maintable").show();
          }
          else
          {
            $("table").hide();
            $("."+this.series[0].name.split('By')[0]).show();
          }
           
         }
    },
	   plotBackgroundColor: null,
	   plotBorderWidth: null,
	   plotShadow: false,
	   type: 'pie'
   },
   title: {
	   text: 'CyberAds Revenue For GAM360 Video - Geo'
   },
   xAxis: {
    categories: z.dates
      },
   yAxis: {
	showEmpty: false,
    title: {
    
      text: 'Estimated Revenue ($)'
    },
    labels: {
      enabled: true
  }
  
    },
   exporting: { enabled: false },
   credits: {
    enabled: false
  },
   colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
   '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

   tooltip: {
	   pointFormat: '{series.name}: <b>${point.y}</b>'
   },
   plotOptions: {
	   pie: {
		   allowPointSelect: true,
		   cursor: 'pointer',
		   dataLabels: {
			   enabled: true,
			   format: '<b>{point.name}</b>: ${point.y}',
			   
		   }, showInLegend: true,
	   }
   },
   series: [
    {
      name: "Geo",
      
      data: x,
    }
    ],
    drilldown: {
		drillUpButton: {
			position: {
				x: 0,
				y: -35,
			}
		  },
      series: y
      }
     }); 
     function init_daterangepicker() {
			if ("undefined" != typeof $.fn.daterangepicker) {
				console.log("init_daterangepicker");
				var a = function(a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
					b = {
						startDate: moment().subtract(7, "days"),
						endDate: moment(),
						// minDate: moment().subtract(3, "month"),
						minDate: new Date('2019-08-01'),
						maxDate: moment(),
						dateLimit: {
							days: 90
						},
						showDropdowns: !0,
						showWeekNumbers: !0,
						timePicker: !1,
						timePickerIncrement: 1,
						timePicker12Hour: !0,
						ranges: {
							Today: [moment(), moment()],
							Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							"Last 7 Days": [moment().subtract(6, "days"), moment()],
							"Last 30 Days": [moment().subtract(29, "days"), moment()],
							"This Month": [moment().startOf("month"), moment()],
							"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
						},
						opens: "left",
						buttonClasses: ["btn bg-pink"],
						applyClass: "btn-small btn-primary",
						cancelClass: "btn-small",
						format: "MM/DD/YYYY",
						separator: " to ",
						locale: {
							applyLabel: "Submit",
							cancelLabel: "Clear",
							fromLabel: "From",
							toLabel: "To",
							customRangeLabel: "Custom",
							daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							firstDay: 1
						}
					};
				$(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
					console.log("show event fired")
				}), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
					console.log("hide event fired")
				}), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
											
		let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
		$("#dateinput").val(strtdate);
		let startdate=b.startDate.format("MMMM D, YYYY");
		let enddate=b.endDate.format("MMMM D, YYYY");
		localStorage.setItem('strtdate', strtdate);
		localStorage.setItem('startdate', enddate);
		localStorage.setItem('enddate', startdate);
		window.location.reload();
		
	
					console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				}), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
					console.log("cancel event fired")
				}), $("#options1").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				}), $("#options2").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
				}), $("#destroy").click(function() {
					$(".custom-daterangepicker").data("daterangepicker").remove()
				})
			}
			
		}
	
		function init_daterangepicker_right() {
			if ("undefined" != typeof $.fn.daterangepicker) {
				console.log("init_daterangepicker_right");
				var a = function(a, b, c) {
						console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
					},
					b = {
						startDate: moment().subtract(6, "days"),
						endDate: moment(),
						// minDate: moment().subtract(3, "month"),
						minDate: new Date('2019-08-01'),
						maxDate: moment(),
						dateLimit: {
							days: 90
						},
						showDropdowns: !0,
						showWeekNumbers: !0,
						timePicker: !1,
						timePickerIncrement: 1,
						timePicker12Hour: !0,
						ranges: {
							Today: [moment(), moment()],
							Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
							"Last 7 Days": [moment().subtract(6, "days"), moment()],
							"Last 30 Days": [moment().subtract(29, "days"), moment()],
							"This Month": [moment().startOf("month"), moment()],
							"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
						},
						opens: "right",
						buttonClasses: ["btn btn-default"],
						applyClass: "btn-small btn-primary",
						cancelClass: "btn-small",
						format: "MM/DD/YYYY",
						separator: " to ",
						locale: {
							applyLabel: "Submit",
							cancelLabel: "Clear",
							fromLabel: "From",
							toLabel: "To",
							customRangeLabel: "Custom",
							daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
							monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
							firstDay: 1
						}
					};
				$("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
					console.log("show event fired")
				}), $("#reportrange_right").on("hide.daterangepicker", function() {
					console.log("hide event fired")
				}), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
					console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
				}), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
					console.log("cancel event fired")
				}), $("#options1").click(function() {
					$("#reportrange_right").data("daterangepicker").setOptions(b, a)
				}), $("#options2").click(function() {
					$("#reportrange_right").data("daterangepicker").setOptions(b, a)
				}), $("#destroy").click(function() {
					$("#reportrange_right").data("daterangepicker").remove()
				})
			}
		}
	
		function init_daterangepicker_single_call() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_1"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal2").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_2"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal3").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_3"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#single_cal4").daterangepicker({
				singleDatePicker: !0,
				singleClasses: "picker_4"
			}, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}))
		}
	
		function init_daterangepicker_reservation() {
			"undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
				console.log(a.toISOString(), b.toISOString(), c)
			}), $("#reservation-time").daterangepicker({
				timePicker: !0,
				timePickerIncrement: 30,
				locale: {
					format: "MM/DD/YYYY h:mm A"
				}
			}))
		}
	
		function init_SmartWizard() {
			"undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
				transitionEffect: "slide"
			}), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
		}
	
	
	
	
		var originalLeave = $.fn.popover.Constructor.prototype.leave;
		$.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
			init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
		});  
    }
	constructor(private _gamvidgeoService:GamVideoGeoService) { }

	ngOnInit() {

		this._gamvidgeoService.getVidGeo(localStorage.getItem('strtdate'),localStorage.getItem('child_net_code'))
		.subscribe(data =>{
			this.loading = false;
			if(data['finaldata']==null){
				swal.fire({
					title: "Empty Data",
					text: "No Data For Selected Date Range!",
					icon: "warning",
					button: "Ok",
				});
			}
			this.graphdata = data['level1'];
			this.lvl1data1 = data['finaldata'];
			this.tabledata = data["adtype_table_data"];
			this.lvl2data = data["adtype_table_data_level1"]["0"];
			this.lvl2data1 = data["adtype_table_data_level1"]["1"];
			this.lvl2data2 = data["adtype_table_data_level1"]["2"];
			this.lvl2data3 = data["adtype_table_data_level1"]["3"];
			this.lvl2data4  = data["adtype_table_data_level1"]["4"];
			this.lvl2data5  = data["adtype_table_data_level1"]["5"];
			this.lvl2data6 = data["adtype_table_data_level1"]["6"];
			this.lvl2data7 = data["adtype_table_data_level1"]["7"];
			this.lvl2data8 = data["adtype_table_data_level1"]["8"];
			this.lvl2data9 = data["adtype_table_data_level1"]["9"];
			this.lvl2data10 = data["adtype_table_data_level1"]["10"];
			this.graphdatelvl3 = data["level3_dates"];
			this.daterange = data["adtype_date_range"];
			this.sumupdata = data['Sum_table_data'];
			this.getpiechart(this.graphdata,this.lvl1data1,this.graphdatelvl3);
	  },
		error => this.errorMsg = error);
		
	}

	downloadadxvideogeo() { 
		// if(localStorage.getItem('usertype')=='2'){
		// 	document.location.href='https://angular.cybermediaservices.net/assets/web_api/download-adx-video-geo_networks.php?'+localStorage.getItem("strtdate")+"&acc_mgr_id="+localStorage.getItem("acc_mgr_id")+'&acc_name='+localStorage.getItem("acc_name")+'&new_acc_name='+localStorage.getItem("old_acc_name");
		// }else{
			document.location.href='https://angular.cybermediaservices.net/assets/web_api/video_gam/download-gam-video-geo.php?'+localStorage.getItem("strtdate")+'&child_net_code='+localStorage.getItem("child_net_code");
		//}
		
	
	  }

	toggle(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  togglelvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  togglelvl(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleimglvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  togglevidlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggletxtlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleanimglvl1(a) {
   $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
  toggleunmlvl1(a) {
    $("."+a).toggle();
    if ($(".show-span"+a).hasClass("fa fa-plus")) {
      $(".show-span"+a).removeClass('fa fa-plus');
      $(".show-span"+a).addClass('fa fa-minus show-span'+a);
    }else{
      $(".show-span"+a).removeClass('fa fa-minus');
      $(".show-span"+a).addClass('fa fa-plus show-span'+a);
    }
  }
}
