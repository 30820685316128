import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EditUserDetailService {
  edit_user_detail: string = "https://angular.cybermediaservices.net/assets/web_api/sub_user/edit_user_details.php";

  constructor(private http:HttpClient) {}
  public save_edituser(data){
    return this.http.post(this.edit_user_detail,data);
  }
  ​
  ​ }