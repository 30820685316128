import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SaveUserService {
  save_userdata: string = "https://angular.cybermediaservices.net/assets/web_api/sub_user/save_user.php";

  constructor(private httpClient: HttpClient) { }
​
//for CSR query form Submission
public userdata(data,uniqid){
  return this.httpClient.post(this.save_userdata+'?uniqid='+uniqid,data);
}
​
}