import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { InvoicedataService } from './invoicedata.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  public monthyear;
  public errorMsg;
  public final_rev;
  public can;
  public cap;
  public adx_deals;
  public invalid_rev = [];
  public conv_rate;
  public month = [];
  public invoice_date = [];
  public acc_mgr_id = [];
  public policy_disabled;
  public status;
  public disable:boolean = false;
  public stamp:boolean = false;
  public invoice_number;
  public pub_adx_partner_id;
  public trimmed_pub_adx_partner_id;
  public child_net_code;
  public accountHolderName = [];
  public accountHolderAddress;
  public city;
  public state;
  public postal_code;
  public bankName = [];
  public accountNumber = [];
  public ifsc = [];
  public pannumber = [];
  public gstnumber;
  public gstaddress;
  public gstcity;
  public gststate;
  public gstpostal;
  public accountType = [];
  public bank_address = [];
  public bank_city = [];
  public bank_state;
  public bank_postal = [];
  public delhi_state:boolean = false;
  public gst_flag:boolean = false;
  public ntwktbl:boolean = false;
  // public isLoading:boolean = false;
  public final_rev_number;
  public decimal_number;
  public swift_code;
  public country_type;
  model: any = {};

  apiURL: string = '';
  downloadURL: string = '';

  constructor(private httpClient: HttpClient,private _invoicedataService:InvoicedataService,private router: ActivatedRoute, private routes: Router) { }
  // click() {
  //   this.isLoading = true;
  //   setTimeout(() => {
  //     this.isLoading = false;
  //   }, 9000)
  // }
  ngOnInit() {
    this.monthyear = this.router.snapshot.paramMap.get('m');
    if(localStorage.getItem('usertype')=='2'){
      this.ntwktbl=true;
      this.apiURL="https://angular.cybermediaservices.net/assets/web_api/invoice/network_sendmail.php";
      this.downloadURL ="https://angular.cybermediaservices.net/assets/web_api/invoice/download_ntkinv.php";
    }else{
      this.ntwktbl=false;
       this.apiURL="https://angular.cybermediaservices.net/assets/web_api/invoice/sendmail.php";
        // this.apiURL="https://angular.cybermediaservices.net/assets/web_api/invoice/sendmail_test.php";
        this.downloadURL ="https://angular.cybermediaservices.net/assets/web_api/invoice/download_inv.php";
     
    }
    this._invoicedataService.getInvoicedata(localStorage.getItem('adx_id'),localStorage.getItem('acc_mgr_id'),localStorage.getItem('acc_name'),localStorage.getItem('uniq_id'),this.monthyear,localStorage.getItem('old_acc_name'))
    .subscribe(data => {
      this.final_rev = parseFloat(data['invoice']['CAN'])+parseFloat(data['invoice']['CAP'])+parseFloat(data['invoice']['adx_deals_pub_rev']);
      this.NumInWords(parseFloat(data['invoice']['CAN'])+parseFloat(data['invoice']['adx_deals_pub_rev'])+parseFloat(data['invoice']['CAP'])-parseFloat(data['invoice']['invalid_rev']));
      this.can = data['invoice']['CAN'];
      this.cap = data['invoice']['CAP'];
      this.adx_deals = data['invoice']['adx_deals_pub_rev'];
      this.invalid_rev = data['invoice']['invalid_rev'];
      this.conv_rate = data['invoice']['bcr_rate'];
      this.month = data['invoice']['month'];
      this.invoice_date = data['invoice']['invoice_date'];
      this.acc_mgr_id = data['invoice']['acc_mgr_id'];
      this.policy_disabled = data['invoice']['policy_disabled'];
      this.status = data['invoice']['status'];
      this.pub_adx_partner_id = data['bank']['pub_adx_partner_id'];
      this.child_net_code = data['bank']['child_net_code'];
      if(this.pub_adx_partner_id != ''){
          this.trimmed_pub_adx_partner_id = this.pub_adx_partner_id.replace("adx-subsyn-","");
          this.invoice_number = this.trimmed_pub_adx_partner_id.substr(this.trimmed_pub_adx_partner_id.length -5)+"/CMS/"+data['invoice']['invoice_number'];
      }else{
          this.invoice_number = this.child_net_code.substr(this.child_net_code.length -7)+"/CMS/"+data['invoice']['invoice_number'];
      }
      
      this.accountHolderName = data['bank']['accountHolderName'];
      this.accountHolderAddress = data['bank']['accountHolderAddress'];
      this.city = data['bank']['city'];
      this.state = data['bank']['state'];
      this.postal_code = data['bank']['postal_code'];
      this.bankName = data['bank']['bankName'];
      this.accountNumber = data['bank']['accountNumber'];
      this.ifsc = data['bank']['ifsc'];
      this.pannumber = data['bank']['pan_number'];
      this.gstnumber = data['bank']['gst_number'];
      this.gstaddress = data['bank']['gst_address'];
      this.gstcity = data['bank']['gst_city'];
      this.gststate = data['bank']['gst_state'];
      this.gstpostal = data['bank']['gst_postal'];
      this.accountType = data['bank']['acountType'];
      this.bank_address = data['bank']['bank_address'];
      this.bank_city = data['bank']['bank_city'];
      this.bank_state = data['bank']['bank_state'];
      this.bank_postal = data['bank']['bank_postal'];
      this.swift_code = data['bank']['swift_code'];
      this.country_type = data['bank']['country_type'];
      if(this.country_type == 'international'){
        this.ntwktbl=true;
        // console.log("check sandy =>"+this.country_type);
      }
      if(this.policy_disabled == 'Y' || this.status == 'Payment Under Process' || this.status == 'Paid'){
        this.disable = true;
      }
      if(this.status == 'Paid'){
        this.stamp = true;
      }
      if(this.gststate == 'Delhi'){
        this.delhi_state = true;
      }
     
      if(this.gstnumber != '' && this.gstnumber.toString().length > 10){
        this.gst_flag = true;
      }
      else{
        this.gst_flag = false;
        this.gstnumber ="N/A";
      }
      if(localStorage.getItem('usertype')=='2'){
        this.accountHolderName = [localStorage.getItem('userName')];
      }
    },
    error => this.errorMsg = error);
    
  }

  
  approval(data) {
    data['final_rev'] = this.final_rev;
    data['CAN'] = this.can;
    data['CAP'] = this.cap;
    data['invalid_rev'] = this.invalid_rev;
    data['conv_rate'] = this.conv_rate;
    data['month'] = this.month;
    data['invoice_date'] = this.invoice_date;
    data['acc_mgr_id'] = this.acc_mgr_id;
    data['policy_disabled'] = this.policy_disabled;
    data['pub_adx_partner_id'] = this.pub_adx_partner_id;
    data['invoice_number'] = this.invoice_number;
    if(localStorage.getItem('usertype')=='2'){
      data['accountHolderName'] = localStorage.getItem('userName');
      data['publisher_name'] = localStorage.getItem('userName');
    }else{
      data['accountHolderName'] = this.accountHolderName;
      data['publisher_name'] = localStorage.getItem('acc_name');
      data['new_publisher_name'] = localStorage.getItem('old_acc_name');
      data['uniq_id'] = localStorage.getItem('uniq_id');
    }
    data['accountHolderAddress'] = this.accountHolderAddress;
    data['city'] = this.city;
    data['state'] = this.state;
    data['postal_code'] = this.postal_code;
    data['bankName'] = this.bankName;
    data['accountNumber'] = this.accountNumber;
    data['ifsc'] = this.ifsc;
    data['pannumber'] = this.pannumber;
    data['gstnumber'] = this.gstnumber;
    data['gstaddress'] = this.gstaddress;
    data['gstcity'] = this.gstcity;
    data['gststate'] = this.gststate;
    data['gstpostal'] = this.gstpostal;
    data['accountType'] = this.accountType;
    data['bank_address'] = this.bank_address;
    data['bank_city'] = this.bank_city;
    data['bank_state'] = this.bank_state;
    data['bank_postal'] = this.bank_postal;
    data['swift_code'] = this.swift_code;
    data['country_type'] = this.country_type;
    
    

    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));

    this.httpClient.post(this.apiURL+'?q=newinvoice',formData).subscribe((response)=>{
      if(response){
        alert("Thank you for approving the invoice. Our team will process your payment.");
        // this.routes.navigate(['/list-invoice']);
        location.reload(); 
      }
      else{
        alert("Error Occur Try Again");
        // this.router.navigate(['/dashboard']);
      }
      
    })
    // new Response(formData).text().then(console.log);
  }


  NumInWords (number1) {
    
    const  number2 = parseFloat(number1).toFixed(2).split(".");
    const number = parseInt(number2[0]);
    const digit = parseInt(number2[1]);
    // alert(digit);
    const first = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    const tens = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
    const mad = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
    let word = '';
    let decimal = '';
  
    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number%(100*Math.pow(1000,i));
      if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
        if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
          word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + ' ' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
        }
      }
  
      tempNumber = number%(Math.pow(1000,i+1));
      if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'Hundred ' + word;
    }
    this.final_rev_number =  word;

    if(digit && digit > 0){
      for (let i = 0; i < mad.length; i++) {
        let tempNumber = digit%(100*Math.pow(1000,i));
        if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
          if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
            decimal = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + decimal;
          } else {
            decimal = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + ' ' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + decimal;
          }
        }
    
        tempNumber = digit%(Math.pow(1000,i+1));
        if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) decimal = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'Hundred ' + decimal;
      }
      this.decimal_number =  decimal;
    }else{
      this.decimal_number =  false;
    }
  }

  downloadInv(data) {
    data['final_rev'] = this.final_rev;
    data['CAN'] = this.can;
    data['CAP'] = this.cap;
    data['adx_deals'] =this.adx_deals;
    data['invalid_rev'] = this.invalid_rev;
    data['conv_rate'] = this.conv_rate;
    data['month'] = this.month;
    data['invoice_date'] = this.invoice_date;
    data['acc_mgr_id'] = this.acc_mgr_id;
    data['policy_disabled'] = this.policy_disabled;
    data['pub_adx_partner_id'] = this.pub_adx_partner_id;
    data['invoice_number'] = this.invoice_number;
    if(localStorage.getItem('usertype')=='2'){
      data['accountHolderName'] = localStorage.getItem('userName');
    }else{
      data['accountHolderName'] = this.accountHolderName;
    }
    data['accountHolderAddress'] = this.accountHolderAddress;
    data['city'] = this.city;
    data['state'] = this.state;
    data['postal_code'] = this.postal_code;
    data['bankName'] = this.bankName;
    data['accountNumber'] = this.accountNumber;
    data['ifsc'] = this.ifsc;
    data['pannumber'] = this.pannumber;
    data['gstnumber'] = this.gstnumber;
    data['gstaddress'] = this.gstaddress;
    data['gstcity'] = this.gstcity;
    data['gststate'] = this.gststate;
    data['gstpostal'] = this.gstpostal;
    data['accountType'] = this.accountType;
    data['bank_address'] = this.bank_address;
    data['bank_city'] = this.bank_city;
    data['bank_state'] = this.bank_state;
    data['bank_postal'] = this.bank_postal;
    data['swift_code'] = this.swift_code;
    data['country_type'] = this.country_type;

    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));

    this.httpClient.post(this.downloadURL+'?q=newinvoice',formData).subscribe((response)=>{
      if(response){
          data.stopPropagation();
          if(response['network']){
            window.open(
              'https://angular.cybermediaservices.net/downloads.php?filename='+response['filename']+'&network=netwk',
              '_blank' 
            );
          }else{
            window.open(
              'https://angular.cybermediaservices.net/downloads.php?filename='+response['filename'],
              '_blank' 
            );
          }
      }
      else{
        alert("Error Occur Try Again");
      }
      
    })
  }

}
