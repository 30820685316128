import { Component, OnInit } from '@angular/core';
declare var $: any;
import { InsightdataService } from './insightdata.service';
import * as moment from 'moment';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})

export class InsightsComponent implements OnInit {

  public loading: boolean = true;
  public daterange;
  public errorMsg;
  public counttype;
  public newvisitor = [];
  public returnvisitor = [];
  public loyalvisitor = [];
  public n_format;
  public suffix;
  public dotzero;
  public newuser;
  public newpageview;
  public returnuser;
  public returnpageview;
  public loyaluser;
  public loyalpageview;
  public toplandingpage = [];
  public usertype = [];
  public mediumwise = [];
  setheightpage()
   {
    $(document).ready(function(){
      var page_height='';
      $(".page-content").css("height", page_height);
    });
   }
  constructor(private _insightservice:InsightdataService) { }

  ngOnInit() {
    this._insightservice.getinsightdata(localStorage.getItem('anlytic_id'),localStorage.getItem('uniq_id'))
    .subscribe(data => {
      this.loading = false;
      this.daterange = data['daterange'];
      this.usertype = data['userinarray'];

      // for(this.counttype=0;this.counttype<=2;this.counttype++) {
      //     if(this.usertype[this.counttype]['type'] == 'Casual') {
      //       this.newuser = this.CurrencyFormat(this.usertype[this.counttype]['user']);
      //       this.newpageview = this.CurrencyFormat(this.usertype[this.counttype]['pageviews']);
      //     }
      //     else if(this.usertype[this.counttype]['type'] == 'Returning') {
      //       this.returnuser = this.CurrencyFormat(this.usertype[this.counttype]['user']);
      //       this.returnpageview = this.CurrencyFormat(this.usertype[this.counttype]['pageviews']);
      //     }
      //     else if(this.usertype[this.counttype]['type'] == 'Loyal') {
      //       this.loyaluser = this.CurrencyFormat(this.usertype[this.counttype]['user']);
      //       this.loyalpageview = this.CurrencyFormat(this.usertype[this.counttype]['pageviews']);
      //     }
      // }
      
      this.newvisitor = data['userinarray']['New Visitor'];
      this.returnvisitor = data['userinarray']['Returning Visitor'];
      this.loyalvisitor = data['userinarray']['Loyal Visitor'];
      this.newuser = this.CurrencyFormat(data['userinarray']['New Visitor']['user']);
      this.newpageview = this.CurrencyFormat(data['userinarray']['New Visitor']['pageviews']);
      this.returnuser = this.CurrencyFormat(data['userinarray']['Returning Visitor']['user']);
      this.returnpageview = this.CurrencyFormat(data['userinarray']['Returning Visitor']['pageviews']);
      this.loyaluser = this.CurrencyFormat(data['userinarray']['Loyal Visitor']['user']);
      this.loyalpageview = this.CurrencyFormat(data['userinarray']['Loyal Visitor']['pageviews']);
      this.toplandingpage = data['top_landingpage'];
      this.mediumwise = data['mediumwise'];
      // console.log(this.daterange);
      this.setheightpage()},
    error => this.errorMsg = error);
  }
  CurrencyFormat( n, precision = 1 ) {
    if(n>0){
      if (n < 900) {
        // 0 - 900
        this.n_format = n.toFixed(precision);
        this.suffix = '';
      } else if (n < 900000) {
        // 0.9k-850k
        this.n_format = (n/1000).toFixed(precision);
        this.suffix = 'K';
      } else if (n < 900000000) {
        // 0.9m-850m
        this.n_format = (n/ 1000000).toFixed(precision);
        this.suffix = 'M';
      } else if (n < 900000000000) {
        // 0.9b-850b
        this.n_format = (n / 1000000000).toFixed(precision);
        this.suffix = 'B';
      } else {
        // 0.9t+
        this.n_format = (n / 1000000000000).toFixed(precision);
        this.suffix = 'T';
      }
    }
    else{
      this.n_format =0;
      return this.n_format;
    }
    // Remove unecessary zeroes after decimal. "1.0" -> "1"; "1.00" -> "1"
    // Intentionally does not affect partials, eg "1.50" -> "1.50"
    if ( precision > 0 ) {
      var str = '0';
      this.dotzero = '.' + str.repeat(precision );
      this.n_format = this.n_format.replace(this.dotzero,'' );
    }
    return this.n_format + this.suffix;
  }

  togglemedium(a) {
    $('div').find('.tab-pane').removeClass('active');
    $('.mediums').removeClass('active');
    $('#'+a).find('.tab-pane').addClass('active');
    $('#'+a+'first').addClass('active');
  }
}
