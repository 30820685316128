import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { notify } from './notify';
import { tap, catchError } from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeftsidebarService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/left_sidebar.php";

  constructor(private http:HttpClient) { }

  getmenustatus(daterange,adxname,new_adx_name,uniq_id,isdemo,child_net_code): Observable<notify[]>{
    return this.http.get<notify[]>(this._url+ "?"+daterange+"&acc_name="+adxname+"&new_acc_name="+new_adx_name+"&uniq_id="+uniq_id+"&isdemo="+isdemo+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}