import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NetworkbreakupdataService } from './networkbreakup.service';

@Component({
  selector: 'view-breakup',
  templateUrl: './network-breakup.component.html',
  styleUrls: ['./network-breakup.component.css']
})
export class NetworkBreakupComponent implements OnInit {
  public monthyear;
  public errorMsg;
  public breakupList = [];
  public totData = [];

  constructor(private _networkbreakupdataService:NetworkbreakupdataService,private router: ActivatedRoute, private routes: Router) { }

  ngOnInit() {
    this.monthyear = this.router.snapshot.paramMap.get('m');
    this._networkbreakupdataService.getBreakupdata(localStorage.getItem('uniq_id'),localStorage.getItem('acc_mgr_id'),this.monthyear,localStorage.getItem("access_token"),localStorage.getItem("useremail"))
    .subscribe(data => {
      this.breakupList = data['table_data'];
      this.totData = data['totdata'];
    },
      error => this.errorMsg = error);
      // $('#newtable').DataTable().ajax.reload();
  }

}
