import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { videosite } from './gam-video-site';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamVideoSiteService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/video_gam/gam_video_site.php";

  constructor(private http:HttpClient) { }

  getVideoSite(daterange,child_net_code): Observable<videosite[]>{
    return this.http.get<videosite[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}