import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { geo } from './adx-geo';

@Injectable({
  providedIn: 'root'
})
export class CheckUserServiceService {
  chkusername: string = "https://angular.cybermediaservices.net/assets/web_api/sub_user/check_user.php";
  chkmailid: string = "https://angular.cybermediaservices.net/assets/web_api/sub_user/check_mail.php";
  constructor(private http:HttpClient) { }
​

chkuser(acc_name): Observable<geo[]>{
  return this.http.get<geo[]>(this.chkusername+ "?acc_name="+acc_name)
}
chkmail(mail): Observable<geo[]>{
  return this.http.get<geo[]>(this.chkmailid+ "?mail="+mail)
}
errorHandler(error: HttpErrorResponse){
  return observableThrowError(error.message || "Server Error");
}
​
}