import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { geo } from './adx-geo';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PioecharttotaalService {
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads/cyberads_country_pie.php";

  constructor(private http:HttpClient) { }

  getadspiechart(daterange,acc_name,uniq_id,new_acc_name): Observable<geo[]>{
    return this.http.get<geo[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&new_acc_name="+new_acc_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}