import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AdxVidAdunitService } from './adx-vid-adunitService';

@Component({
  selector: 'app-adx-video-adunit',
  templateUrl: './adx-video-adunit.component.html',
  styleUrls: ['./adx-video-adunit.component.css']
})
export class AdxVideoAdunitComponent implements OnInit {
	piechart;
  public ustabledata = [];
	public usinnertabledata = [];
	public catabledata = [];
	public cainnertabledata = [];
	public uktabledata = [];
	public ukinnertabledata = [];
	public austabledata = [];
	public ausinnertabledata = [];
	public gertabledata = [];
	public gerinnertabledata = [];
	public indtabledata = [];
	public indinnertabledata = [];
	public frtabledata = [];
	public frinnertabledata = [];
	public beltabledata = [];
	public belinnertabledata = [];
	public nethrtabledata = [];
	public nethrinnertabledata = [];
	public nztabledata = [];
  public nzinnertabledata = [];
  public othtabledata = [];
  public othinnertabledata = [];
	public show:boolean = false;
  public showcan:boolean = false;
  public showuk:boolean = false;
  public showaus:boolean = false;
  public showger:boolean = false;
	public showind:boolean = false;
	public showfr:boolean = false;
  public showbel:boolean = false;
  public shownet:boolean = false;
  public shownz:boolean = false;
  public showoth:boolean = false;
  public classname :any = 'fa fa-plus';
  public classnamecan :any = 'fa fa-plus';
  public classnameuk :any = 'fa fa-plus';
  public classnameaus :any = 'fa fa-plus';
  public classnameger :any = 'fa fa-plus';
	public classnameind :any = 'fa fa-plus';
	public classnamefr :any = 'fa fa-plus';
  public classnamebel :any = 'fa fa-plus';
  public classnamenet :any = 'fa fa-plus';
  public classnamenz :any = 'fa fa-plus';
  public classnameoth :any = 'fa fa-plus';
  public graphdata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  getpiechart(x)
    {
		this.piechart = new Chart({
			chart: {
	   plotBackgroundColor: null,
	   plotBorderWidth: null,
	   plotShadow: false,
	   type: 'pie'
   },
   title: {
	   text: 'Adx For AdUnit Overview'
   },
   colors: ['#0182c3', '#e91d63', '#4f5d68', '#ff9f00', '#00a698',
   '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

   tooltip: {
	   pointFormat: '{series.name}: <b>${point.y}</b>'
   },
   yAxis:{
    showEmpty: false,
   },
   plotOptions: {
	   pie: {
		   allowPointSelect: true,
		   cursor: 'pointer',
		   dataLabels: {
			   enabled: true,
			   format: '<b>{point.name}</b>: ${point.y}',
			   
		   }, showInLegend: true,
	   }
   },
   series: [{
	   name: 'Revenue',
	  
	   data: x,
   }]
	   }); 
    }
	constructor(private _adxadunitService:AdxVidAdunitService) { }

	ngOnInit() {

		this._adxadunitService.getAdunit(localStorage.getItem('isdemo'))
		.subscribe(data => {this.ustabledata = data["adunit_table_data"]["0"],
		this.usinnertabledata = data["adunit_innertable_data"]["0"],
		this.catabledata = data["adunit_table_data"]["1"],
		this.cainnertabledata = data["adunit_innertable_data"]["1"],
		this.uktabledata = data["adunit_table_data"]["2"],
		this.ukinnertabledata = data["adunit_innertable_data"]["2"],
		this.austabledata = data["adunit_table_data"]["3"],
		this.ausinnertabledata = data["adunit_innertable_data"]["3"],
		this.gertabledata = data["adunit_table_data"]["4"],
		this.gerinnertabledata = data["adunit_innertable_data"]["4"],
		this.indtabledata = data["adunit_table_data"]["5"],
		this.indinnertabledata = data["adunit_innertable_data"]["5"],
		this.frtabledata = data["adunit_table_data"]["6"],
		this.frinnertabledata = data["adunit_innertable_data"]["6"],
		this.beltabledata = data["adunit_table_data"]["7"],
		this.belinnertabledata = data["adunit_innertable_data"]["7"],
		this.nethrtabledata = data["adunit_table_data"]["8"],
		this.nethrinnertabledata = data["adunit_innertable_data"]["8"],
		this.nztabledata = data["adunit_table_data"]["9"],
    this.nzinnertabledata = data["adunit_innertable_data"]["9"],
    this.othtabledata = data["adunit_table_data"]["10"],
    this.othinnertabledata = data["adunit_innertable_data"]["10"],
    this.graphdata = data["adunit_graph_revenue"],this.daterange = data["adtype_date_range"],this.sumupdata = data["sum_table_data"];
	  this.getpiechart(this.graphdata);
	  },
		error => this.errorMsg = error);
		
	}
	toggle() {
    this.show = !this.show;
    if(this.show)  
      this.classname = "fa fa-minus";
    else
      this.classname = "fa fa-plus";
  }
  togglecan() {
    this.showcan = !this.showcan;
    if(this.showcan)  
      this.classnamecan = "fa fa-minus";
    else
      this.classnamecan = "fa fa-plus";
  }
  toggleuk() {
    this.showuk = !this.showuk;
    if(this.showuk)  
      this.classnameuk = "fa fa-minus";
    else
      this.classnameuk = "fa fa-plus";
  }
  toggleaus() {
    this.showaus = !this.showaus;
    if(this.showaus)  
      this.classnameaus = "fa fa-minus";
    else
      this.classnameaus = "fa fa-plus";
  }
  toggleanger() {
    this.showger = !this.showger;
    if(this.showger)  
      this.classnameger = "fa fa-minus";
    else
      this.classnameger = "fa fa-plus";
  }
  toggleind() {
    this.showind = !this.showind;
    if(this.showind)  
      this.classnameind = "fa fa-minus";
    else
      this.classnameind = "fa fa-plus";
	}
	togglefr() {
    this.showfr = !this.showfr;
    if(this.showfr)  
      this.classnamefr = "fa fa-minus";
    else
      this.classnamefr = "fa fa-plus";
  }
  togglebel() {
    this.showbel = !this.showbel;
    if(this.showbel)  
      this.classnamebel = "fa fa-minus";
    else
      this.classnamebel = "fa fa-plus";
  }
  togglenet() {
    this.shownet = !this.shownet;
    if(this.shownet)  
      this.classnamenet = "fa fa-minus";
    else
      this.classnamenet = "fa fa-plus";
  }
  togglenz() {
    this.shownz = !this.shownz;
    if(this.shownz)  
      this.classnamenz = "fa fa-minus";
    else
      this.classnamenz = "fa fa-plus";
  }
  toggleoth() {
    this.showoth = !this.showoth;
    if(this.showoth)  
      this.classnameoth = "fa fa-minus";
    else
      this.classnameoth = "fa fa-plus";
  }
}
