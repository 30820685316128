import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';

interface invoicedata {
  month: string,
  pub_name:string,
  earnings: string,
  invalid: string,
  final_rev: string
  
}


@Injectable({
  providedIn: 'root'
})
export class NetworkbreakupdataService {
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      // this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/network_breakup.php";
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/network_breakup_token.php";
      
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/invoice/breakup.php";
    }
  }

  getBreakupdata(uniqid,acc_mgr_id,monthyear,access_token,email): Observable<invoicedata[]>{
    return this.http.get<invoicedata[]>(this._url+ "?&uniq_id="+uniqid+"&acc_mgr_id="+acc_mgr_id+"&monthyear="+monthyear+"&access_token="+access_token+"&email="+email)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
