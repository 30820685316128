import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdsOverviewComponent } from './ads-overview.component';


@Injectable({
  providedIn: 'root'
})
export class AdsOverviewService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Adsense_api/ads_overview.php";

  constructor(private http:HttpClient) { }

  getAdsoverview(daterange,acc_name,website,ads_id): Observable<AdsOverviewComponent[]>{
    return this.http.get<AdsOverviewComponent[]>(this._url+ "?"+daterange+"&uniq_id="+acc_name+"&website="+website+"&ads_id="+ads_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error"); 
  }
  
}