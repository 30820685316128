import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';

interface profiledata {
  pub_id: string,
  pub_uniq_id:string,
  pub_name: string,
  pub_acc_name: string,
  pub_acc_new_name: string,
  pub_email: string,
  pub_adx_partner_id:string,
  pub_adx_new_partner_id:string,
  pub_adsense_partner_id: string,
  pub_analytic_id: string,
  cmda_id: string,
  f_name:string,
  l_name:string,
  username:string,
  pub_address:string,
  mob:string,
  pub_company:string,
  pub_afc_share:string,
  pub_afv_share:string
}


@Injectable({
  providedIn: 'root'
})
export class ViewprofiledataService {
  public usertype: any;
  private _url: string = "";
  save_userdata: string = "https://angular.cybermediaservices.net/assets/web_api/request_query.php";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/network_viewprofile.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/network_viewprofile.php";
    }
  }

  getprofiledata(email): Observable<profiledata[]>{
    return this.http.get<profiledata[]>(this._url+ "?&email="+email)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  getprofilesdata(email): Observable<profiledata[]>{
    return this.http.get<profiledata[]>(this._url+ "?&network_email="+email)
  }
  requestquery(data){
    return this.http.post(this.save_userdata,data);
  }
}
