import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chart } from 'angular-highcharts';
import { AdunitdetailService } from './adunitdetail.service';
import {Router} from "@angular/router";

declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-remnant-inventory',
  templateUrl: './remnant-inventory.component.html',
  styleUrls: ['./remnant-inventory.component.css']
})
export class RemnantInventoryComponent implements OnInit {
  chart: Chart;
  unfill;
  remnant;
  inhouse;
  width;
  height;
  errshow:boolean=true;
  public splitted;
  public checked_devices = [];
  public seriesresult=[];
  public selected_device = [];
  public id=[];
  public final_detail_data=[];
  public sum_imp;
  public sum_cpm;
  public sum_rev;
  public table_data = [];
  public graph_data = [];
  public webname;
  public remnantImp;
  public total_unfilled;
  public inhouseImp;
  public safefillImp;
  public inhouseRev;
  public top_date;
  data: any = {};
  // nrSelect="Select Size";
  // nrSelectdevice="Select Device";
  // nrSelectTag="Choose Tag Type";
  display='none'; //default Variable
  bannerDiv:boolean=false; //default Variable
  thirdpartyDiv:boolean=false; //default Variable
  notChecked:boolean=false; //default Variable
  public errorMsg;
  fileToUpload:File = null;
  public imagePath;
  imgURL: any;
  model: any = {};
  apiURL: string = 'https://angular.cybermediaservices.net/assets/web_api/inventory/campaign_setup.php';


  setheightpage()
   {
    $(document).ready(function(){
      var page_height='';
      $(".page-content").css("height", page_height);
    });
   }

  getlinegraphdata(x)
  {
  this.inhouse =
  {
    name: 'Inhouse',
    data: x.imp_graph,
  };
  

this.seriesresult=[this.inhouse]
  let chart = new Chart({
    chart: {

      spacingBottom: 5,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,

      type: 'area',
      marginBottom: 85,
      style: {
        fontFamily: 'arial',
      },


    },
    title: {
      text: '',
    },

    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      x: 0,
      y: 10,
      floating: true,
      borderWidth: 0,
      backgroundColor:'#FFFFFF'
    },
    xAxis: {
      categories: x.date,
    },
    yAxis: {
      title: {
        text: 'Estimated Revenue ($)'
      }
    },

    tooltip: {
      shared: true,
      // valuePrefix: '$',


    },
    credits: {
      enabled: false
    },
    exporting: { enabled: false },


    plotOptions: {
      series: {
        dataLabels: {
          //enabled: true,
          //format: '{y}$',
          borderRadius: 5,
          //backgroundColor: 'rgba(252, 255, 197, 0.7)',
          borderWidth: 0,
          borderColor: 'rgba(255, 255, 255, 0.18)',
          y: -6
        },
      },

      area: {
        fillOpacity: 0.08
      }
    },
    series: this.seriesresult
  });
    this.chart = chart;

    function init_daterangepicker() {
      if ("undefined" != typeof $.fn.daterangepicker) {
        //console.log("init_daterangepicker");
        console.log(moment().format());
        var a = function(a, b, c) {
            //console.log(a.toISOString(), b.toISOString(), c), $(".custom-daterangepicker span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
          },
          b = {
            startDate: moment().subtract(7, "days"),
            endDate: moment(),
           // minDate: moment().subtract(3, "month"),
           minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            },
            opens: "left",
            buttonClasses: ["btn bg-pink"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
        $(".custom-daterangepicker span").html(localStorage.getItem('enddate')+"-"+localStorage.getItem('startdate')), $(".custom-daterangepicker").daterangepicker(b, a), $(".custom-daterangepicker").on("show.daterangepicker", function() {
         // console.log("show event fired")
        }), $(".custom-daterangepicker").on("hide.daterangepicker", function() {
          //console.log("hide event fired")
        }), $(".custom-daterangepicker").on("apply.daterangepicker", function(a, b) {
                      
    let strtdate="strtdate="+b.startDate.format("YYYY-MM-DD") +"&enddate="+ b.endDate.format("YYYY-MM-DD");
    $("#dateinput").val(strtdate);
    let startdate=b.startDate.format("MMMM D, YYYY");
    let enddate=b.endDate.format("MMMM D, YYYY");
    localStorage.setItem('strtdate', strtdate);
    localStorage.setItem('startdate', enddate);
  localStorage.setItem('enddate', startdate);
    window.location.reload();
    
  
         // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $(".custom-daterangepicker").on("cancel.daterangepicker", function(a, b) {
          //console.log("cancel event fired")
        }), $("#options1").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function() {
          $(".custom-daterangepicker").data("daterangepicker").remove()
        })
      }
      
    }
  
    function init_daterangepicker_right() {
      if ("undefined" != typeof $.fn.daterangepicker) {
       // console.log("init_daterangepicker_right");
        var a = function(a, b, c) {
            //console.log(a.toISOString(), b.toISOString(), c), $("#reportrange_right span").html(a.format("MMMM D, YYYY") + " - " + b.format("MMMM D, YYYY"))
          },
          b = {
            startDate: moment().subtract(6, "days"),
            endDate: moment(),
            // minDate: moment().subtract(3, "month"),
            minDate: new Date('2019-08-01'),
            maxDate: moment(),
            dateLimit: {
              days: 90
            },
            showDropdowns: !0,
            showWeekNumbers: !0,
            timePicker: !1,
            timePickerIncrement: 1,
            timePicker12Hour: !0,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment()],
              "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            },
            opens: "right",
            buttonClasses: ["btn btn-default"],
            applyClass: "btn-small btn-primary",
            cancelClass: "btn-small",
            format: "MM/DD/YYYY",
            separator: " to ",
            locale: {
              applyLabel: "Submit",
              cancelLabel: "Clear",
              fromLabel: "From",
              toLabel: "To",
              customRangeLabel: "Custom",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              firstDay: 1
            }
          };
        $("#reportrange_right span").html(moment().subtract(29, "days").format("MMMM D, YYYY") + " - " + moment().format("MMMM D, YYYY")), $("#reportrange_right").daterangepicker(b, a), $("#reportrange_right").on("show.daterangepicker", function() {
         // console.log("show event fired")
        }), $("#reportrange_right").on("hide.daterangepicker", function() {
         // console.log("hide event fired")
        }), $("#reportrange_right").on("apply.daterangepicker", function(a, b) {
         // console.log("apply event fired, start/end dates are " + b.startDate.format("MMMM D, YYYY") + " to " + b.endDate.format("MMMM D, YYYY"))
        }), $("#reportrange_right").on("cancel.daterangepicker", function(a, b) {
         // console.log("cancel event fired")
        }), $("#options1").click(function() {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#options2").click(function() {
          $("#reportrange_right").data("daterangepicker").setOptions(b, a)
        }), $("#destroy").click(function() {
          $("#reportrange_right").data("daterangepicker").remove()
        })
      }
    }
  
    function init_daterangepicker_single_call() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_single_call"), $("#single_cal1").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_1"
      }, function(a, b, c) {
        //console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal2").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_2"
      }, function(a, b, c) {
       // console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal3").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_3"
      }, function(a, b, c) {
       // console.log(a.toISOString(), b.toISOString(), c)
      }), $("#single_cal4").daterangepicker({
        singleDatePicker: !0,
        singleClasses: "picker_4"
      }, function(a, b, c) {
        console.log(a.toISOString(), b.toISOString(), c)
      }))
    }
  
    function init_daterangepicker_reservation() {
      "undefined" != typeof $.fn.daterangepicker && (console.log("init_daterangepicker_reservation"), $("#reservation").daterangepicker(null, function(a, b, c) {
        //console.log(a.toISOString(), b.toISOString(), c)
      }), $("#reservation-time").daterangepicker({
        timePicker: !0,
        timePickerIncrement: 30,
        locale: {
          format: "MM/DD/YYYY h:mm A"
        }
      }))
    }
  
    function init_SmartWizard() {
      "undefined" != typeof $.fn.smartWizard && (console.log("init_SmartWizard"), $("#wizard").smartWizard(), $("#wizard_verticle").smartWizard({
        transitionEffect: "slide"
      }), $(".buttonNext").addClass("btn btn-success"), $(".buttonPrevious").addClass("btn btn-primary"), $(".buttonFinish").addClass("btn btn-default"))
    }
  
  
  
  
    var originalLeave = $.fn.popover.Constructor.prototype.leave;
    $.fn.popover.Constructor.prototype.leave = function(a) {}, $("body").popover(), $(document).ready(function() {
      init_daterangepicker(), init_daterangepicker_right(), init_daterangepicker_single_call(), init_daterangepicker_reservation()
    });
  }
  
  constructor(private httpClient: HttpClient,private _AdunitdetailService:AdunitdetailService,private router: Router) { }

  ngOnInit() {

    this._AdunitdetailService.getadunitsize(localStorage.getItem('uniq_id'),localStorage.getItem('strtdate'))
    .subscribe(data => {
      this.final_detail_data = data['final_detail'];
      this.webname =  data['web'];
      this.remnantImp =  data['remnantImp'];
      this.safefillImp = data['safefillImp'];
      this.inhouseImp = data['inhouseImp'];
      this.inhouseRev = data['inhouseRev'];
      this.total_unfilled = data['total_unfilled'];
      this.sum_imp = data['sum_imp'];
      this.sum_cpm = data['sum_cpm'];
      this.sum_rev = data['sum_rev'];
      this.table_data = data['table_data'];
      this.graph_data = data['graph_data'];
      this.top_date = data['daterangenew'];
      this.getlinegraphdata(this.graph_data);
    },
    error => this.errorMsg = error);
    this.setheightpage();
  }
  devices = [
    {name:'Desktop', value:'1', checked:false},
    {name:'Mobile', value:'3', checked:false},
    {name:'Tablet', value:'2', checked:false}
  ]

  handleFileInput(file: FileList){
   this.fileToUpload = file.item(0);
  }

  preview(files) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      alert("Only images are supported.");
      return;
    }

    var img_size=$('#adsize').val();
    var img_width=img_size.split("x");
    var upload_width=img_width[0];
    var upload_height=img_width[1];
    // console.log(upload_width);
    // console.log(upload_height);
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (e) => {
      // var height = $('#filecv').height();
      // var width = $('#filecv').width();  
      // alert(width + "x" + height);
        
      // if(width==upload_width && height==upload_height) {
        this.imgURL = reader.result;
      // }
      // else  {
      //     alert("Image dimension not correct");
      //     $('#adsize').empty();
      //     $('#filecv').empty();
      // }
    }
  }
  
  selectOption(event, value: number) {
    //getted from event

    if (event== true) {
      this.checked_devices.push(value);
    } 
    if (event == false) {

      let index = this.checked_devices.indexOf(value);
      if (index > -1) {
        this.checked_devices.splice(index, 1);
      }
    }

    if(this.checked_devices.find(x => x == "3") && this.splitted > "410"){
      alert("Selected Ad Size and Devices may not compatible with each other. Please select different size.");
    }

    if(this.checked_devices.length == 0){
      this.notChecked = true;
    }else{
      this.notChecked = false;
    }
    
    // console.log("device" + JSON.stringify(this.checked_devices, null, 2));

  }

  selectedSize(size){
    this.splitted = size.split("x",3);
    if(this.checked_devices.find(x => x == "3") && this.splitted > "410"){
      alert("Selected Ad Size and Devices may not compatible with each other. Please select different size.");
    }
  }

  onClickSubmit(data) {

    this.errshow=false;
    if(this.checked_devices.length == 0){
      this.notChecked = true;
    }else{
      this.notChecked = false;
    }
    if(this.notChecked == false) {
      console.log('ticket form submission');
      data['camp_name']="safe_remnant_"+this.webname+"_"+moment().format("HHmmss");
      data['startdate']=moment().format("YYYY-MM-DD");
      // data['startdate']=moment().format("YYYY-MM-DD HH:mm:ss");
      data['enddate']=moment().add(2,"year").format("YYYY-MM-DD");
      data['webname']=this.webname;
      data['uniqid']=localStorage.getItem('uniq_id');
      data['cmpstrg_type']=5;
      data['device'] = JSON.stringify(this.checked_devices);
  
      const formData: FormData = new FormData();
      formData.append('data', JSON.stringify(data));
      if(this.fileToUpload) {
        formData.append('file', this.fileToUpload, this.fileToUpload.name);
      }
  
      this.httpClient.post(this.apiURL+'?q=newcampaign',formData).subscribe((response)=>{
        if(response){
          alert("Thanks! Camapign setup successfully.");
          this.closeModalDialog();
          this.router.navigate(['/dashboard']);
        }
        else{
          alert("Camapign not setup correctly . Please contact Account Manager");
          this.closeModalDialog();
          // this.router.navigate(['/dashboard']);
        }
        
      })
  
      new Response(formData).text().then(console.log);
    }
    else{
      this.notChecked = true;
      // alert("Campaign not Setup, Please select alteast one device.");
    }
  }


show_hide(val){
  if (val == 1){
    this.bannerDiv = true;
    this.thirdpartyDiv = false;
  }
  if (val == 2){
    this.bannerDiv = false;
    this.thirdpartyDiv = true;
  }
}

  openModalDialog(){
    this.display='block'; //Set block css
 }

 closeModalDialog(){
  this.display='none'; //set none css after close dialog
 }

}