import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { top } from './adxtopcontent';
import { tap, catchError } from 'rxjs/operators';

interface myData {
  success: boolean,
  message: string
  pub_name: string
  adx_id: string
  ads_id: string
  anlytic_id: string
  uniq_id: string
  startdate: string
  enddate: string
  oldname: string
  isdemo: string
  acc_mgr_id: string
  usertype: string
  issubuser: string
  email:string
  // strtdate: string
}


@Injectable({
  providedIn: 'root'
})
export class TopcontenttotalService {
  
  public usertype: any;

  private _url: string = "";

  
  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/maindashboard_topheading_networks.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/maindashboard_topheading_totalearnings.php";
    }
    console.log('sandeep user type =>'+this.usertype); 
   }

  getTopcontent(daterange,acc_mgr_id,acc_name,ads_id,new_adx_name,uniqid,child_net_code): Observable<top[]>{
    return this.http.get<top[]>(this._url+ "?"+daterange+"&acc_mgr_id="+acc_mgr_id+"&acc_name="+acc_name+"&ads_id="+ads_id+"&new_acc_name="+new_adx_name+"&uniqid="+uniqid+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  showBackToHomeData(username) {
    // post these details to API server return user info if correct  
    return this.http.get<myData>('https://angular.cybermediaservices.net/assets/web_api/showDashboardData.php?username='+username+"&string=backhome")
  }
  
}