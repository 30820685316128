import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { network_data } from './network_data';

interface myData {
    success: boolean,
    message: string
    pub_name: string
    adx_id: string
    ads_id: string
    anlytic_id: string
    uniq_id: string
    startdate: string
    enddate: string
    oldname: string
    isdemo: string
    acc_mgr_id: string
    usertype: string
    issubuser: string
    email:string
    // strtdate: string
  }


@Injectable()
export class Network_Site_Service{
    public usertype: any;
    private _url: string = "https://angular.cybermediaservices.net/assets/web_api/networks_sites_details.php";
    // private _url: string = "https://angular.cybermediaservices.net/assets/web_api/networks_sites_trans_details.php";
    

    constructor(private http:HttpClient) {
        this.usertype = localStorage.getItem('usertype');
    }
    getnetwoksiteData(acc_mgr_id): Observable<network_data[]>{
        return this.http.get<network_data[]>(this._url+ "?acc_mgr_id="+acc_mgr_id)
    }
    errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
    }

    showDashboardData(username) {
        // post these details to API server return user info if correct 
        return this.http.get<myData>('https://angular.cybermediaservices.net/assets/web_api/showDashboardData.php?username='+username)
      }
}