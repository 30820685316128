import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { CdpService } from './cdp.service';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-cdp',
  templateUrl: './cdp.component.html',
  styleUrls: ['./cdp.component.css']
})
export class CdpComponent implements OnInit {
 
	public loading: boolean = true;
	public errorMsg;
	public country_data;
	public device_data;
	public browser_data;
	public language_data;
	public interest_data;
	public user_data;

	setheightpage()
	 {
	  $(document).ready(function(){
		var page_height='';
		$(".page-content").css("height", page_height);
	  });
	 }
	constructor(private _cdpservice:CdpService) { }
  
	ngOnInit() {
	  this._cdpservice.getcdpdata(localStorage.getItem('uniq_id'))
	  .subscribe(data => {
		this.loading = false;
		this.country_data = data['country'];
		this.device_data = data['devices'];
		this.browser_data = data['browser'];
		this.language_data = data['language'];
		this.interest_data = data['interest'];
		this.user_data = data['user'];
		this.setheightpage()},
	  error => this.errorMsg = error);
	}
}
