import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { piechart } from './adxpiechartcontent';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PiecharttotalService {

  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_pro/cyberads_pro_device_pie.php";
 
  constructor(private http:HttpClient) { }

  getpiechart(daterange,anlyticid,acc_name,uniq_id,website,adsid): Observable<piechart[]>{
    return this.http.get<piechart[]>(this._url+ "?"+daterange+"&analyid="+anlyticid+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&website="+website+"&adsid="+adsid)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}