import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Network_Site_Service } from './network_Site_Service';
import { Router } from '@angular/router';
import { Compiler } from '@angular/core';
declare var $: any;
@Component({
  selector: 'network-for-site',
  templateUrl: './networksites.component.html',
  styleUrls: ['./networksites.component.css']
})

export class NetworkDataComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public tabledata = [];
  public daterange = [];
  public sumupdata = [];
  public errorMsg;
  public show: boolean = false;
  public loading: boolean = true;
  public usertype: any;
  public _url: string = "";
  public networkemail;
  constructor(private _networksiteService: Network_Site_Service, private router: Router, private _compiler: Compiler) { }

  ngOnInit() {
    if (localStorage.getItem('usertype') == "2" && localStorage.getItem('acc_mgr_id')!=null) {
      this._networksiteService.getnetwoksiteData(localStorage.getItem('acc_mgr_id')).subscribe(data => {
        this.loading = false;
        this.tabledata = data["table_data"];
      },
        error => this.errorMsg = error);
      // $('#datatable').DataTable();
      $('#datatable').DataTable().ajax.reload();
    } else {
      this.router.navigate(['/login']);
    }
  }

  Logintosafe(pub_email: string) {

    this._networksiteService.showDashboardData(pub_email).subscribe(data => {
      if (data.success) {
        // this.networkemail = localStorage.getItem('useremail');
        this.networkemail = localStorage.getItem('userName');
        localStorage.clear();
        this._compiler.clearCache();
        this.getdaterange();
        localStorage.setItem('acc_name', data.pub_name);
        localStorage.setItem('adx_id', data.adx_id);
        localStorage.setItem('ads_id', data.ads_id);
        localStorage.setItem('anlytic_id', data.anlytic_id);
        localStorage.setItem('uniq_id', data.uniq_id);
        localStorage.setItem('website_name', 'all');
        localStorage.setItem('startdate', data.startdate);
        localStorage.setItem('enddate', data.enddate);
        localStorage.setItem('old_acc_name', data.oldname);
        localStorage.setItem('isdemo', data.isdemo);
        localStorage.setItem('acc_mgr_id', data.acc_mgr_id);
        localStorage.setItem('issubuser', data.issubuser);
        localStorage.setItem('usertype', data.usertype);
        localStorage.setItem('accmgr', 'false');
        localStorage.setItem('networkred', 'net');
        localStorage.setItem('lastuseremail', this.networkemail);
        // this.router.navigate(['/dashboard']); 
        this.reloadComponent();
        console.log("sandeep Useremail =>" + this.networkemail);
      } else {
        alert(data.message);
      }
    },
      error => this.errorMsg = error);
  }
  reloadComponent() {
    // this.router.navigateByUrl('/dashboard', { skipLocationChange: true });
    // this.router.navigate(["/dashboard"]);
    // reload("/dashboard");
    // this.router.navigate(['/dashboard']);
    window.location.href = '/dashboard';
  }
  getdaterange() {
    //localStorage.clear();
    var asiaTime = new Date().toLocaleString("en-US", { timeZone: "Pacific/Honolulu" });
    var today = new Date(asiaTime);
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
    var strtmnth = ("0" + (lastWeek.getMonth() + 1)).slice(-2);
    var strtday = ("0" + lastWeek.getDate()).slice(-2);
    var endmnth = ("0" + (today.getMonth() + 1)).slice(-2);
    var endday = ("0" + today.getDate()).slice(-2);
    var finalstrtdate = [lastWeek.getFullYear(), strtmnth, strtday].join("-");
    var finalenddate = [today.getFullYear(), endmnth, endday].join("-");
    let strtdate = "strtdate=" + finalstrtdate + "&enddate=" + finalenddate;
    localStorage.setItem('strtdate', strtdate);
  }


  viewprofile(pub_email: string) {
    alert(pub_email);
  }

}