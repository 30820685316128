import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { geo } from './adx-geo';

@Injectable({
  providedIn: 'root'
})
export class DelUserService {
  del_userdata: string = "https://angular.cybermediaservices.net/assets/web_api/sub_user/del_user.php";

  constructor(private http:HttpClient) {}
  public deluser(id){
    return this.http.get(this.del_userdata+ "?id="+id);
  }
  ​
  ​ }
