import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advance-responsive',
  templateUrl: './advance-responsive.component.html',
  styleUrls: ['./tools.component.css']
})
export class AdvanceResponsiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
