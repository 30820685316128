import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { TraficsrcService } from './traficsrc.service';
declare var $: any;
@Component({
  selector: 'app-traficsource',
  templateUrl: './traficsource.component.html',
  styleUrls: ['./traficsource.component.css']
})
export class TraficsourceComponent implements OnInit {
  chart: Chart;
  donutchart:Chart;
  donutchart1:Chart;
  public columnchartdata = [];
  public donutchatdata1 = [];
   public dateranges = []; 
   public tabulardata = []; 
   public topdata = []; 
   public errorMsg;
   public donutchartdata =[];
   public donutdata =[];
   public loading: boolean = true;

   setheightpage()
   {
    $(document).ready(function(){
      var page_height='';
      $(".page-content").css("height", page_height);
    });
   }  
   getdonutchart(x)
   {
 let donutchart = new Chart({	
  chart: {
    type: 'column'
},
colors: ['#0182c3', '#00a698', '#ff9f00', '#e91d63', '#4f5d68', 
'#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

title: {
    text: '',
},
xAxis: {
    categories: ['Direct', 'Organic', 'Referral', 'Email', 'Social Network'],


},

yAxis: {
   
    title: {
        text: 'Traffic'
    },},
credits: {
    enabled: false
},
exporting:{ enabled: false},
series: x
});

     this.donutchart = donutchart;
}

  constructor(private _TraficsrcService:TraficsrcService) { }

  ngOnInit() {
    this.setheightpage();
    this._TraficsrcService.getdonutchart(localStorage.getItem('anlytic_id'))
    .subscribe(data => {this.loading = false;this.columnchartdata = data['chartdata'];this.getdonutchart(this.columnchartdata);this.tabulardata = data['tabulardata1'];this.topdata = data['topbardata1'];},
    error => this.errorMsg = error);
  }

}

