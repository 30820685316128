import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { appmobileos } from './adx-appmobileos';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxAppMobileosService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_app/cyberads_app_mobos.php";

  constructor(private http:HttpClient) { }

  getAppMobileos(daterange,acc_name,demo,uniq_id): Observable<appmobileos[]>{
    return this.http.get<appmobileos[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&isdemo="+demo+"&uniq_id="+uniq_id)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}