import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from './notify.service';
import { LeftsidebarService } from './leftsidebar.service';
import { CookieService } from 'ngx-cookie-service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public notification = [];
  public website = [];
  public notifydata = [];
  public contentmenustatusdata = [];
  public appmenustatusdata = [];
  public vidmenustatusdata = [];
  public notifrevydata = [];
  public notifecpmydata = [];
  public notifrpmmydata = [];
  public alertcount = [];
  public accname = [];
  public errorMsg;
  public demo;
  public accmgr;
  public adminsubuser;
  public adopssubuser;
  public authorsubuser;
  public interval;
  public adxmenu;
  public selwebsite;
  public isnotifybnk: boolean = true;
  public isnotifyrev: boolean = true;
  public isnotifyecpm: boolean = true;
  public isnotifyrpm: boolean = true;
  public isnotifyprevfinal: boolean = true;
  public isnotifyinvalid: boolean = true;
  public adxappmenu: boolean = true;
  public capromenustatus: boolean = true;
  public adxcontentmenu: boolean = true;
  public adxvidmenu: boolean = true;
  public cyberads_menu: boolean = true;
  public ca_pro_flag: boolean = true;
  public keyinmenu: boolean = true;
  public adsmenu: boolean = true;
  public cyberad: boolean = true;
  router: string;
  public url: string;
  public ticketOption: boolean = false;
  public pubtools: boolean = true;
  public adxcontentsiteshide: boolean = true;
  public adxcontentmobileoshide: boolean = true;
  public adxcontentadunitshide: boolean = true;
  public shownetworksites: boolean = true;
  public shownetworkearnings: boolean = false;
  public cyberadsmenu: boolean = true;
  public showkeyinmenu;
  public profilebtn: boolean = false;
  public useremail;
  public keyinsigh11t;
  public childnetwkuser: boolean = false;
  public bnkdetails: boolean = false;
  public addNewDomain: boolean = true;
  // public mcmreporting:boolean=false;
  public appbutton: boolean = true;
  public MCMpub_name;
  public new_MCMpub_name;
  // public arrData = [];
  apiURL: string = '';


  constructor(private httpClient: HttpClient, private _router: Router, private _notifyService: NotifyService, private _LeftsidebarService: LeftsidebarService, private cookieService: CookieService) {
    this.router = _router.url;

  }

  ngOnInit() {
    // if (localStorage.getItem("access_token") == null || localStorage.getItem("access_token") == "UNKNOWN") {
    // 	this._router.navigate(['/login']);
    // }
    // this.refreshData();
    // this.refreshData(); 
    this.interval = setTimeout(() => {
      this.refreshData();
    }, 1000);
    if (localStorage.getItem('acc_mgr_id') == '19') {
      this.ticketOption = true;
    }
    this.interval = setTimeout(() => {
      this.checkGamstatus();
      // this.checkAppstatus();
    }, 1250);

  }

  refreshData() {

    // this.loadScript('../../node_modules/jquery/dist/jquery.js');
    // this.loadScript('../../node_modules/bootstrap/dist/js/bootstrap.js');

    this.loadScript('../assets/js/jquery.js');
    this.loadScript('../assets/js/plugins/jquery/jquery.min.js');
    this.loadScript('../assets/js/plugins/bootstrap/bootstrap.min.js');
    this.loadScript('../assets/js/bootstrap-select.js');
    this.loadScript('../assets/js/bootstrap-notify.min.js');
    this.loadScript('../assets/js/plugins/jquery/jquery-ui.min.js');
    this.loadScript('../assets/js/plugins/mcustomscrollbar/jquery.mCustomScrollbar.min.js');
    this.loadScript('../assets/js/moment.min.js');
    this.loadScript('../assets/js/daterangepicker.js');
    this.loadScript('../assets/js/plugins.js');
    this.loadScript('../assets/js/actions.js');
    this.loadScript('../assets/js/formden.js');
    this.loadScript('../assets/js/jquery.dataTables.min.js');
    this.loadScript('../assets/js/sweetalert.js');

    this._notifyService.getNotification(localStorage.getItem('adx_id'), localStorage.getItem('acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('old_acc_name'))
      .subscribe(data => {
        this.notifydata = data['bnkdtl_notification'], this.website = data['website'], this.notifrevydata = data['rev_notification'], this.notifecpmydata = data['ecpm_notification'], this.notifrpmmydata = data['rpm_notification'], this.alertcount = data['alertcount'];
        if (data['bnkdtl_notification'] != null) {
          this.isnotifybnk = true;
        }
        else {
          this.isnotifybnk = false;
        }
        if (data['rev_notification'] != null) {
          this.isnotifyrev = true;
        }
        else {
          this.isnotifyrev = false;
        }
        if (data['ecpm_notification'] != null) {
          this.isnotifyecpm = true;
        }
        else {
          this.isnotifyecpm = false;
        }
        if (data['rpm_notification'] != null) {
          this.isnotifyrpm = true;
        }
        else {
          this.isnotifyrpm = false;
        }
        if (data['prev_final_rev'] != null) {
          this.isnotifyprevfinal = true;
        }
        else {
          this.isnotifyprevfinal = false;
        }
        if (data['invalid_rev'] != null) {
          this.isnotifyinvalid = true;
        }
        else {
          this.isnotifyinvalid = false;
        }
      },
        error => this.errorMsg = error);

    this._LeftsidebarService.getmenustatus(localStorage.getItem('strtdate'), localStorage.getItem('acc_name'), localStorage.getItem('old_acc_name'), localStorage.getItem('uniq_id'), localStorage.getItem('isdemo'), localStorage.getItem('child_net_code'))
      .subscribe(data => {
        this.contentmenustatusdata = data['content']; this.appmenustatusdata = data['app']; this.vidmenustatusdata = data['video']; this.capromenustatus = data['ca_pro_flag']; this.accname = data['name'];
        if (localStorage.getItem('uniq_id') == 'UNIQ_DEMO_2019') {
          this.demo = true;
        }
        else {
          this.demo = false;
        }
        if (localStorage.getItem('accmgr') == "true") {
          this.accmgr = true;
        }
        else {
          this.accmgr = false;
        }
        if (localStorage.getItem('usertype') == "5") {
          this.adminsubuser = true;
        }
        else {
          this.adminsubuser = false;
        }
        if (localStorage.getItem('usertype') == "6") {
          this.adopssubuser = true;
        }
        else {
          this.adopssubuser = false;
        }

        if (localStorage.getItem('usertype') == "7") {
          this.authorsubuser = true;
        }
        else {
          this.authorsubuser = false;
        }
        if (localStorage.getItem('uniq_id') == 'UNIQ@2782018_123020') {
          this.cyberad = true;
        }
        else {
          this.cyberad = false;
        }
        if (localStorage.getItem('anlytic_id') != '') {
          this.keyinmenu = true;
          $("#keyinsight").show();
          $("#keyinsight1").hide();
        }
        else {
          this.keyinmenu = false;
          $("#keyinsight").hide();
          $("#keyinsight1").show();
        }
        if (localStorage.getItem('acc_name') != '') {
          this.adxmenu = true;
          $("#adxmenu").show();
          this.cyberads_menu = true;
          $("#cyberads_menu").show();
        }
        else {
          this.adxmenu = false;
          $("#adxmenu").hide();
        }
        if (localStorage.getItem('ads_id') != '' && localStorage.getItem('ads_id') != 'ca-') {
          this.adsmenu = true;
          $("#adsense_menu").show();
        }
        else {
          this.adsmenu = false;
          $("#adsense_menu").hide();
        }
        if (data['content'] == 'true') {
          this.adxcontentmenu = true;
          $("#adxcontent_menu").show();
        }
        else {
          this.adxcontentmenu = false;
          $("#adxcontent_menu").hide();
        }
        if (data['app'] == 'true') {
          this.adxappmenu = true;
          $("#adxapp_menu").show();

        }
        else {
          this.adxappmenu = false;
          $("#adxapp_menu").hide();
        }
        if (data['ca_pro_flag'] == 'true') {
          $("#ca_pro_flag").show();
          this.ca_pro_flag = true;
          this.cyberads_menu = true;
          $("#cyberads_menu").show();

        }
        else {
          this.ca_pro_flag = false;
          this.cyberads_menu = false;
          $("#ca_pro_flag").hide();
        }
        // console.log(this.ca_pro_flag);
        // console.log(this.cyberads_menu);
        if (data['video'] == 'true') {
          this.adxvidmenu = true;
          $("#adxvideo_menu").show();

        }
        else {
          this.adxvidmenu = false;
          $("#adxvideo_menu").hide();
        }

        if (data['gam_content'] == 'true') {
          $("#gamcontent_menu").show();
        } else {
          $("#gamcontent_menu").hide();
        }
        if (data['gam_app'] == 'true') {
          $("#gamapp_menu").show();
        } else {
          $("#gamapp_menu").hide();
        }
        if (data['gam_video'] == 'true') {
          $("#gamvideo_menu").show();
        } else {
          $("#gamvideo_menu").hide();
        }
        if (localStorage.getItem('usertype') == "2") {
          this.keyinmenu = false;
          this.keyinsigh11t = true;
          this.pubtools = true;
          this.adxcontentmenu = true;
          this.adxcontentsiteshide = false;
          this.adxcontentmobileoshide = false;
          this.adxcontentadunitshide = false;
          this.shownetworksites = true;
          // this.addNewDomain = false;
          if (localStorage.getItem('acc_mgr_id') == "38") {
            this.shownetworkearnings = true;
          }
          this.adxvidmenu = true;
          // this.cyberadsmenu=true;
          this.cyberadsmenu = false;
          this.showkeyinmenu = false;
          this.profilebtn = true;
          this.accname = [{ name: localStorage.getItem('userName') }];
          this.useremail = localStorage.getItem('useremail');
          this.bnkdetails = false;
          // alert(localStorage.getItem('useremail'));
          $("#adxcontent_menu").show();
          $("#adxvideo_menu").show();
        } else {
          // this.pubtools=true;
          this.shownetworksites = false;
          this.showkeyinmenu = true;
          this.useremail = localStorage.getItem('useremail');
          this.keyinsigh11t = true;

        }

        if (localStorage.getItem('usertype') == "3") {
          this.childnetwkuser = true;
          this.appbutton = true;
          // this.profilebtn =true;
          // console.log("sandeep user3 usermail =>"+localStorage.getItem('lastuseremail'));
        }
        if (localStorage.getItem('child_net_code') != '' && localStorage.getItem('child_net_code') != null && localStorage.getItem('child_net_code') != 'null') {
          // this.mcmreporting=true;
          $("#gamRevenue").show();
        } else {
          // this.mcmreporting=false;
          $("#gamRevenue").hide();
        }
        if (localStorage.getItem('acc_name') != '' || data['ca_pro_flag'] == 'true' || localStorage.getItem('child_net_code') != '') {
          this.cyberads_menu = true;
          $("#cyberads_menu").show();
        }
        else {
          this.cyberads_menu = false;
          $("#cyberads_menu").hide();
        }
        if (localStorage.getItem('usertype') == "3") {
          this.new_MCMpub_name = localStorage.getItem('new_MCMpub_name');
          if (localStorage.getItem('acc_name') != '' || data['ca_pro_flag'] == 'true') {
            this.cyberadsmenu = true;
            this.adxmenu = true;
            $(".hidenetworkrev").show();
            this.MCMpub_name = false;
          } else {
            this.adxmenu = false;
            this.cyberadsmenu = false;
            $(".hidenetworkrev").hide();
            this.MCMpub_name = true;
          }
        }


        // console.log(this.cyberads_menu);
        // console.log(this.adsmenu);
      },
        error => this.errorMsg = error);
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  toggle_notify() {
    $('#notifydiv').css('display', 'none');
  }
  toggle_notify_show() {
    $('#notifydiv').css('display', 'block');
  }


  toggle_logout() {
    $('#logoutdiv').css('display', 'none');
  }
  toggle_logout_show() {
    $('#logoutdiv').css('display', 'block');
  }
  ariaexpand() {
    $("#adx_nav").click(function () {
      $(this).toggleClass("active");
    });
  }
  // togglecont() {
  //   $( "#adxcont" ).click(function() {
  //     $( this ).toggleClass( "active" );
  //   });
  // }
  // togglevid() {
  //   $( "#adxvid" ).click(function() {
  //     $( this ).toggleClass( "active" );
  //   });
  // }
  // togglekeyin() {
  //   $( "#keyin" ).click(function() {
  //     $( this ).toggleClass( "active" );
  //   });
  // }
  selectwebsite(event: any) {
    //update the ui
    this.selwebsite = event.target.value;
    let webname = event.target.value;
    localStorage.setItem('website_name', webname);
    window.location.reload();
  }
  checkGamstatus() {
    if (localStorage.getItem('usertype') == '2') {
      this.apiURL = "https://angular.cybermediaservices.net/assets/web_api/checkGamStatus.php";

      const formData: FormData = new FormData();
      formData.append('acc_mgr_id', localStorage.getItem('acc_mgr_id'));
      formData.append('publisher_name', localStorage.getItem('userName'));
      this.httpClient.post(this.apiURL + '?q=checkstatus', formData).subscribe((response) => {
        if (response['msg'] == 'success') {
          this.addNewDomain = true;
        } else {
          this.addNewDomain = false;
        }
      });
    }
  }
  // checkAppstatus(){
  //   if(localStorage.getItem('usertype')=='3'){
  //     this.apiURL="https://angular.cybermediaservices.net/assets/web_api/checkAppStatus.php";

  //     const formData: FormData = new FormData();
  //     formData.append('acc_name', localStorage.getItem('acc_name'));
  //     formData.append('uniq_id', localStorage.getItem('uniq_id'));
  //     this.httpClient.post(this.apiURL+'?q=checkappstatus',formData).subscribe((response)=>{
  //       if(response['msg']=='success'){
  //         this.appbutton = true;
  //       }else{
  //         this.appbutton = false; 
  //       }
  //     });
  //   }
  // }
}

