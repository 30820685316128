import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { adtype } from './gam-app-adtype';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class GamAppAdTypesService {  
  
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/app_gam/gam_app_adtype.php";

  constructor(private http:HttpClient) { }

  getAppAdtypes(daterange,child_net_code): Observable<adtype[]>{
    return this.http.get<adtype[]>(this._url+ "?"+daterange+"&child_net_code="+child_net_code)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}