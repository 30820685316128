import { Component, OnInit } from '@angular/core';
import { Chart} from 'angular-highcharts';
import { TraficsrcbyvalService } from './traficsrcbyval.service';

@Component({
  selector: 'app-traficsrcbyval',
  templateUrl: './traficsrcbyval.component.html',
  styleUrls: ['./traficsrcbyval.component.css']
})
export class TraficsrcbyvalComponent implements OnInit {
    chart: Chart;
    donutchart:Chart;
    donutchart1:Chart;
    public barchartdata1 = [];
    public bartopdata1 = [];
     public dateranges1 = []; 
     public barchartdata2 = [];
    public bartopdata2 = [];
     public dateranges2 = []; 
     public tabledata = [];
     public tabledata1 = [];
     public tabledata2 = [];
     public tabledata3 = [];
     public tabledata4 = [];
     public innertabledataL1 = [];
     public innertabledataL = [];
     public innertabledataL2 = [];
     public innertabledataL3 = [];
     public innertabledataL4 = [];
     public topbardate = [];
     public innertabledata = [];
     public innertabledata1 = [];
     public innertabledata2 = [];
     public innertabledata3 = [];
     public innertabledata4 = [];
     public errorMsg;
     public donutchartdata =[];
     public donutdata =[];
     public show:boolean = false;
     public showhighend:boolean = false;
     public showtab:boolean = false;
     public showcon:boolean = false;
     public showagain:boolean = false;
     public classname :any = 'fa fa-plus';
     public classnamehighend :any = 'fa fa-plus';
     public classnametab :any = 'fa fa-plus';
     public classnamecon :any = 'fa fa-plus';
     public classnameagain :any = 'fa fa-plus';
     public loading: boolean = true;
     getdonutchart(x,y,z,a)
     {
   let donutchart = new Chart({	
    chart: {
        type: 'column',
        marginBottom:70,
    },
    title: {
        text:'',
    },
    colors: ['#0182c3', '#e91d63',  '#ff9f00', '#4f5d68', '#00a698',
    '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],

    credits:{enabled:false,},
    exporting:{ enabled: false},

    xAxis: {
        categories: a
    },
    yAxis: {
        min: 0,
        title: {
            text: null,
        },
        stackLabels: {
            enabled: false,
           
        }
    },
    legend: {
        align: 'center',
        x: 0,
        verticalAlign: 'bottom',
        y: 25,
        floating: true,
        backgroundColor:'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        enabled:true,
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
                color:'white'
            }
        }
    },
    series: z
});
     this.donutchart = donutchart;
     
     let donutchart1 = new Chart({	
        chart: {
            type: 'column',
            marginBottom:70,
        },
        title: {
            text:'',
        },
        colors: ['#0182c3', '#e91d63',  '#ff9f00', '#4f5d68', '#00a698',
        '#8fc321', '#00689d', '#c90649', '#404a53', '#cd7f01', '#008477', '#739c1a'],
    
        credits:{enabled:false},
        exporting:{ enabled: false},

        xAxis: {
            categories: y
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            stackLabels: {
                enabled: false,
               
            }
        },
        legend: {
            align: 'center',
            x: 0,
            verticalAlign: 'bottom',
            y: 25,
            floating: true,
            backgroundColor:'white',
            borderColor: '#CCC',
            borderWidth: 0,
            shadow: false,
            enabled:true,
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                    color:'white'
                }
            }
        },
        series: x
    });
       this.donutchart1 = donutchart1;
  }
  
    constructor(private _TraficsrcbyvalService:TraficsrcbyvalService) { }
  
    ngOnInit() {
      this._TraficsrcbyvalService.getdonutchart(localStorage.getItem('anlytic_id'))
      .subscribe(data => {this.loading = false;this.barchartdata1 = data['chartdata'];this.bartopdata1 = data['topdata'];this.dateranges1 = data['daterange'];this.barchartdata2 = data['chartdata1'];this.bartopdata2 = data['topdata1'];this.dateranges2 = data['daterange1'];this.getdonutchart(this.barchartdata1,this.dateranges1,this.barchartdata2,this.dateranges2);this.tabledata = data['tabledata']['0'];this.tabledata1 = data['tabledata']['1'];this.tabledata2 = data['tabledata']['2'];this.tabledata3 = data['tabledata']['3'];
      this.tabledata4 = data['tabledata']['4'];this.innertabledata = data['innertabledata']['0'];this.innertabledata1 = data['innertabledata']['1'];this.innertabledata2 = data['innertabledata']['2'];this.innertabledata3 = data['innertabledata']['3'];this.innertabledata4 = data['innertabledata']['4'];this.innertabledataL = data['innertabledataL']['0'];this.innertabledataL1 = data['innertabledataL']['1'];this.innertabledataL2 = data['innertabledataL']['2'];this.innertabledataL3 = data['innertabledataL']['3'];
      this.innertabledataL4 = data['innertabledataL']['4'];this.topbardate = data['topdate'];},
      error => this.errorMsg = error);
    }
    toggle() {
		this.show = !this.show;
		if(this.show)  
		  this.classname = "fa fa-minus";
		else
		  this.classname = "fa fa-plus";
	  }
	  togglehighend() {
		this.showhighend = !this.showhighend;
		if(this.showhighend)  
		  this.classnamehighend = "fa fa-minus";
		else
		  this.classnamehighend = "fa fa-plus";
	  }
	  toggletab() {
		this.showtab = !this.showtab;
		if(this.showtab)  
		  this.classnametab = "fa fa-minus";
		else
		  this.classnametab = "fa fa-plus";
	  }
	  togglecon() {
		this.showcon = !this.showcon;
		if(this.showcon)  
		  this.classnamecon = "fa fa-minus";
		else
		  this.classnamecon = "fa fa-plus";
      }
      toggleagain() {
		this.showagain = !this.showagain;
		if(this.showagain)  
		  this.classnameagain = "fa fa-minus";
		else
		  this.classnameagain = "fa fa-plus";
	  }
  }

