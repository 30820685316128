import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { pop } from './adxdashboardpop';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

      private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Dashboard/display_revenuediff.php"

  constructor(private http:HttpClient) { }

  getDashboard(acc_name): Observable<pop[]>{
      return this.http.get<pop[]>(this._url+ "?acc_name="+acc_name)
  }

  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
    }

}
