import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { device } from './adx-device';
import { tap, catchError } from 'rxjs/operators';



@Injectable()
export class AdxDeviceService {  
  public usertype: any;
  private _url: string = "";

  constructor(private http:HttpClient) {
    this.usertype = localStorage.getItem('usertype');
    if(this.usertype==2){
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_display/cyberads_display_device_networks.php";
    }else{
      this._url = "https://angular.cybermediaservices.net/assets/web_api/Cyberads_display/cyberads_display_device.php";
    }
  }

  getDevice(daterange,acc_mgr_id,acc_name,uniqid,new_acc_name): Observable<device[]>{
    return this.http.get<device[]>(this._url+ "?"+daterange+"&acc_mgr_id="+acc_mgr_id+"&acc_name="+acc_name+"&uniqid="+uniqid+"&new_acc_name="+new_acc_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}