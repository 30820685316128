import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { notify } from './notify';
import { tap, catchError } from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private _url: string = "https://angular.cybermediaservices.net/assets/web_api/notification.php";

  constructor(private http:HttpClient) { }

  getNotification(adxid,adxname,uniqid,new_adx_name): Observable<notify[]>{
    return this.http.get<notify[]>(this._url+ "?adxid="+adxid+"&adxname="+adxname+"&uniq_id="+uniqid+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
}
