import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { topgraph } from './adxtopgrphcontent';

@Injectable({
  providedIn: 'root'
})
export class CyberadOverviewService {
 
   private _url: string = "https://angular.cybermediaservices.net/assets/web_api/Cyberads/cyberads_pro_with_cyberads_network.php";
   
  
  constructor(private http:HttpClient) { }

  getTopgraph(daterange,acc_name,uniq_id,new_adx_name): Observable<topgraph[]>{
    return this.http.get<topgraph[]>(this._url+ "?"+daterange+"&acc_name="+acc_name+"&uniq_id="+uniq_id+"&new_acc_name="+new_adx_name)
  }
  errorHandler(error: HttpErrorResponse){
    return observableThrowError(error.message || "Server Error");
  }
  
}